import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Aux from '../../../hoc/ReactAux/ReactAux';

import RecipientPaymentListItem from '../../../components/payment/list-item';
import CallNotifyListItem from '../../../components/call-notify/list-item';

import * as callNotifyActions from '../../../actions/call-notify';
import * as paymentActions from '../../../actions/recipient-payment';

class RecipientListTop extends Component {
    cancelSelectedImportPaymentFunc = () => {
        this.props.paymentActions.cancelSelectedImportPayment();
        this.props.history.push('/file/import-payment');
    }

    cancelSelectedCallNotifyFunc = () => {
        this.props.callNotifyActions.cancelSelectedCallNotify();
        this.props.history.push('/call-notify/list');
    }

    render() {
        const { selectedImportPayment,
            selectedCallNotify } = this.props;

        return (
            <Aux>
                {selectedImportPayment ?
                    <RecipientPaymentListItem item={selectedImportPayment}
                        cancelSelectedImportPaymentFunc={this.cancelSelectedImportPaymentFunc}
                        color='warning' /> :

                    selectedCallNotify ?
                        <CallNotifyListItem item={selectedCallNotify}
                            color='warning'
                            cancelSelectedCallNotifyFunc={this.cancelSelectedCallNotifyFunc} /> : null}
            </Aux>
        )
    }
}

RecipientListTop.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

function mapStateToProps(state) {
    return {
        selectedImportPayment: state.payment.import.selected,
        selectedCallNotify: state.callNotify.selected,
    }
}

function mapDispathToProps(dispatch) {
    return {
        callNotifyActions: bindActionCreators(callNotifyActions, dispatch),
        paymentActions: bindActionCreators(paymentActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(withRouter(RecipientListTop));