import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Aux from '../../../../hoc/ReactAux/ReactAux'

import RecipientDetailProfileEditForm from './edit-form'
import RecipientDetailProfileView from './view'
import Comment from '../../../comment'

import * as personalInfoActions from '../../../../actions/recipient-personal-info'
import * as callCenter from '../../../../actions/call-center'
import * as contractActions from '../../../../actions/recipient-contract'
import * as stagePhaseActions from '../../../../actions/stage-phase'

class RecipientDetailProfile extends Component {
  componentDidMount() {
    this.props.stagePhaseActions.getStagePhases()
    this.props.contractActions.getStages()
  }

  updatePersonalInfo = values => {
    values.personId = this.props.recipient.personalInfo.personId

    this.props.personalInfoActions.updatePersonalInfo(
      this.props.recipient.id,
      values
    )
  }

  contractUpdateStageFunc = stageId => {
    this.props.contractActions.updateStage(this.props.recipient.id, stageId)
  }

  contractUpdateStagePhaseFunc = stagePhaseId => {
    this.props.stagePhaseActions.update(this.props.recipient.id, stagePhaseId)
  }

  render() {
    const {
      recipient,
      callCenter,
      personalInfoActions,
      editPersonalInfo,
      contract,
      recipientStages,
      stagePhases,
    } = this.props

    return (
      <Aux>
        {editPersonalInfo.formEnable ? (
          <RecipientDetailProfileEditForm
            onSubmit={this.updatePersonalInfo}
            cancelFunc={personalInfoActions.cancelEdit}
          />
        ) : (
          <RecipientDetailProfileView
            personalInfo={recipient && recipient.personalInfo}
            getCallBack={callCenter.getCallBack}
            editFunc={personalInfoActions.edit}
            contract={contract}
            stages={recipientStages}
            updateStageFunc={this.contractUpdateStageFunc}
            stagePhases={stagePhases}
            updateStagePhaseFunc={this.contractUpdateStagePhaseFunc}
          />
        )}
        <hr />
        <Comment />
      </Aux>
    )
  }
}

function mapStateToProps(state) {
  return {
    recipient: state.recipient.item,
    editPersonalInfo: state.recipient.editPersonalInfo,
    contract: state.recipient.contract,
    recipientStages: state.recipient.stages,
    stagePhases: state.recipient.stagePhases,
  }
}

function mapDispathToProps(dispatch) {
  return {
    personalInfoActions: bindActionCreators(personalInfoActions, dispatch),
    callCenter: bindActionCreators(callCenter, dispatch),
    contractActions: bindActionCreators(contractActions, dispatch),
    stagePhaseActions: bindActionCreators(stagePhaseActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispathToProps
)(RecipientDetailProfile)
