import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import PrintDebtAmnesty2019 from '../../containers/print/debt-amnesty2019';
import PrintBanningDepartureRequest from '../../containers/print/banning-departure-request';

const ScreensPrint = () => {
    function Print() {
        return (
            <div>
                <h2>Печать документов</h2>
                <Link to='/print/PrintDebtAmnesty2019'>Долговая амнистия</Link><br />
                <Link to='/print/PrintBanningDepartureRequest'>Заявление об ограничении права выезда с РФ</Link>
            </div>
        )
    }

    return (
        <Switch>
            <Route exact path='/print' component={Print} />
            <Route path='/print/PrintDebtAmnesty2019' component={PrintDebtAmnesty2019} />
            <Route path='/print/PrintBanningDepartureRequest' component={PrintBanningDepartureRequest} />
        </Switch>
    )
}

export default ScreensPrint;