import React from 'react'
import { Route, Switch } from 'react-router-dom'

import DocumentList from '../../components/document/list'
// import DocumentEdit from '../../components/document/edit'

const ScreenDocument = () => {
  return (
    <Switch>
      <Route path='/document-flow/outgoing'>
        <DocumentList isIncoming={false} />
      </Route>
      <Route path='/document-flow/incoming'>
        <DocumentList isIncoming={true} />
      </Route>
      {/* <Route path='/document-flow/:id' component={DocumentEdit} /> */}
    </Switch>
  )
}

export default ScreenDocument
