import React from 'react'
import { Row, Button, Col } from 'reactstrap'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'

let CommentForm = props => {
  return (
    <form onSubmit={props.handleSubmit}>
      <h6>Комментарий</h6>
      <Row>
        <Col>
          <Field component='textarea' className='form-control' name='comment' />
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col>
          {/* <Button outline color='secondary' onClick={props.cancelFunc}>
            Отмена
          </Button> */}
          <Button type='submit' color='light'>
            Сохранить комментарий
          </Button>
        </Col>
      </Row>
    </form>
  )
}

CommentForm = reduxForm({
  form: 'commentaryForm',
  enableReinitialize: true,
})(CommentForm)

CommentForm = connect(state => ({
  initialValues: {
    comment: state.recipient.item.personalInfo.comment,
  },
}))(CommentForm)

export default CommentForm
