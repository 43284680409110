import React, { Component } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

export default class RecipientPagination extends Component {
  render() {
    const {
      paging: { count, pageIndex, pageSize, pages },
      changePage,
      className,
    } = this.props

    const startItem = (pageIndex - 1) * pageSize + 1
    const endItem =
      startItem + pageSize - 1 > count ? count : startItem + pageSize - 1
    let dottedLink = false
    let showLink = 1

    const items = []
    for (let i = 1; i <= pages; i++) {
      if (i === pageIndex) {
        dottedLink = false
      }
      if (
        i <= showLink ||
        (pageIndex - 1 <= i && i <= pageIndex + 1) ||
        i === pages ||
        (pageIndex - showLink === 3 && showLink + 1 === i) ||
        (pageIndex + 2 === i && pages - 1 === i)
      ) {
        items.push(
          <PaginationItem key={i} active={i === pageIndex}>
            <PaginationLink
              href='#'
              onClick={() => {
                if (i !== pageIndex) {
                  changePage(i)
                }
              }}
            >
              {i}
            </PaginationLink>
          </PaginationItem>
        )
      } else {
        if (!dottedLink) {
          dottedLink = true

          items.push(
            <PaginationItem key={i} disabled>
              <PaginationLink href='#'>...</PaginationLink>
            </PaginationItem>
          )
        }
      }
    }

    return (
      <Pagination size='sm' className={className}>
        <PaginationItem
          disabled={pageIndex <= 1}
          onClick={() => {
            if (pageIndex > 1) {
              changePage(pageIndex - 1)
            }
          }}
        >
          <PaginationLink previous href='#' />
        </PaginationItem>
        {items}
        <PaginationItem
          disabled={pageIndex >= pages}
          onClick={() => {
            if (pageIndex < pages) {
              changePage(pageIndex + 1)
            }
          }}
        >
          <PaginationLink next href='#' />
        </PaginationItem>
        <div className='pl-2 mt-1'>
          {count === 0
            ? 'По вашему запросу ничего не найдено'
            : 'Показано с ' + startItem + ' по ' + endItem + ' из ' + count}
        </div>
      </Pagination>
    )
  }
}
