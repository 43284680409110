import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FormDatePicker from '../common/form-datepicker';
import { Row, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';

let TaxFilter = props => {
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit} className='col-12'>
            <div className='card mb-2'>
                <div className='card-header bg-primary text-white' role='tab' id='headingOne'>
                    <h5 className='mb-0'>
                        <span data-toggle='collapse' data-parent='#accordion' href='#collapseOne' aria-expanded='true' aria-controls='collapseOne' id='heading-one-link'>
                            Поиск
                    </span>
                    </h5>
                </div>

                <div className='card-block p-2'>
                    <div className='form-group row'>
                        <label htmlFor='search-date-start' className='col-12 col-sm-4 col-lg-2 col-form-label'>Дата c</label>

                        <div className='col-12 col-sm-8 col-lg-2 date'>
                            <Field component={FormDatePicker} name='from' placeholder='Дата с' autoComplete='off'></Field>
                        </div>
                        <label htmlFor='search-date-start' className='col-12 col-sm-4 col-lg-2 col-form-label'>Дата по</label>

                        <div className='col-12 col-sm-8 col-lg-2 date'>
                            <Field component={FormDatePicker} name='to' placeholder='Дата по' autoComplete='off'></Field>
                        </div>
                    </div>

                    <FormGroup>
                        <Row className='justify-content-end pr-3'>
                            {/* <Button outline color='secondary mr-2' onClick={reset}>Очистить</Button> */}
                            <input type='submit' value='Найти' className='btn btn-primary' id='filter-submit' />
                        </Row>
                    </FormGroup>
                </div>
            </div>
        </form>
    )
}

TaxFilter = reduxForm({
    form: 'taxFilter'
})(TaxFilter);

TaxFilter = connect(
    state => ({
        initialValues: state.accountingTax.filter // pull initial values
    }),
    //{ load: loadAccount } // bind account loading action creator
)(TaxFilter)


export default TaxFilter;