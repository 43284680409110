import React from 'react'
import { Link } from 'react-router-dom'

import * as helpers from '../../utils/helper'

const PersonInfoListItem = props => {
  return (
    <tr className={(props.item.hasActiveBankruptInfo ? 'bg-danger text-white' : '')}>
      <td>{props.item.cessionAgreementNumber}</td>
      <td>{props.item.cessionCreditOrganizationName}</td>
      <td>
        <Link to={'recipient/' + props.item.recipientId}>
          {props.item.lastName} {props.item.maidenName && '(' + props.item.maidenName + ')'}
          {props.item.firstName} {props.item.middleName}
        </Link>
      </td>
      <td>{props.item.creditAgreementNumber}</td>
      <td className='text-right text-nowrap'>
        {helpers.moneyToRub(props.item.totalDebt)}
      </td>
      <td className='text-right text-nowrap'>
        {helpers.moneyToRub(props.item.paidSum)}
      </td>
      <td className='text-right text-nowrap'>
        {helpers.moneyToRub(props.item.currentDebt)}
      </td>
      <td>{props.item.recipientStage}</td>
      <td>{props.item.stagePhase}</td>
      <td>{props.item.courtName}</td>
      <td>{props.item.exeProds.length > 0 ? props.item.exeProds[0].department : ''}</td>
      <td>
        {props.item.exeProds.map(ep => (
          <span key={ep.id}>
            {ep.number}
            <br />
          </span>
        ))}
      </td>
      <td>
        {props.item.exeProds.map(ep => (
          <span key={ep.id}>
            {helpers.dateToString(ep.createDate)}
            <br />
          </span>
        ))}
      </td>
      <td>
        {props.item.exeProds.map(ep => (
          <span key={ep.id}>
            {helpers.dateToString(ep.endDate)}
            <br />
          </span>
        ))}
      </td>
    </tr>
  )
}

export default PersonInfoListItem
