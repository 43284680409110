import React, { Component } from 'react'
import { Button } from 'reactstrap'

export default class PhoneCall extends Component {
    render() {
        const { phones,
            getCallBack } = this.props;

        var phoneArray = phones && phones.split(';');

        const renderPhones = phoneArray && phoneArray.map((value, index) => {
            value = value.trim();

            if (!value) {
                return null;
            }

            return (
                <span key={index}>
                    {value}
                    <Button
                        color="link"
                        size="sm"
                        onClick={() => { getCallBack(value) }}>
                        <i className="fa fa-phone-square mb-2" aria-hidden="true"></i>
                    </Button>{' '}
                </span>
            )
        })

        return (
            <span>
                {renderPhones}
            </span>
        )
    }
}