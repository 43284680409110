import React, { Component } from 'react';
import { ListGroup } from 'reactstrap';
import CallNotifyListItem from './list-item';

export default class CallNotifyList extends Component {
    componentDidMount() {
        this.props.searchFunc();
    }

    render() {
        const { list,
            downloadFileFunc,
            selectCallNotifyFunc } = this.props;

        const renderList = list && list.map((value) => {
            return (
                <CallNotifyListItem key={value.id}
                    item={value}
                    downloadFileFunc={downloadFileFunc}
                    selectCallNotifyFunc={selectCallNotifyFunc} />
            )
        })

        return (
            <div className='row'>
                <div className='col-xl-1 d-none d-xl-block'></div>
                <div className='col-xl-10 col-12'>
                    <h2>Список уведомлений</h2>
                    <ListGroup>
                        {renderList}
                    </ListGroup>
                    <div className='col-xl-1 d-none d-xl-block'></div>
                </div>
            </div>
        )
    }
}