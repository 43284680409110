import {
  PERSONAL_INFO_SET_FILTER,
  PERSONAL_INFO_CLEAR_FILTER,
} from '../constants/personal-info'

export const setFilter = filter => {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({ type: PERSONAL_INFO_SET_FILTER, payload: filter })
      resolve()
    })
  }
}

export const clearFilter = () => {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({ type: PERSONAL_INFO_CLEAR_FILTER })
      resolve()
    })
  }
}
