import axios from '../services/axios';
import {
  OK,
  FAIL,
  REQUEST,
  RECIPIENT_PAYMENT,
  CREATE,
  CANCEL,
  EDIT,
  IMPORT,
  SELECT,
  SAVE,
  DELETE,
} from '../constants/legal';
import { get as getContract } from '../actions/recipient-contract';
import { info, error } from 'react-notification-system-redux';

export function getAll(recipientId) {
  return dispatch => {
    dispatch({
      type: RECIPIENT_PAYMENT + REQUEST
    });

    axios.get('/recipient/' + recipientId + '/payments')
      .then(result => {
        dispatch({
          type: RECIPIENT_PAYMENT + OK,
          payload: result.data
        })
      })
      .catch(result => {
        dispatch({
          type: RECIPIENT_PAYMENT + FAIL,
          errors: result.message
        })
      });
  }
}

export function create() {
  return dispatch => {
    dispatch({
      type: RECIPIENT_PAYMENT + CREATE,
    })
  }
}

export function cancel() {
  return dispatch => {
    dispatch({
      type: RECIPIENT_PAYMENT + CREATE + CANCEL
    })
  }
}

export function save(values) {
  return dispatch => {
    if (values.id) {
      dispatch({
        type: RECIPIENT_PAYMENT + EDIT + REQUEST,
      })

      axios.put('/recipient/' + values.recipientId + '/payment/' + values.id, values)
        .then(result => {
          dispatch({
            type: RECIPIENT_PAYMENT + EDIT + OK,
            payload: result.data
          });

          dispatch(getContract(values.recipientId));
        })
        .catch(result => {
          dispatch(
            error({
              title: 'Ошибка редактирования платежа',
              message: result.response.data.error || result.message
            }));
        });
    } else {
      dispatch({
        type: RECIPIENT_PAYMENT + CREATE + REQUEST,
      })

      axios.post('/recipient/' + values.recipientId + '/payments', values)
        .then(result => {
          dispatch({
            type: RECIPIENT_PAYMENT + CREATE + OK,
            payload: result.data
          });

          dispatch(getContract(values.recipientId));
        })
        .catch(result => {
          dispatch(
            error({
              title: 'Ошибка создания платежа',
              message: result.response.data.error || result.message
            }));
        });
    }
  }
}

export function importFile(fileData) {
  return dispatch => {
    dispatch({
      type: RECIPIENT_PAYMENT + IMPORT + REQUEST,
    })

    const data = new FormData();
    if (!fileData) {
      return;
    }

    data.append('formFile', fileData);

    axios.post('/payments/import', data)
      .then(result => {
        dispatch({
          type: RECIPIENT_PAYMENT + IMPORT + OK,
          payload: result.data
        });
      })
      .catch(result => {
        dispatch(
          error({
            title: 'Ошибка импорта платежей',
            message: result.response.data.error || result.message
          }));
      });
  }
}

export const edit = value => {
  return dispatch => {
    dispatch({ type: RECIPIENT_PAYMENT + EDIT, payload: value })
  }
}

export const selectImportPayment = value => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_PAYMENT + IMPORT + SELECT,
      payload: value
    })
  }
}

export const cancelImportPayment = () => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_PAYMENT + IMPORT + CANCEL,
    })
  }
}

export const addSelectedImportPayment = (values, recipientId) => {
  return dispatch => {
    values.recipientId = recipientId;

    axios.post('/recipient/' + values.recipientId + '/payments', values)
      .then(result => {
        dispatch({
          type: RECIPIENT_PAYMENT + IMPORT + SELECT + SAVE,
          payload: result.data
        });

        dispatch(
          info({
            title: 'Платеж успешно добавлен',
            message: result.data.comment
          }));
      })
      .catch(result => {
        dispatch(
          error({
            title: 'Ошибка создания платежа',
            message: result.response.data.error || result.message
          }));
      });
  }
}

export const cancelSelectedImportPayment = () => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_PAYMENT + IMPORT + SELECT + CANCEL
    })
  }
}

export const paymentDelete = (item) => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_PAYMENT + DELETE + REQUEST
    })

    axios.delete('/recipient/' + item.recipientId + '/payment/' + item.id)
      .then(() => {
        dispatch({
          type: RECIPIENT_PAYMENT + DELETE + OK,
          payload: item.id
        })

        dispatch(getAll(item.recipientId));
      })
  }
}