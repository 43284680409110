import axios from '../axios'

import {
    SEARCH_URL,
    DOCUMENTS_BY_RECIPIENT
} from '../../constants/personal-info'

export const search = filter => axios.post(SEARCH_URL, filter);

export const getDocumentsByRecipient = recipientId => axios.get(DOCUMENTS_BY_RECIPIENT.replace('{recipientId}', recipientId));
