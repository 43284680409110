import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotificationList from '../../containers/Notification/List';

const ScreensNotification = () => {
    return (
        <Switch>
            <Route exact path='/notification' component={NotificationList} />
        </Switch>
    )
}

export default ScreensNotification;