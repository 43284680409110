import axios from '../services/axios'
import {
  OK,
  FAIL,
  REQUEST,
  RECIPIENT_COURT_DECISION,
  CREATE,
  CANCEL,
  EDIT,
  DELETE,
  EXE_PRODUCTION_REQUEST_MODEL,
  GET_EXE_PRODUCTION_REQUEST_MODEL,
} from '../constants/legal'
import { error, info } from 'react-notification-system-redux'

export function get(recipientId) {
  return dispatch => {
    dispatch({
      type: RECIPIENT_COURT_DECISION + REQUEST,
    })

    axios
      .get('/recipient/' + recipientId + '/judical-acts')
      .then(result => {
        dispatch({
          type: RECIPIENT_COURT_DECISION + OK,
          payload: result.data,
        })
      })
      .catch(result => {
        dispatch({
          type: RECIPIENT_COURT_DECISION + FAIL,
          errors: result.message,
        })
      })
  }
}

export function create() {
  return dispatch => {
    dispatch({
      type: RECIPIENT_COURT_DECISION + CREATE,
    })
  }
}

export function cancel() {
  return dispatch => {
    dispatch({
      type: RECIPIENT_COURT_DECISION + CREATE + CANCEL,
    })
  }
}

export function save(values) {
  return dispatch => {
    if (values.id) {
      dispatch({
        type: RECIPIENT_COURT_DECISION + EDIT + REQUEST,
      })

      axios
        .put(
          '/recipient/' + values.recipientId + '/judical-act/' + values.id,
          values
        )
        .then(result => {
          dispatch({
            type: RECIPIENT_COURT_DECISION + EDIT + OK,
            payload: result.data,
          })
        })
        .catch(result => {
          dispatch(
            error({
              title: 'Ошибка редактирования судебного дела',
              message: result.response.data.error || result.message
            })
          )
        })
    } else {
      dispatch({
        type: RECIPIENT_COURT_DECISION + CREATE + REQUEST,
      })

      axios
        .post('/recipient/' + values.recipientId + '/judical-acts', values)
        .then(result => {
          dispatch({
            type: RECIPIENT_COURT_DECISION + CREATE + OK,
            payload: result.data,
          })
        })
        .catch(result => {
          dispatch(
            error({
              title: 'Ошибка создания судебного дела',
              message: result.response.data.error || result.message
            })
          )
        })
    }
  }
}

export const edit = values => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_COURT_DECISION + EDIT,
      payload: values,
    })
  }
}

export const remove = values => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_COURT_DECISION + DELETE + REQUEST,
    })

    axios
      .delete(
        '/recipient/' +
          values.recipientId +
          '/judical-act/' +
          values.courtDecisionId
      )
      .then(() => {
        dispatch({
          type: RECIPIENT_COURT_DECISION + DELETE + OK,
          payload: values.courtDecisionId,
        })

        dispatch(
          info({
            title: 'Судебное дело',
            message: 'Запись удалена',
          })
        )
      })
      .catch(result => {
        dispatch(
          error({
            title: 'Ошибка удаления судебного дела',
            message: result.response.data.error || result.message
          })
        )
      })
  }
}

export const getExeProductionRequestModel = judicalActId => {
  return dispatch => {
    dispatch({
      type: EXE_PRODUCTION_REQUEST_MODEL,
    })

    axios
      .get(GET_EXE_PRODUCTION_REQUEST_MODEL + judicalActId)
      .then(result => {
        dispatch({
          type: EXE_PRODUCTION_REQUEST_MODEL + OK,
          payload: result.data,
        })
      })
      .catch(result => {
        dispatch(
          error({
            title: 'Ошибка при получении данных для заявления о ВИП',
            message: result.response.data.error || result.message
          })
        )
      })
  }
}

export const exeProductionRequestCancel = () => {
  return dispatch => {
    dispatch({
      type: EXE_PRODUCTION_REQUEST_MODEL + CANCEL,
    })
  }
}
