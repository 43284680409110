import React, { useState, useEffect } from 'react'
import Pagination from '../common/pagination'
import DocumentEditForm from './edit-form'
import DocumentListOutgoing from './list-outgoing'
import DocumentListIncoming from './list-incoming'

import * as documentFlowApi from '../../services/webapi/document-flow-api'

const DocumentList = props => {
  const [list, setList] = useState([])
  const [totalCount, setTotalCount] = useState(-1)
  const [pageIndex, setPageIndex] = useState(1);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editItemId, setEditItemId] = useState({});
  const [isIncoming, setIsIncoming] = useState(props.isIncoming);

  const toggleEditForm = (withSearch) => {
    setShowEditForm(!showEditForm);

    if (withSearch === true) {
      setPageIndex(1);
      search();
    }
  };

  const paging = {
    count: totalCount,
    pageIndex: pageIndex,
    pageSize: 50,
    pages: Math.ceil(totalCount / 50),
  }

  const search = () => {
    documentFlowApi
      .search({
        pageNumber: pageIndex,
        pageSize: paging.pageSize,
        isIncoming: props.isIncoming,
      })
      .then(result => {
        setList(result.data.pageData);
        setTotalCount(result.data.totalCount)
      })
      .catch(err => {
        console.log(err)
      })
  }

  if (isIncoming !== props.isIncoming) {
    setIsIncoming(props.isIncoming);
  }

  const editItemFunc = (itemId) => {
    setShowEditForm(true);
    setEditItemId(itemId);
  }

  useEffect(() => {
    search();
  }, [pageIndex, isIncoming])

  const DocumentListPaging = () => {
    return (
      <>
        <div className='float-right'>
          <br />
          <Pagination paging={paging} changePage={setPageIndex} />
        </div>

        {
          showEditForm ?
            <DocumentEditForm toggle={toggleEditForm} isIncoming={isIncoming} itemId={editItemId} /> :
            null
        }
      </>)
  }

  if (isIncoming) {
    return (
      <DocumentListIncoming list={list} editItemFunc={editItemFunc}>
        <DocumentListPaging />
      </DocumentListIncoming>
    )
  } else {
    return (
      <DocumentListOutgoing list={list} editItemFunc={editItemFunc}>
        <DocumentListPaging />
      </DocumentListOutgoing>
    )
  }
}

export default DocumentList;