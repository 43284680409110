import React from 'react';
import Aux from '../../../hoc/ReactAux/ReactAux';

import ReportTemplateList from '../../../containers/report-template/list/list';

const ScreensReportTemplateList = () => {
    return (
        <Aux>
            <h2>Шаблоны документов</h2>
            <ReportTemplateList />
        </Aux>
    )
}

export default ScreensReportTemplateList;
