import axios from '../services/axios';
import {
    LAW_COURTS,
    OK,
    FAIL,
    REQUEST,
    LAW_COURTS_LIST,
    LAW_COURT_SELECT,
    LAW_COURT_RECIPIENTS,
} from '../constants/legal';
import { error } from 'react-notification-system-redux';

import * as lawCourtApi from '../services/webapi/law-court-api'

export const searchAutoComplete = (value) => {
    return dispatch => {
        dispatch({
            type: LAW_COURTS + REQUEST
        });


        lawCourtApi.searchAutocomplete(value)
            .then(result => {
                dispatch({
                    type: LAW_COURTS + OK,
                    payload: result.data,
                })
            })
            .catch(result => {
                dispatch({
                    type: LAW_COURTS + FAIL,
                    errors: result.message
                })

                dispatch(
                    error({
                        title: 'LawCourt Autocomplete',
                        message: result.response.data.error || result.message
                    }));
            });
    }
}

export const search = (value) => {
    return dispatch => {
        dispatch({
            type: LAW_COURTS_LIST + REQUEST
        });

        axios.post('/law-court/search', value)
            .then(result => {
                dispatch({
                    type: LAW_COURTS_LIST + OK,
                    payload: result.data
                });
            })
            .catch(result => {
                dispatch({
                    type: LAW_COURTS_LIST + FAIL,
                    errors: result.message
                });

                dispatch(
                    error({
                        title: 'LawCourt Search',
                        message: result.response.data.error || result.message
                    }));
            })
    }
}

export const selectDetail = (lawCourtId) => {
    return dispatch => {
        dispatch({
            type: LAW_COURT_SELECT,
            payload: lawCourtId
        });

        axios.get('/law-court/' + lawCourtId)
            .then(result => {
                dispatch({
                    type: LAW_COURT_SELECT + OK,
                    payload: result.data
                });
            })
            .catch(result => {
                dispatch({
                    type: LAW_COURT_SELECT + FAIL,
                    errors: result.message
                });

                dispatch(
                    error({
                        title: 'LawCourt Select Detail',
                        message: result.response.data.error || result.message
                    })
                );
            })
    }
}

export const getRecipients = (lawCourtId) => {
    return dispatch => {
        dispatch({
            type: LAW_COURT_RECIPIENTS
        });

        axios.post('/law-court/' + lawCourtId + '/recipients')
            .then(result => {
                dispatch({
                    type: LAW_COURT_RECIPIENTS + OK,
                    payload: result.data
                })
            })
            .catch(result => {
                dispatch({
                    type: LAW_COURT_RECIPIENTS + FAIL,
                    errors: result.message
                });

                dispatch(
                    error({
                        title: 'LawCourt get recipients',
                        message: result.response.data.error || result.message
                    })
                );
            })
    }
}