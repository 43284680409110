import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ScreensLawCourtList from './list';

const ScreensLawCourt = () => {
    return (
        <Switch>
            <Route path='/catalogs/law-court' exact component={ScreensLawCourtList} />
        </Switch>
    )
}

export default ScreensLawCourt;