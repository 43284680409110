import React, { Component } from 'react'
// import DatePicker from 'react-datepicker';
// import 'moment/locale/ru';
import moment from 'moment'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import './datepicker.css';
// import PropTypes from 'prop-types';

class FormDatePicker extends Component {
  constructor(props) {
    super(props)

    this.state = { focused: false }
  }

  returnYears = () => {
    let years = []
    for (let i = moment().year() - 70; i <= moment().year() + 10; i++) {
      years.push(
        <option key={'key' + i} value={i}>
          {i}
        </option>
      )
    }
    return years
  }

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <select
          value={month.month()}
          onChange={e => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option key={label} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          value={month.year()}
          onChange={e => onYearSelect(month, e.target.value)}
        >
          {this.returnYears()}
        </select>
      </div>
    </div>
  )

  render() {
    const {
      input: { value, onChange },
      placeholder,
      name,
    } = this.props

    return (
      <div className='input-group'>
        <SingleDatePicker
          date={value ? moment.utc(value).startOf('day') : null} // momentPropTypes.momentObj or null
          onDateChange={value => {
            const changeDate = moment.utc(value)
            onChange(changeDate.isValid() ? changeDate.format('YYYY-MM-DD') : value)
          }} // PropTypes.func.isRequired
          focused={this.state.focused} // PropTypes.bool
          onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
          id={name} // PropTypes.string.isRequired,
          placeholder={placeholder}
          numberOfMonths={1}
          anchorDirection='right'
          isOutsideRange={() => false}
          small={true}
          block
          renderMonthElement={this.renderMonthElement}
        />
      </div>
    )
  }
}

export default FormDatePicker
