import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Aux from '../../../hoc/ReactAux/ReactAux';

import RecipientPaymentListItem from '../../payment/list-item';
import CallNotifyListItem from '../../call-notify/list-item';

import * as callNotifyActions from '../../../actions/call-notify';
import * as paymentActions from '../../../actions/recipient-payment';

class RecipientDetailTop extends Component {
    addSelectedImportPaymentFunc = value => {
        this.props.paymentActions.addSelectedImportPayment(value, this.props.match.params.id);
        this.props.history.push('/file/import-payment');
    }

    cancelSelectedImportPaymentFunc = () => {
        this.props.paymentActions.cancelSelectedImportPayment();
        this.props.history.push('/file/import-payment');
    }

    addSelectedCallNotifyFunc = () => {
        this.props.callNotifyActions.addSelectedCallNotify(
            this.props.selectedCallNotify.id,
            this.props.match.params.id);
        this.props.history.push('/call-notify/list');
    }

    cancelSelectedCallNotifyFunc = () => {
        this.props.callNotifyActions.cancelSelectedCallNotify();
        this.props.history.push('/call-notify/list');
    }

    render() {
        const { selectedImportPayment,
            selectedCallNotify,
            recipientId } = this.props;

        return (
            <Aux>
                {selectedImportPayment ?
                    <RecipientPaymentListItem item={selectedImportPayment}
                        color='warning'
                        recipientId={recipientId}
                        addSelectedImportPaymentFunc={this.addSelectedImportPaymentFunc}
                        cancelSelectedImportPaymentFunc={this.cancelSelectedImportPaymentFunc}></RecipientPaymentListItem> :
                    selectedCallNotify ?
                        <CallNotifyListItem item={selectedCallNotify}
                            color='warning'
                            recipientId={recipientId}
                            addSelectedCallNotifyFunc={this.addSelectedCallNotifyFunc}
                            cancelSelectedCallNotifyFunc={this.cancelSelectedCallNotifyFunc}></CallNotifyListItem> :
                        null}
            </Aux>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedImportPayment: state.payment.import.selected,
        selectedCallNotify: state.callNotify.selected,
    }
}

function mapDispathToProps(dispatch) {
    return {
        callNotifyActions: bindActionCreators(callNotifyActions, dispatch),
        paymentActions: bindActionCreators(paymentActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(withRouter(RecipientDetailTop));