import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card, CardBody, CardHeader } from 'reactstrap'
import Aux from '../../../hoc/ReactAux/ReactAux'

import ActivityEditForm from '../../activity/edit'
import ActivityList from '../../activity/list'
import PaymentEditForm from '../../payment/edit'
import RecipientPayments from '../../payment/list'
import CourtDecisionEditForm from '../../court-decision/edit'
import ImportExeProds from '../../executing-productions/import-list'
import PledgeItemEditForm from '../../pledge-item/edit-form'
import RecipientDetailDocumentLinks from './document/links';

import * as activityActions from '../../../actions/recipient-activity'
import * as fileActions from '../../../actions/file'
import * as paymentActions from '../../../actions/recipient-payment'
import * as courtDecisionActions from '../../../actions/recipient-court-decision'
import * as executingProductionActions from '../../../actions/recipient-executing-production'

import { CONTRACT, DOCS } from '../../../constants/recipient'
import { dateToString } from '../../../utils/helper'

class RecipientDetailInfo extends Component {
  componentDidMount() {
    this.props.activityActions.getAll(this.props.recipientId)
    this.props.paymentActions.getAll(this.props.recipientId)
    this.props.activityActions.getAllActivityTypes()
    this.props.executingProductionActions.importFromSite(
      this.props.recipientId,
      true
    )
  }

  addExecutingProduction = values => {
    this.props.executingProductionActions.add({
      ...values,
      personalInfoId: this.props.recipient.personalInfo.id,
      recipientId: this.props.recipientId,
    })
  }

  getEditedActivityType = value => value.id === this.props.editActivity.typeId

  activitySave = values => {
    let files =
      this.props.editActivity.files &&
      this.props.editActivity.files.map(element => {
        return {
          fileID: element.fileID,
          recipientFileTypeId:
            element.recipientFileTypeId ||
            '4b05bd0e-8261-4440-b81f-380d6e110f02',
        }
      })

    this.props.activityActions.save({
      comment: values.comment,
      files: files,
      recipientId: this.props.recipientId,
      recipientActivityType: { id: this.props.editActivity.typeId },
      recipientPersonalInfoID: this.props.recipient.personalInfo.id,
      id: this.props.editActivity.item && this.props.editActivity.item.id,
      regNumber: values.regNumber,
      regDate: values.regDate,
    })
  }

  activityDelete = value => {
    this.props.activityActions.activityDelete(this.props.recipientId, value.id)
  }

  paymentSave = values => {
    this.props.paymentActions.save({
      ...values,
      amount: values.amount.toString().replace(',', '.'),
      recipientId: this.props.recipientId,
    })
  }

  courtDecisionSaveFunc = values => {
    this.props.courtDecisionActions.save({
      ...values,
      recipientId: this.props.recipientId,
    })
  }

  render() {
    const {
      editActivity,
      activityTypes,
      activities,
      activityActions,
      tabId,
      paymentActions,
      editPayment,
      payments,
      editCourtDecision,
      importExeProd,
      courtDecisionActions,
      fileActions,
      editPledgeItem,
      recipientId,
      recipient,
    } = this.props

    let detailInfoRender = null;

    if (editActivity.formEnable) {
      detailInfoRender = <ActivityEditForm
        activityType={activityTypes.filter(this.getEditedActivityType)}
        editActivity={editActivity}
        onSubmit={this.activitySave}
        removeFileFunc={activityActions.editActivityRemoveFile}
        addFileFunc={activityActions.editActivityAddFile}
        cancelFunc={activityActions.cancel}
      />;
    } else if (editPayment.formEnable) {
      detailInfoRender = <PaymentEditForm
        onSubmit={this.paymentSave}
        cancelFunc={paymentActions.cancel}
        create={editPayment.create}
      />;
    } else if (editCourtDecision.formEnable) {
      detailInfoRender = <CourtDecisionEditForm
        item={{}}
        cancelFunc={courtDecisionActions.cancel}
        onSubmit={this.courtDecisionSaveFunc}
      />;
    } else if (importExeProd.formEnable || importExeProd.offline) {
      detailInfoRender = <Card color='light' className='mb-2'>
        <CardHeader tag='h5'>
          Данные с ФССП{' '}
          {importExeProd.offline
            ? (recipient.personalInfo.lastCheckExeProdsDate ? '(от ' + recipient.personalInfo.lastCheckExeProdsDate + ')' : 'отсутствует')
            : '(online)'}
        </CardHeader>
        <CardBody>
          <ImportExeProds
            list={importExeProd.list.exeProds}
            add={this.addExecutingProduction}
          />
        </CardBody>
      </Card>
    } else if (editPledgeItem.isShow) {
      detailInfoRender = <PledgeItemEditForm
        item={editPledgeItem.item}
        cancelFunc={editPledgeItem.cancelFunc}
        saveOrUpdateFunc={editPledgeItem.saveOrUpdateFunc}
      />
    } else if (tabId === CONTRACT) {
      detailInfoRender = <RecipientPayments
        payments={payments}
        editFunc={paymentActions.edit}
        deleteFunc={paymentActions.paymentDelete}
      />;
    } else if (tabId === DOCS) {
      detailInfoRender = <RecipientDetailDocumentLinks recipientId={recipientId} />
    } else {
      detailInfoRender = <ActivityList
        activities={activities}
        downloadFileFunc={fileActions.downloadFile}
        editFunc={activityActions.edit}
        deleteFunc={this.activityDelete}
      />;
    }

    return (
      <Aux>
        {detailInfoRender}
      </Aux>
    )
  }
}

function mapStateToProps(state) {
  return {
    editActivity: state.activity.editActivity,
    activityTypes: state.activity.activityTypes,
    activities: state.activity.activities,
    editPayment: state.payment.editPayment,
    payments: state.payment.list,
    editCourtDecision: state.recipient.editCourtDecision,
    importExeProd: state.recipient.importExeProd,
    recipient: state.recipient.item,
  }
}

function mapDispathToProps(dispatch) {
  return {
    fileActions: bindActionCreators(fileActions, dispatch),
    activityActions: bindActionCreators(activityActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    courtDecisionActions: bindActionCreators(courtDecisionActions, dispatch),
    executingProductionActions: bindActionCreators(
      executingProductionActions,
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispathToProps)(RecipientDetailInfo)
