import axios from '../axios'

import {
  CONTRACT_SEARCH_NUMBERS_URL,
  CONTRACT_GET_CESSION_NUMBERS_URL,
} from '../../constants/contract'

export const searchNumbers = filter =>
  axios.post(CONTRACT_SEARCH_NUMBERS_URL, filter)

export const getCessionNumbers = () =>
  axios.get(CONTRACT_GET_CESSION_NUMBERS_URL)
