import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensRecipientList from './list';
import ScreensRecipientDetail from './detail';

const ScreensRecipient = () => {
    return (
        <Switch>
            <Route exact path='/recipient' component={ScreensRecipientList} />
            <Route path='/recipient/:id' component={ScreensRecipientDetail} />
        </Switch>
    )
}

export default ScreensRecipient;