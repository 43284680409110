import React from 'react';
import moment from 'moment';
import { Alert, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import Aux from '../../../hoc/ReactAux/ReactAux';

import FileList from '../../file/list';

const RecipientDetailSuccession = (props) => {
    let successionRender = (
        <Alert color='primary'>
            Определение о правоприемстве отсутствует
        </Alert>
    );

    if (props.activities) {
        var successionActivity = props.activities.find(value => {
            return value.isActive && value.recipientActivityType.id === '693cbb4a-186d-47a5-bd31-21a8813eef9f'
        });

        if (successionActivity) {
            successionRender = (
                <Card className='mb-2 p-0' body outline color='primary'>
                    <CardBody>
                        <CardTitle>
                            <div className='d-flex justify-content-between'>
                                <span>{successionActivity.recipientActivityType.name}</span>
                                <small className='text-right text-muted'>
                                    {moment.utc(successionActivity.creationDate).local().format('DD.MM.YYYY ')}
                                    {moment.utc(successionActivity.creationDate).local().format('HH:mm')}
                                </small>
                            </div></CardTitle>
                        <CardText>
                            <span>
                                <span className='col-xs-6'>{successionActivity.comment}</span>
                                <span className='col-xs-6'>
                                    <FileList files={successionActivity.files}
                                        downloadFileFunc={props.downloadFileFunc} />
                                </span>
                            </span></CardText>
                    </CardBody>
                </Card>
            );
        }
    }

    return (
        <Aux>
            {successionRender}
        </Aux>
    )
}

export default RecipientDetailSuccession;