import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CheckLosts from '../components/file/check-losts';
import * as fileActions from '../actions/file';
import ImportFileForm from '../components/file/import';
import { SubmissionError } from 'redux-form';
import ImportPaymentForm from '../components/file/import-payment';
import * as paymentActions from '../actions/recipient-payment';
import ImportProcessPayments from '../components/payment/import-process';

class Files extends Component {
    importFile = values => {
        if (!values.importFile || !values.bankCode) {
            throw new SubmissionError({
                importFile: !values.importFile && 'Необходимо выбрать файл',
                bankCode: !values.bankCode && 'Необходимо выбрать название банка',
                _error: 'Ошибка импорта'
            })
        }

        this.props.fileActions.importFileFromBank(values.importFile, values.bankCode);
    }

    importPaymentFile = values => {
        if (!values.importFile) {
            throw new SubmissionError({
                importFile: !values.importFile && 'Необходимо выбрать файл',
                _error: 'Ошибка импорта'
            })
        }

        this.props.paymentActions.importFile(values.importFile);
    }

    selectImportPaymentFunc = value => {
        this.props.paymentActions.selectImportPayment(value);
        this.props.history.push('/recipient')
    }

    render() {
        const { files,
            importPayment } = this.props;
        const { getLosts,
            attachFile,
            removeFile,
            saveLostFiles } = this.props.fileActions;

        return (
            <Switch>
                <Route path='/file/check-lost'
                    render={(props) =>
                        <CheckLosts {...props}
                            files={files}
                            getLosts={getLosts}
                            attachFile={attachFile}
                            removeFile={removeFile}
                            saveLostFiles={saveLostFiles}>
                        </CheckLosts>
                    }>
                </Route>
                <Route path='/file/import'
                    render={props =>
                        <ImportFileForm {...props}
                            onSubmit={this.importFile} >
                        </ImportFileForm>
                    }>
                </Route>
                <Route path='/file/import-payment'
                    render={props =>
                        <div>
                            <Row>
                                <h3>Импорт платежей</h3>
                                {importPayment.process ?
                                    <div className="col align-self-end mb-2">
                                        <Button outline color='secondary'
                                            className='float-right'
                                            onClick={this.props.paymentActions.cancelImportPayment}>Отмена</Button>
                                    </div> : null}
                            </Row>
                            {importPayment.process ?
                                <ImportProcessPayments
                                    importPayment={importPayment}
                                    selectFunc={this.selectImportPaymentFunc}>
                                </ImportProcessPayments> :
                                <ImportPaymentForm {...props}
                                    onSubmit={this.importPaymentFile} />}
                        </div>}>
                </Route>
            </Switch>
        )
    }
}

function mapStateToProps(state) {
    return {
        files: state.file.list,
        importPayment: state.payment.import,
    }
}

function mapDispathToProps(dispatch) {
    return {
        fileActions: bindActionCreators(fileActions, dispatch),
        paymentActions: bindActionCreators(paymentActions, dispatch),
    }
}

Files.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default connect(mapStateToProps, mapDispathToProps)(withRouter(Files));