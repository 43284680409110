import axios from '../axios'

import {
  GET_URL,
  GET_LIST_BY_RECIPIENT_ID_URL,
  SAVE_OR_UPDATE_URL,
  REMOVE_URL,
  VIN_SEARCH_AUTOCOMPLETE_URL
} from '../../constants/pledge-item'

export const getById = id => axios.get(GET_URL + id)

export const getListByRecipientId = id =>
  axios.get(GET_LIST_BY_RECIPIENT_ID_URL + id)

export const saveOrUpdate = item => axios.post(SAVE_OR_UPDATE_URL, item)

export const remove = id => axios.delete(REMOVE_URL + id)

export const searchAutocomplete = value => axios.get(VIN_SEARCH_AUTOCOMPLETE_URL + value)
