import { OK } from '../constants/legal';
import {
    EXE_PRODUCTION_LIST,
    PRINT,
    ELEMENT_TOGGLE,
    SET_ALL,
} from '../constants/exe-prod';

const initialState = {
    list: [],
    printList: []
};

export const exeProd = (state = initialState, action = {}) => {
    switch (action.type) {
        case EXE_PRODUCTION_LIST + PRINT + OK:
            action.payload && action.payload.map(value => {
                value.checked = true;
            });

            return {
                ...state,
                printList: action.payload
            }

        case EXE_PRODUCTION_LIST + PRINT + ELEMENT_TOGGLE:
            {
                let printList = [...state.printList];
                printList.map(value => {
                    if (value.id === action.payload) {
                        value.checked = !value.checked;
                    }
                });

                return {
                    ...state,
                    printList
                }
            }

        case EXE_PRODUCTION_LIST + PRINT + SET_ALL:
            {
                let printList = [...state.printList];
                printList.map(value => {
                    value.checked = action.payload;
                });

                return {
                    ...state,
                    printList
                }
            }

        default:
            return state;
    }
}