import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ListGroupItem, Row, Col } from 'reactstrap';
import './list-item.css';

import * as helperActions from '../../utils/helper';

class RecipientListItem extends Component {
    openDetailClick = () => {
        this.props.history.push('/recipient/' + this.props.recipient.id);
    }

    render() {
        const { recipient } = this.props;

        if (!recipient) { return null; }

        let color = '';
        switch (recipient.stageId) {
            case 5:
                color = 'text-danger';
                break;
            case 6:
                color = 'text-success';
                break;
        }

        return (
            <ListGroupItem className='border-left-red'
                action
                onClick={this.openDetailClick}>
                <Row>
                    <Col className={color}>
                        <small className='float-right text-right text-muted'>
                            {recipient.bankName} ({recipient.cessionAgreementNumber})
                        </small>
                        <h5>{recipient.name} <small className='text-muted'>{recipient.birthDate}</small></h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs='12' sm='3'>
                        <span><small className='mr-1'>Кредитный Договор:</small>№ {recipient.creditAgreementNumber}</span>
                        <span className='badge badge-light'>{recipient.creditType}</span>
                    </Col>
                    <Col xs='12' sm='3'>
                        {recipient.judicalActNumber || recipient.judicalActDate ?
                            <span><small>Судебное Дело:</small><span className='ml-1'>№ {recipient.judicalActNumber} от {recipient.judicalActDate}</span></span> : null}
                    </Col>
                    <Col xs='12' sm='6' className='text-right'>
                        <mark className='mr-1'>
                            {recipient.stage}
                        </mark>
                        <br />
                        <u>{recipient.stagePhase}</u>
                    </Col>
                </Row>
                <Row>
                    <Col xs='12'>
                        <span>
                            <small className='mr-1'>Дата последнего платежа:</small>
                            {recipient.lastPaymentDate ?
                                helperActions.dateToString(recipient.lastPaymentDate) :
                                'платежи отсутствуют'}
                        </span>
                    </Col>
                </Row>
            </ListGroupItem>
        )
    }
}

RecipientListItem.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(RecipientListItem);