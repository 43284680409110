import {
    OK,
    ACCOUNTING_TAX_FILTER,
} from '../constants/legal';

// Get a date object for the current time
var filterDateFrom = new Date();
// Set it to one month ago
//filterDateFrom.setMonth(filterDateFrom.getMonth() - 1);
filterDateFrom.setDate(1);
// Zero the hours
filterDateFrom.setUTCHours(0, 0, 0);

var filterDateTo = new Date();
// Zero the hours
filterDateTo.setUTCHours(0, 0, 0);

const initialState = {
    list: [],
    total: {},
    filter: { from: filterDateFrom, to: filterDateTo },
};

export const accountingTax = (state = initialState, action = {}) => {
    switch (action.type) {
        case ACCOUNTING_TAX_FILTER + OK:
            return {
                list: action.payload,
                total: action.total,
                filter: action.filter
            }

        default:
            return state;
    }
}