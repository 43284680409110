import jwtDecode from 'jwt-decode';
import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru-RU');

export function GetUserInfo(token) {
    const decodedToken = jwtDecode(token);
    const username = decodedToken[Object.keys(decodedToken)[0]];
    const roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    const organizationName = decodedToken['organization_name'];

    let userRoles = [];

    if (roles && !Array.isArray(roles)) {
        userRoles = roles;
    } else if (roles) {
        userRoles.push(roles);
    }

    return {
        username,
        isAdmin: isAdmin(userRoles),
        isSupervisor: isSupervisor(userRoles),
        organizationName,
    };
}

export function isAdmin(roles) {
    return roles.includes('Admin')
}

export function isSupervisor(roles) {
    return roles.includes('Supervisor') || isAdmin(roles);
}

export function moneyToRub(value) {
    if (value) {
        return value.toLocaleString('ru-RU');
    }

    return 0;
}

export function moneyToRubWithCurrency(value) {
    return moneyToRub(value) + ' руб';
}

export function dateToString(value) {
    if (!value) {
        return null;
    }

    return moment.utc(value).local().format('DD.MM.YYYY');
}

export function replaceCommaToDot(value) {
    if (!value) {
        return 0;
    }

    if (!isNaN(value)) {
        return value;
    }

    return value.replace(',', '.');
}