import React from 'react'
import {
  FormGroup,
  ListGroup,
  Row,
  ListGroupItem,
  ListGroupItemText,
  ListGroupItemHeading,
  Button,
  UncontrolledTooltip,
} from 'reactstrap'
import Aux from '../../hoc/ReactAux/ReactAux'

const PledgeItemList = props => {
  const renderList = props.list.map(value => {
    return (
      <ListGroupItem key={value.id}>
        <ListGroupItemHeading>
          {value.brand} {value.model} ({value.yearOfIssue})
        </ListGroupItemHeading>
        <ListGroupItemText>
          <Button
            outline
            color='danger'
            className='float-right'
            onClick={() => {
              props.removeFunc(value.id)
            }}
            id={'PledgeItemRemove' + value.id}
          >
            <i className='fa fa-trash-o' aria-hidden='true' />
          </Button>
          <UncontrolledTooltip
            placement='top'
            target={'PledgeItemRemove' + value.id}
          >
            Удалить
          </UncontrolledTooltip>

          <Button
            color='link'
            className='float-right'
            onClick={() => {
              props.editFunc(value)
            }}
            id={'PledgeItemEdit' + value.id}
          >
            <i className='fa fa-pencil-square-o' aria-hidden='true' />
          </Button>
          <UncontrolledTooltip
            placement='top'
            target={'PledgeItemEdit' + value.id}
          >
            Изменить
          </UncontrolledTooltip>

          <span className='text-muted'>{value.vinDesc}:&nbsp;</span>
          {value.vin}
          <br />
          <span className='text-muted'>{value.colorDesc}:&nbsp;</span>
          {value.color}
          <br />
          <span className='text-muted'>{value.regNumberDesc}:&nbsp;</span>
          {value.regNumber}
          <br />
          <span className='text-muted'>{value.statusDesc}:&nbsp;</span>
          {value.status}
        </ListGroupItemText>
      </ListGroupItem>
    )
  })

  return (
    <Aux>
      <FormGroup>
        {props.list.length > 0 ? (
          <ListGroup>{renderList}</ListGroup>
        ) : (
          <Row className='pl-3'>'Данные отсутствуют'</Row>
        )}
      </FormGroup>
    </Aux>
  )
}

export default PledgeItemList
