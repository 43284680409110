import React from 'react';
import { Row, Col } from 'reactstrap';
import Aux from '../../../hoc/ReactAux/ReactAux';

import LawCourtList from '../../../containers/LawCourt/List';
import LawCourtDetail from '../../../containers/LawCourt/Detail';

const ScreensReportTemplateList = () => {
    return (
        <Aux>
            <h2>Суды</h2>
            <Row>
                <Col xl='7' xs='12'>
                    <LawCourtList />
                </Col>
                <Col xl='5' xs='12'>
                    <LawCourtDetail />
                </Col>
            </Row>
        </Aux>
    );
};

export default ScreensReportTemplateList;
