import React from 'react'
import { Button } from 'reactstrap'

const PlegeItemAddButton = props => {
  return (
    <Button
      color='primary'
      disabled={props.buttonDisable}
      onClick={() => {
        props.pledgeItemEditFunc()
      }}
    >
      Добавить объект залога
    </Button>
  )
}

export default PlegeItemAddButton
