import React from 'react';
import { Link } from 'react-router-dom';

const ScreensCatalogsMain = () => {
    return (
        <div>
            <h2>Справочники</h2>
            <Link to='/catalogs/law-court'>Суды</Link>
        </div>
    )
}

export default ScreensCatalogsMain;