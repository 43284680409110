import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ScreensReportTemplateList from './list';
import ScreensReportTemplateDetail from './detail';

const ScreensReportTemplate = () => {
    return (
        <Switch>
            <Route path='/settings/report-template' exact component={ScreensReportTemplateList} />
            <Route path='/settings/report-template/:id' component={ScreensReportTemplateDetail} />
        </Switch>
    )
}

export default ScreensReportTemplate;