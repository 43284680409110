import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Aux from '../../../hoc/ReactAux/ReactAux';

import RecipientExeProductions from '../../executing-productions/list';
import CourtDecisionList from '../../court-decision/list';

import * as executingProductionActions from '../../../actions/recipient-executing-production';
import * as fileActions from '../../../actions/file';

class RecipientDetailExeProduction extends Component {
    componentDidMount() {
        this.props.executingProductionActions.getAll(this.props.recipientId);
    }

    render() {
        const { executingProductions,
            importExeProd,
            fileActions,
            courtDecisions,
            executingProductionActions } = this.props;

        return (
            <Aux>
                <RecipientExeProductions list={executingProductions}
                    importExeProd={importExeProd}
                    downloadFileFunc={fileActions.downloadFile}
                    removeExeProdFunc={executingProductionActions.remove} />

                {importExeProd && importExeProd.formEnable ?
                    <Card>
                        <CardHeader>Судебное дело</CardHeader>
                        <CardBody>
                            <CourtDecisionList list={courtDecisions} />
                        </CardBody>
                    </Card> :
                    null}
            </Aux>
        )
    }
}

function mapStateToProps(state) {
    return {
        executingProductions: state.recipient.executingProductions,
        importExeProd: state.recipient.importExeProd,
        courtDecisions: state.recipient.courtDecisions,
    }
}

function mapDispathToProps(dispatch) {
    return {
        executingProductionActions: bindActionCreators(executingProductionActions, dispatch),
        fileActions: bindActionCreators(fileActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(RecipientDetailExeProduction);