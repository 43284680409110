import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ScreensCatalogsMain from './main';
import ScreensCatalogsLawCourt from './law-court/law-court';

const ScreensCatalogs = () => {
    return (
        <Switch>
            <Route path='/catalogs/' exact component={ScreensCatalogsMain} />
            <Route path='/catalogs/law-court' component={ScreensCatalogsLawCourt} />
        </Switch>
    )
}

export default ScreensCatalogs;