import React from 'react';
import { ListGroup, ListGroupItem, ListGroupItemHeading, Row, Col, Badge, Button, FormGroup } from 'reactstrap';
import { moneyToRubWithCurrency, dateToString } from '../../utils/helper';

const ImportExeProds = (props) => {
    const { list } = props;

    const renderList = list && list.map((value, index) => {
        return (
            <ListGroupItem key={index} color={index % 2 ? 'secondary' : ''}>
                <ListGroupItemHeading>
                    <Row><Col>{value.name}</Col></Row>
                    <Row className={value.sum ? 'text-light bg-warning' : ''}>
                        <Col md='8' sm='12'>
                            {value.exeProductionNumber} от&nbsp;
                                {dateToString(value.exeProductionDate)}
                        </Col>
                        <Col md='4' sm='12'>
                            {moneyToRubWithCurrency(value.sum)}
                        </Col>
                    </Row>
                    {value.ipEnd ?
                        <Badge color='success'>{dateToString(value.ipEndDate)}, {value.ipEnd}</Badge> :
                        null}
                </ListGroupItemHeading>
                <FormGroup>{value.subject}</FormGroup>
                <FormGroup>{value.details}</FormGroup>
                <FormGroup>
                    <small className='font-weight-bold'>Отдел судебных приставов</small> {value.department}<br />
                    <small className='font-weight-bold'>Судебный пристав-исполнитель&nbsp;</small> {value.bailiff}
                    <Row className='justify-content-end'>
                        <Button onClick={() => {
                            props.add(value);
                        }}>Добавить
                        </Button>
                    </Row>
                </FormGroup>
            </ListGroupItem>
        )
    })

    return (
        <ListGroup>
            {renderList}
        </ListGroup>
    )
}

export default ImportExeProds;