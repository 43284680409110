import React, { Component } from 'react'
import Aux from '../../hoc/ReactAux/ReactAux'
import CommentEditForm from './EditForm'
import * as commentActions from '../../actions/comment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class Comment extends Component {
  saveComments = values => {
    const result = {
      comment: values.comment,
      recipientId: this.props.recipient.id,
    }

    this.props.commentActions.save(result)
  }

  cancelComments = () => {}

  render() {
    if (!this.props.recipient.id) {
      return null
    }

    return (
      <Aux>
        <CommentEditForm
          onSubmit={this.saveComments}
          cancelFunc={this.cancelComments}
        />
      </Aux>
    )
  }
}

function mapStateToProps(state) {
  return {
    recipient: state.recipient.item,
  }
}

function mapDispathToProps(dispatch) {
  return {
    commentActions: bindActionCreators(commentActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispathToProps
)(Comment)
