import axios from '../../services/axios';
import { error } from 'react-notification-system-redux';
import { uploadFile } from '../file';

import {
    REPORT_TEMPLATE,
    EDIT,
    CANCEL,
    REQUEST,
    OK,
    REPORT_TEMPLATES,
    REPORT_TEMPLATE_ADD_FILE,
    SAVE
} from '../../constants/legal';

export const edit = (value) => {
    return dispatch => {
        dispatch({
            type: REPORT_TEMPLATE + EDIT,
            payload: value
        });
    }
}

export const editCancel = () => {
    return dispatch => {
        dispatch({
            type: REPORT_TEMPLATE + EDIT + CANCEL
        })
    }
}

export const get = (id) => {
    return dispatch => {
        dispatch({
            type: REPORT_TEMPLATE + REQUEST
        });

        axios.get('/report-template/' + id)
            .then(result => {
                dispatch({
                    type: REPORT_TEMPLATE + OK,
                    payload: result.data
                });
            })
            .catch(result => {
                dispatch(
                    error({
                        title: 'Вызов ReportTemplate' + id,
                        message: result.response.data.error || result.message
                    }));
            });
    }
}

export const getAll = () => {
    return dispatch => {
        dispatch({
            type: REPORT_TEMPLATES + REQUEST
        });

        axios.get('/report-template')
            .then(result => {
                dispatch({
                    type: REPORT_TEMPLATES + OK,
                    payload: result.data
                });
            })
            .catch(result => {
                dispatch(
                    error({
                        title: 'Вызов ReportTemplate',
                        message: result.response.data.error || result.message
                    }));
            });
    }
}

export const addFile = fileData => {
    return dispatch => {
        dispatch({
            type: REPORT_TEMPLATE_ADD_FILE + REQUEST
        });

        uploadFile(fileData)
            .then(result => {
                dispatch({
                    type: REPORT_TEMPLATE_ADD_FILE + OK,
                    payload: { name: fileData.name, fileID: result.data }
                });
            });
    }
} 

export const save = item => {
    return dispatch => {
        dispatch({
            type: REPORT_TEMPLATE + SAVE
        });

        axios.put('/report-template/' + item.id, { fileID: item.fileID })
            .then(result => {
                dispatch({
                    type: REPORT_TEMPLATE + SAVE + OK,
                    payload: result.data
                });
            });
    }
}