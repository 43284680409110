import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap';
import { moneyToRub } from '../../utils/helper';
import { Row, Col, Button } from 'reactstrap';
import ImportPaymentsDropdown from '../payment/import-button';
import TaxFilter from './filter';
import { ACCOUNTING_TAX_EXPORT_TO_EXCEL } from '../../constants/legal';
import Aux from '../../hoc/ReactAux/ReactAux'

export default class Tax extends Component {
    componentDidMount() {
        this.props.filterTaxFunc(this.props.filter);
    }

    submit = (values) => {
        this.props.filterTaxFunc(values);
    }

    exportFunc = () => {
        this.props.downloadFileFunc(ACCOUNTING_TAX_EXPORT_TO_EXCEL, this.props.filter);
    }

    render() {
        const { list,
            total } = this.props;

        const groupedCessions = list && list.reduce((taxByCession, taxItem) => {
            if (!taxByCession[taxItem.cessionAgreementNumber]) {
                taxByCession[taxItem.cessionAgreementNumber] = [];
            }
            taxByCession[taxItem.cessionAgreementNumber].push(taxItem);
            return taxByCession;
        }, {});

        const cessions = Object.keys(groupedCessions);

        const renderGroupedList = cessions && cessions.map((value, index) => {
            return (<Aux key={'cession' + index}>
                <tr className='table-secondary font-weight-bold text-right'>
                    <td colSpan='3'>
                        {value}
                    </td>
                    <td>
                        {
                            moneyToRub(
                                groupedCessions[value].reduce((previousValue, currentValue) => {
                                    return { paymentSum: previousValue.paymentSum + currentValue.paymentSum };
                                }, { paymentSum: 0 }).paymentSum
                            )
                        }
                    </td>
                    <td colSpan='2'></td>
                </tr>
                {groupedCessions[value].map((paymentItem, paymentIndex) => {
                    return (
                        <tr key={paymentIndex}>
                            <td><Link to={'/recipient/' + paymentItem.recipientId}>{paymentItem.name}</Link><br />Номер договора: {paymentItem.creditAgreementNumber}</td>
                            <td className='text-right'>{moneyToRub(paymentItem.loanBalanceTotalRUR)}</td>
                            <td className='text-right'>{moneyToRub(paymentItem.debtTotal)}</td>
                            <td className='text-right'>{moneyToRub(paymentItem.paymentSum)}</td>
                            <td className='text-right'>{moneyToRub(paymentItem.assignRightsPrice)}</td>
                            <td className='text-right'>{moneyToRub(paymentItem.ratio)}</td>
                        </tr>
                    )
                })}
            </Aux>)
        });

        return (
            <div>
                <Row className='justify-content-between'>
                    <Col xs='auto'>
                        <h3>Отчет</h3>
                    </Col>
                    <Col xs='auto' className='float-right'>
                        <Button className='mr-2'
                            color='secondary'
                            onClick={this.exportFunc}>Экспорт в Excel</Button>
                        <ImportPaymentsDropdown></ImportPaymentsDropdown>
                    </Col>
                </Row>
                <Row>
                    <TaxFilter onSubmit={this.submit}>
                    </TaxFilter>
                </Row>

                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>ФИО</th>
                            <th>Основной долг</th>
                            <th>Остаток долга</th>
                            <th>Приход денежных средств</th>
                            <th>Цена уступки прав</th>
                            <th>Соотношение</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderGroupedList}
                    </tbody>
                    <tfoot>
                        <tr className='text-right'>
                            <td></td>
                            <th>{moneyToRub(total.loanBalanceTotalRURTotal)}</th>
                            <th>{moneyToRub(total.debtSumTotal)}</th>
                            <th>{moneyToRub(total.paymentSumTotal)}</th>
                            <th>{moneyToRub(total.assignRightsPriceTotal)}</th>
                            <th>{moneyToRub(total.ratioTotal)}</th>
                        </tr>
                    </tfoot>
                </Table>
            </div>
        )
    }
}