import { DEFAULT_PAGE_SIZE } from '../constants/legal'
import {
  PERSONAL_INFO_SET_FILTER,
  PERSONAL_INFO_CLEAR_FILTER,
} from '../constants/personal-info'

const initialState = {
  listFilter: {
    fio: '',
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    birthday: '',
    contractNumber: '',
    stages: [],
    cession: '',
    stagePhases: [],
    passport: '',
    phone: '',
    judicalInfo: '',
    lawCourt: '',
    inn: '',
    hasCourtOrder: null,
    vin: '',
    hasActiveBankruptInfo: false,
    hasLimitExeProdRequest: false,
  },
}

export const personalInfo = (state = initialState, action = {}) => {
  switch (action.type) {
    case PERSONAL_INFO_SET_FILTER:
      return {
        ...state,
        listFilter: action.payload,
      }

    case PERSONAL_INFO_CLEAR_FILTER:
      return { ...state, listFilter: initialState.listFilter }

    default:
      return state
  }
}
