import React from 'react';
import { Card, CardTitle, CardText } from 'reactstrap';

import Aux from '../../hoc/ReactAux/ReactAux';

const LawCourtDetail = (props) => {
    const { item } = props;

    if (!item){
        return null;
    }

    return (
        <Aux>
            <Card body>
                <CardTitle>
                    {item.nameTo}
                </CardTitle>
                <CardText>
                    {item.addressIndex ? ', ' + item.addressIndex : null};
                {item.address}
                    <br />
                    <span>Телефон: {item.phones}</span>
                </CardText>
            </Card>

            {props.children}
        </Aux>
    )
}

export default LawCourtDetail;