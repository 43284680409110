import React from 'react'
import { FormGroup, Alert, Input } from 'reactstrap'

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0])

export default function FileInput({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  type,
  meta: { touched, error },
  ...props
}) {
  return (
    <FormGroup>
      <Input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type={type}
        {...props.input}
        {...props}
      />
      {touched && error && <Alert color='danger'>{error}</Alert>}
    </FormGroup>
  )
}
