import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ListGroupItem, ListGroupItemHeading, ListGroupItemText, Button } from 'reactstrap';
import { LEGAL_DOWNLOAD_FILE } from '../../constants/legal';

export default class CallNotifyListItem extends Component {
    render() {
        const { item,
            downloadFileFunc,
            color,
            recipientId,
            addSelectedCallNotifyFunc,
            cancelSelectedCallNotifyFunc,
            selectCallNotifyFunc } = this.props;

        if (!item) {
            return null;
        }

        return (
            <ListGroupItem color={color}>
                <ListGroupItemHeading>
                    <small className='text-muted pr-2 float-right'>
                        {moment.utc(item.callStart).local().format('DD.MM.YYYY HH:mm.ss')}
                    </small>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>{item.disposition}
                            <small className='text-muted pl-1'>
                                ({item.duration} сек)
                            </small>
                        </li>
                        <li className='list-inline-item'>
                            {item.callerId || item.internal}
                            <i className='fa fa-angle-double-right pl-2 pr-2 text-muted' aria-hidden='true'></i>
                            {item.destination || item.calledDid}</li>
                        <li className='list-inline-item'></li>
                    </ul>
                </ListGroupItemHeading>
                <ListGroupItemText>
                    <span>
                        {recipientId ?
                            <Button color='primary' className='float-right'
                                onClick={addSelectedCallNotifyFunc}>Создать активность</Button>
                            : null}
                        {cancelSelectedCallNotifyFunc ?
                            <Button outline color='secondary' className='float-right mr-2'
                                onClick={cancelSelectedCallNotifyFunc}>Отмена</Button>
                            : null}
                    </span>
                    {selectCallNotifyFunc && !item.recipientId ?
                        <Button color='primary' className='float-right'
                            onClick={() => {
                                selectCallNotifyFunc(item);
                            }}>Привязать к активности</Button>
                        : null}
                    {item.recordFileId ?
                        <Button color='link' className='float-right'
                            onClick={() => {
                                downloadFileFunc(LEGAL_DOWNLOAD_FILE + item.recordFileId);
                            }}>Скачать запись</Button> :
                        null}
                    {item.event}
                    <br />
                    {item.recipientId ?
                            <Link to={'/recipient/' + item.recipientId}>{item.recipientName}</Link> :
                            null}
                </ListGroupItemText>
            </ListGroupItem>
        )
    }
}