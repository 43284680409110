import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Row } from 'reactstrap'
import FormDatePicker from '../../../common/form-datepicker'
import { connect } from 'react-redux'

let RecipientDetailProfileEditForm = props => {
  const { cancelFunc, handleSubmit } = props

  return (
    <form onSubmit={handleSubmit}>
      <dl className='row'>
        <dt className='col-sm-3'>Фамилия</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='lastName'
            autoComplete='off'
          />
        </dd>
        <dt className='col-sm-3'>Имя</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='firstName'
            autoComplete='off'
          />
        </dd>
        <dt className='col-sm-3'>Отчество</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='middleName'
            autoComplete='off'
          />
        </dd>
        <dt className='col-sm-3'>Девичья фамилия</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='maidenName'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Дата рождения</dt>
        <dd className='col-sm-9'>
          <Field
            component={FormDatePicker}
            name='birthDate'
            placeholder='Дата рождения'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Место рождения</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='placeOfBirth'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Паспорт Серия</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='passportSeries'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Паспорт Номер</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='passportNumber'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Паспорт Орган, выдавший паспорт.</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='passportIssuingOfficeName'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Паспорт Код выдавшего подразделения.</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='passportIssuingOfficeCode'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Паспорт Дата выдачи.</dt>
        <dd className='col-sm-9'>
          <Field
            component={FormDatePicker}
            name='passportIssueDate'
            placeholder='Дата выдачи'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Адрес регистрации</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control col-sm-3 mb-1'
            name='registrationAddressIndex'
            autoComplete='off'
          />

          <Field
            component='input'
            type='text'
            className='form-control'
            name='registrationAddress'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Фактический адрес</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control col-sm-3 mb-1'
            name='factAddressIndex'
            autoComplete='off'
          />

          <Field
            component='input'
            type='text'
            className='form-control'
            name='factAddress'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Место работы</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='workPlace'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Телефон регистрации</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='regPhone'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Фактический телефон</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='factPhone'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Рабочий телефон</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='workPhone'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>Прочие телефоны</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            type='text'
            className='form-control'
            name='otherPhones'
            autoComplete='off'
          />
        </dd>

        <dt className='col-sm-3'>ИНН</dt>
        <dd className='col-sm-9'>
          <Field
            component='input'
            name='inn'
            placeholder='ИНН'
            autoComplete='off'
          />
        </dd>
      </dl>
      <Row>
        <Button outline color='secondary' onClick={cancelFunc}>
          Отмена
        </Button>
        <Button type='submit' color='primary' className='ml-2'>
          Сохранить
        </Button>
      </Row>
    </form>
  )
}

RecipientDetailProfileEditForm = reduxForm({
  form: 'recipientProfileEdit',
  enableReinitialize: true,
})(RecipientDetailProfileEditForm)

RecipientDetailProfileEditForm = connect(state => ({
  initialValues: state.recipient.editPersonalInfo.item,
}))(RecipientDetailProfileEditForm)

export default RecipientDetailProfileEditForm
