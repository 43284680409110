import React from 'react'
import { Link } from 'react-router-dom'

export default () => {
  return (
    <div>
      Credit bureau list
      <Link to='/credit-bureau/okb'>ОКБ</Link>
    </div>
  )
}
