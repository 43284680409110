import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { TabContent, TabPane, Row, Col } from 'reactstrap'
import Aux from '../../../hoc/ReactAux/ReactAux'

import RecipientDetailNav from './nav'
import RecipientDetailProfile from './profile'
import RecipientDetailContract from './contract'
import RecipientDetailCourtDecision from './court-decision'
import RecipientDetailExeProduction from './exe-production'
import RecipientDetailDocs from './docs'
import RecipientDetailInfo from './detail-info'
import RecipientDetailDropdown from './detail-dropdown'
import RecipientDetailPersonalInfoList from './personal-info-list'
import RecipientDetailSuccession from './succession'
import RecipientDetailTop from './top'
import PledgeItemList from '../../pledge-item/list'
import PlegeItemAddButton from '../../pledge-item/add-button'

import * as recipientActions from '../../../actions/recipient'
import * as fileActions from '../../../actions/file'
import * as paymentActions from '../../../actions/recipient-payment'
import * as activityActions from '../../../actions/recipient-activity'
import * as executingProductionActions from '../../../actions/recipient-executing-production'
import * as personalInfoActions from '../../../actions/recipient-personal-info'
import * as courtDecisionActions from '../../../actions/recipient-court-decision'

import * as pledgeItemApi from '../../../services/webapi/pledge-item-api'

import {
  PROFILE,
  CONTRACT,
  COURT_DECISION,
  EXE_PRODUCTION,
  DOCS,
  PLEDGE_ITEM,
} from '../../../constants/recipient'

class RecipientDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: PROFILE, // props.importPayment.selectedImportPayment ? CONTRACT : PROFILE
      pledgeItemList: [],
      editPledgeItem: {
        isShow: false,
        item: null,
      },
    }
  }

  componentDidMount() {
    this.props.recipientActions.getRecipient(this.props.recipientId)
    this.props.executingProductionActions.setShowOffline(false)
    this.pledgeItemUpdateList()
  }

  pledgeItemUpdateList = () => {
    pledgeItemApi
      .getListByRecipientId(this.props.recipientId)
      .then(result => {
        this.setState({ pledgeItemList: result.data })
      })
      .catch(err => {
        console.log(err)
      })
  }

  pledgeItemEditFunc = item => {
    this.setState({
      editPledgeItem: {
        isShow: true,
        cancelFunc: this.pledgeItemCancelFunc,
        saveOrUpdateFunc: this.pledgeItemSaveOrUpdateFunc,
        item: item,
      },
    })
  }

  pledgeItemCancelFunc = () => {
    this.setState({ editPledgeItem: { isShow: false } })
  }

  pledgeItemSaveOrUpdateFunc = item => {
    pledgeItemApi
      .saveOrUpdate({ ...item, recipientId: this.props.recipientId })
      .then(() => {
        this.pledgeItemCancelFunc()
        this.pledgeItemUpdateList()
      })
      .catch(err => {
        console.log('pledgeItemSaveOrUpdateFunc', err)
      })
  }

  pledgeItemRemoveFunc = id => {
    pledgeItemApi
      .remove(id)
      .then(() => {
        this.pledgeItemUpdateList()
      })
      .catch(err => {
        console.log('pledgeItemRemoveFunc', err)
      })
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })

      this.props.activityActions.cancel()
      this.props.paymentActions.cancel()
      this.props.executingProductionActions.importFromSiteCancel()
      this.props.personalInfoActions.cancelEdit()
      this.props.courtDecisionActions.cancel()
      this.props.courtDecisionActions.exeProductionRequestCancel()
    }

    this.props.executingProductionActions.setShowOffline(tab === EXE_PRODUCTION)
  }

  render() {
    const {
      recipientId,
      recipient,
      contract,
      activities,
      fileActions,
      selectedImportPayment,
      selectedCallNotify,
      isSupervisor,
    } = this.props

    const plegeItemAddButton = (
      <PlegeItemAddButton
        pledgeItemEditFunc={this.pledgeItemEditFunc}
        buttonDisable={this.state.editPledgeItem.isShow}
      />
    )

    return (
      <Aux>
        <RecipientDetailTop recipientId={recipientId} />

        <Row className='justify-content-between'>
          <Col xs='auto'>
            <h2>{recipient && recipient.name}</h2>
          </Col>

          <Col xs='auto' className='float-right'>
            {selectedImportPayment || selectedCallNotify ? null : (
              <RecipientDetailDropdown
                recipientId={recipientId}
                activeTab={this.state.activeTab}
                plegeItemAddButton={plegeItemAddButton}
              />
            )}
          </Col>
        </Row>

        <RecipientDetailNav
          creditorName={contract && contract.creditorName}
          activeTab={this.state.activeTab}
          toggle={this.toggle}
        />

        <Row className='mt-3'>
          <Col xl='7' xs='12'>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={PROFILE}>
                <RecipientDetailProfile />
              </TabPane>

              <TabPane tabId={CONTRACT}>
                <RecipientDetailContract recipientId={recipientId} />
                <RecipientDetailPersonalInfoList recipientId={recipientId} />
              </TabPane>

              <TabPane tabId={COURT_DECISION}>
                <RecipientDetailSuccession
                  activities={activities}
                  downloadFileFunc={fileActions.downloadFile}
                />
                <RecipientDetailCourtDecision
                  recipientId={recipientId}
                  lawCourtName={
                    recipient &&
                    recipient.personalInfo &&
                    recipient.personalInfo.lawCourtName
                  }
                />
              </TabPane>

              <TabPane tabId={EXE_PRODUCTION}>
                <RecipientDetailSuccession
                  activities={activities}
                  downloadFileFunc={fileActions.downloadFile}
                />
                <RecipientDetailExeProduction recipientId={recipientId} />
              </TabPane>

              <TabPane tabId={DOCS}>
                {isSupervisor ?
                  <RecipientDetailDocs recipientId={recipientId} /> :
                  null}
              </TabPane>

              <TabPane tabId={PLEDGE_ITEM}>
                <PledgeItemList
                  list={this.state.pledgeItemList}
                  editFunc={this.pledgeItemEditFunc}
                  removeFunc={this.pledgeItemRemoveFunc}
                />
              </TabPane>
            </TabContent>
          </Col>
          <Col xl='5' xs='12'>
            <RecipientDetailInfo
              recipientId={recipientId}
              tabId={this.state.activeTab}
              editPledgeItem={this.state.editPledgeItem}
            />
          </Col>
        </Row>
      </Aux>
    )
  }
}

function mapStateToProps(state) {
  return {
    recipient: state.recipient.item,
    editPayment: state.payment.editPayment,
    payments: state.payment.list,
    contract: state.recipient.contract,
    activities: state.activity.activities,
    selectedImportPayment: state.payment.import.selected,
    selectedCallNotify: state.callNotify.selected,
    isSupervisor: state.user.isSupervisor,
  }
}

function mapDispathToProps(dispatch) {
  return {
    recipientActions: bindActionCreators(recipientActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    activityActions: bindActionCreators(activityActions, dispatch),
    executingProductionActions: bindActionCreators(
      executingProductionActions,
      dispatch
    ),
    personalInfoActions: bindActionCreators(personalInfoActions, dispatch),
    courtDecisionActions: bindActionCreators(courtDecisionActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispathToProps)(RecipientDetail)
