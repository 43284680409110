import React, { Component } from 'react'
import {
  FormGroup,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
  Button,
  UncontrolledTooltip,
} from 'reactstrap'
import { dateToString } from '../../utils/helper'
import {
  CREATE_EXE_PRODUCTION_REQUEST_FROM_LAW_COURT,
  CREATE_REQUEST_CESSION_CLAIM_DUBLICATE,
  CREATE_EXE_PROD_REJECT_REASON_REQUEST,
} from '../../constants/legal'

class CourtDecisionList extends Component {
  render() {
    const {
      list,
      editFunc,
      getExeProductionRequestModel,
      removeCourtDecisionFunc,
      downloadFileFunc,
    } = this.props

    var renderList =
      list &&
      list
        .sort((a, b) => {
          return new Date(b.judicalActDate) - new Date(a.judicalActDate)
        })
        .map((value, index) => {
          return (
            <ListGroupItem
              key={index}
              action={!value.active}
              color={value.active ? 'info' : index % 2 ? 'secondary' : ''}
            >
              <ListGroupItemHeading>
                <Row>
                  <span className='ml-3'>
                    Дело №&nbsp;
                    {value.judicalActNumber}&nbsp; от&nbsp;
                    {dateToString(value.judicalActDate)}
                  </span>
                </Row>
              </ListGroupItemHeading>
              <ListGroupItemText>
                <Button
                  outline
                  color='danger'
                  className='float-right'
                  onClick={() => {
                    removeCourtDecisionFunc({
                      recipientId: value.recipientId,
                      courtDecisionId: value.id,
                    })
                  }}
                  id={'CourtDecisionRemove' + index}
                >
                  <i className='fa fa-trash-o' aria-hidden='true' />
                </Button>
                <UncontrolledTooltip
                  placement='top'
                  target={'CourtDecisionRemove' + index}
                >
                  Удалить
                </UncontrolledTooltip>
                <Button
                  color='link'
                  className='float-right'
                  onClick={() => {
                    editFunc(value)
                  }}
                  id={'CourtDecisionEdit' + index}
                >
                  <UncontrolledTooltip
                    placement='top'
                    target={'CourtDecisionEdit' + index}
                  >
                    Изменить
                  </UncontrolledTooltip>
                  <i className='fa fa-pencil-square-o' aria-hidden='true' />
                </Button>
                <span className='text-muted'>Акт&nbsp;</span>
                {value.judicalDealNumber ? (
                  <span>
                    №&nbsp;{value.judicalDealNumber} от{' '}
                    {dateToString(value.judicalDealDate)}
                  </span>
                ) : (
                  <span className='text-muted'>отсутствует</span>
                )}
                <br />
                <span className='text-muted'>
                  {value.lawCourtNameToDesc}:&nbsp;
                </span>
                {value.lawCourtNameTo}
                <br />
                <span className='text-muted'>
                  {value.lawCourtPersonNameDesc}:&nbsp;
                </span>
                {value.lawCourtPersonName}
                <br />
                <span className='text-muted'>
                  {value.lawCourtAddressDesc}:&nbsp;
                </span>
                {value.lawCourtAddressIndex
                  ? value.lawCourtAddressIndex + ', '
                  : ''}
                {value.lawCourtAddress}
                <br />
                <span className='text-muted'>
                  {value.lawCourtPhoneDesc}:&nbsp;
                </span>
                {value.lawCourtPhone}

                <br />
                <Button
                  color='link'
                  onClick={() => {
                    downloadFileFunc(
                      CREATE_EXE_PRODUCTION_REQUEST_FROM_LAW_COURT + value.id
                    )
                  }}
                >
                  Запрос исполнительного документа в ОСП
                </Button>

                <Button
                  color='link'
                  onClick={() => {
                    getExeProductionRequestModel(value.id)
                  }}
                >
                  Зявление о ВИП
                </Button>

                <Button
                  color='link'
                  onClick={() => {
                    downloadFileFunc(
                      CREATE_REQUEST_CESSION_CLAIM_DUBLICATE + value.id
                    )
                  }}
                >
                  Запрос копии определения о проц. правопреемстве
                </Button>

                <Button
                  color='link'
                  onClick={() => {
                    downloadFileFunc(
                      CREATE_EXE_PROD_REJECT_REASON_REQUEST + value.id
                    )
                  }}
                >
                  Запрос о причинах не возбуждения ИП
                </Button>
              </ListGroupItemText>
            </ListGroupItem>
          )
        })

    return (
      <div>
        <FormGroup>
          {list && list.length > 0 ? (
            <ListGroup>{renderList}</ListGroup>
          ) : (
            <Row className='pl-3'>
              {this.props.children || 'Данные отсутствуют'}
            </Row>
          )}
        </FormGroup>
      </div>
    )
  }
}

export default CourtDecisionList
