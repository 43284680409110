import React, { Component } from 'react'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import PropTypes from 'prop-types'
import Aux from '../hoc/ReactAux/ReactAux'

import MainMenu from '../components/main-menu/main-menu'
import Footer from '../components/footer/footer'

class MainLayout extends Component {
  render() {
    const { currentUser, notifications, isSupervisor, organizationName } = this.props

    return (
      <Aux>
        <MainMenu currentUser={currentUser} isSupervisor={isSupervisor} organizationName={organizationName} />
        <Notifications notifications={notifications} />
        <main className='container-fluid body-content'>
            <div>{this.props.children}</div>
        </main>
        <Footer />
        {/* <CallNotifyHub></CallNotifyHub> */}
      </Aux>
    )
  }
}

MainLayout.propTypes = {
  currentUser: PropTypes.string,
  isSupervisor: PropTypes.bool,
}

function mapStateToProp(state) {
  return {
    currentUser: state.user.currentUser,
    isSupervisor: state.user.isSupervisor,
    notifications: state.notifications,
    home: state.home,
    organizationName: state.user.organizationName,
  }
}

export default connect(
  mapStateToProp
)(MainLayout)
