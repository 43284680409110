import React, { Component } from 'react';
import { Button } from 'reactstrap';

export default class CourtDecisionAddDropdown extends Component {
    render() {
        const { courtDecisionCreate,
            buttonDisable } = this.props;

        return (
            <Button color='primary' disabled={buttonDisable} onClick={courtDecisionCreate}>
                Добавить судебное дело
            </Button>
        )
    }
}