import React from 'react';

import Aux from '../../hoc/ReactAux/ReactAux';
import { ListGroup } from 'reactstrap';

import RecipientListItem from '../recipient/list-item';


const LawCourtRecipientList = (props) => {
    return (
        <Aux>
            {props.list ?
            <ListGroup className='list-group-flush'>
                {props.list.map((elem) => {
                    return (
                        <RecipientListItem key={elem.id}
                            recipient={elem}
                            downloadFileFunc={props.downloadFileFunc} />
                    )
                })}
            </ListGroup> :
            <div>Пусто</div>}
        </Aux>
    )
}

export default LawCourtRecipientList;