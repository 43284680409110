import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

class ImportPaymentsDropdown extends Component {
    render() {
        return (
            <Button color='primary' onClick={() => {
                this.props.history.push('/file/import-payment');
            }}>
                Ипортировать платежи
            </Button>
        )
    }
}

ImportPaymentsDropdown.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(ImportPaymentsDropdown);