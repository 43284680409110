import {
  RECIPIENTS_LOAD,
  RECIPIENT_LOAD,
  REQUEST,
  OK,
  FAIL,
  RECIPIENTS_FILTER,
  CREATE,
  CANCEL,
  SAVE,
  EDIT,
  RECIPIENT_CONTRACT,
  RECIPIENT_COURT_DECISION,
  RECIPIENT_EXECUTING_PRODUCTION,
  RECIPIENT_EXECUTING_PRODUCTION_SHOW_OFFLINE_LIST,
  IMPORT,
  RECIPIENT_DOCUMENTS,
  RECIPIENT_PERSONAL_INFO,
  RECIPIENT_STAGES,
  RECIPIENT_STAGE,
  RECIPIENT_CESSION_AGREEMENT_NUMBERS,
  STAGE_PHASE,
  STAGE_PHASES,
  DELETE,
  EXE_PRODUCTION_REQUEST_MODEL,
  RECIPIENT_LOAN_BALANCE_INFO,
  DEFAULT_PAGE_SIZE
} from '../constants/legal'

const initialState = {
  loading: null,
  list: [],
  paging: {
    count: 0,
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    pages: 0,
  },
  errors: null,
  item: {
    personalInfo: {},
  },
  editPersonalInfo: {
    formEnable: false,
    item: {},
  },
  editContract: {
    formEnable: false,
    item: {},
  },
  filter: {},
  contract: {},
  courtDecisions: [],
  executingProductions: [],
  importExeProd: {
    list: [], // список ИП импортируемых с сайта
    formEnable: false, // показать списковую форму,
    offline: false,
  },
  documents: [],
  personalInfos: [], // список участников кредитного договора
  editCourtDecision: {
    formEnable: false, // показать форму редактирования
    item: {}, //объект для редактирования
  },
  stages: [],
  cessionAgreementNumbers: [],
  stagePhases: [],
  exeProductionRequestModel: null,
}

export const recipient = (state = initialState, action = {}) => {
  switch (action.type) {
    case RECIPIENTS_LOAD + REQUEST:
      return { ...state, loading: true }

    case RECIPIENTS_LOAD + OK: {
      const { count, pageIndex, pageSize, pages } = action.payload

      return {
        ...state,
        loading: false,
        list: action.payload.recipients,
        filter: action.filter,
        paging: {
          count,
          pageIndex,
          pageSize,
          pages,
        },
        exeProductionRequestModel: null,
      }
    }

    case RECIPIENTS_LOAD + FAIL:
      return {
        ...state,
        loading: false,
        list: null,
        errors: action.errors,
      }

    case RECIPIENTS_FILTER:
      return { ...state }

    case RECIPIENT_LOAD + REQUEST:
      var item = state.list.filter(function (element) {
        return (
          element && action.recipientId && element.id === action.recipientId
        )
      })[0]

      return {
        ...state,
        loading: true,
        item: {
          ...item,
          personalInfo: {},
        },
        importExeProd: {
          list: [],
          formEnable: false,
          offline: false,
        }
      }

    case RECIPIENT_LOAD + OK:
      return {
        ...state,
        item: action.recipient,
      }

    case RECIPIENT_CONTRACT + OK:
      return { ...state, contract: action.payload }

    case RECIPIENT_COURT_DECISION + OK:
      return { ...state, courtDecisions: action.payload }

    case RECIPIENT_EXECUTING_PRODUCTION + OK:
      return { ...state, executingProductions: action.payload }

    case RECIPIENT_EXECUTING_PRODUCTION + IMPORT + OK:
      return {
        ...state,
        importExeProd: {
          list: action.payload,
          formEnable: !action.isOffline,
          offline:
            state.importExeProd.offline && action.isOffline ? true : false,
        },
      }

    case RECIPIENT_EXECUTING_PRODUCTION + IMPORT + CANCEL:
      return {
        ...state,
        importExeProd: {
          ...state.importExeProd,
          formEnable: false,
          offline: true,
        },
      }

    case RECIPIENT_EXECUTING_PRODUCTION_SHOW_OFFLINE_LIST:
      return {
        ...state,
        importExeProd: {
          ...state.importExeProd,
          offline: action.payload,
          formEnable: false,
        },
      }

    case RECIPIENT_EXECUTING_PRODUCTION + SAVE + OK:
      return {
        ...state,
        executingProductions: state.executingProductions.concat(action.payload),
        importExeProd: {
          ...state.importExeProd,
          formEnable: false,
          offline: true,
        },
      }

    case RECIPIENT_DOCUMENTS + OK:
      return {
        ...state,
        documents: action.payload,
      }

    case RECIPIENT_PERSONAL_INFO + REQUEST:
      return {
        ...state,
        personalInfos: [],
      }

    case RECIPIENT_PERSONAL_INFO + OK:
      return {
        ...state,
        personalInfos: action.payload,
      }

    case RECIPIENT_COURT_DECISION + CREATE + CANCEL:
      return {
        ...state,
        editCourtDecision: {
          formEnable: false,
          item: {},
        },
      }

    case RECIPIENT_COURT_DECISION + CREATE:
      return {
        ...state,
        editCourtDecision: {
          formEnable: true,
          item: {},
        },
      }

    case RECIPIENT_COURT_DECISION + CREATE + OK:
      return {
        ...state,
        editCourtDecision: {
          formEnable: false,
          item: {},
        },
        courtDecisions: state.courtDecisions.concat(action.payload),
      }

    case RECIPIENT_COURT_DECISION + EDIT:
      return {
        ...state,
        editCourtDecision: {
          formEnable: true,
          item: action.payload,
        },
      }

    case RECIPIENT_COURT_DECISION + EDIT + OK: {
      let list =
        state.courtDecisions &&
        state.courtDecisions.map(value => {
          if (value && value.id === action.payload.id) {
            return action.payload
          }

          value.active = false

          return value
        })

      return {
        ...state,
        editCourtDecision: {
          formEnable: false,
          item: {},
        },
        courtDecisions: list,
      }
    }

    case RECIPIENT_COURT_DECISION + DELETE + OK: {
      let list = state.courtDecisions.filter(
        value => value.id !== action.payload
      )

      return {
        ...state,
        courtDecisions: list,
      }
    }

    case RECIPIENT_PERSONAL_INFO + EDIT + OK:
      return {
        ...state,
        editPersonalInfo: {
          formEnable: false,
          item: {},
        },
        item: {
          ...state.item,
          personalInfo: action.payload,
        },
      }

    case RECIPIENT_PERSONAL_INFO + EDIT + CANCEL:
      return {
        ...state,
        editPersonalInfo: {
          formEnable: false,
          item: {},
        },
      }

    case RECIPIENT_PERSONAL_INFO + EDIT:
      return {
        ...state,
        editPersonalInfo: {
          item: state.item.personalInfo,
          formEnable: true,
        },
      }

    case RECIPIENT_LOAN_BALANCE_INFO + EDIT + OK:
      return {
        ...state,
        editContract: {
          formEnable: false,
          item: {},
        },
        contract: action.payload,
      }

    case RECIPIENT_LOAN_BALANCE_INFO + EDIT + CANCEL:
      return {
        ...state,
        editContract: {
          formEnable: false,
          item: {},
        },
      }

    case RECIPIENT_LOAN_BALANCE_INFO + EDIT:
      return {
        ...state,
        editContract: {
          item: state.contract,
          formEnable: true,
        },
      }

    case RECIPIENT_STAGES + OK:
      return {
        ...state,
        stages: action.payload,
      }

    case RECIPIENT_STAGE + SAVE + OK:
    case STAGE_PHASE + SAVE + OK:
      return {
        ...state,
        contract: action.payload,
      }

    case RECIPIENT_CESSION_AGREEMENT_NUMBERS + OK:
      return {
        ...state,
        cessionAgreementNumbers: action.payload,
      }

    case STAGE_PHASES + OK:
      return {
        ...state,
        stagePhases: action.payload,
      }

    case EXE_PRODUCTION_REQUEST_MODEL + OK:
      return {
        ...state,
        exeProductionRequestModel: action.payload,
      }

    case EXE_PRODUCTION_REQUEST_MODEL + CANCEL:
      return {
        ...state,
        exeProductionRequestModel: null,
      }

    default:
      return state
  }
}
