import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, FormFeedback, Input, FormGroup, Row, Col } from 'reactstrap';
import FileInput from './file-input';

const renderSelectField = ({ input, label, type, meta: { touched, error }, children }) => (
    <div>
        <label>{label}</label>
        <FormGroup>
            <Input {...input}
                type={type}
                valid={!error}>
                {children}
            </Input>
            {touched && error && <FormFeedback>{error}</FormFeedback>}
        </FormGroup>
    </div>
)

const ImportFileForm = props => {
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <h3>Импорт данных из банка</h3>
                <Row>
                    <Col>
                        <Field
                            name='importFile'
                            component={FileInput}
                            type='file' />
                    </Col>
                </Row>
                <Row>
                    <Col xs='4'>
                    <Field name='bankCode'
                        component={renderSelectField}
                        type='select'>
                        <option />
                        <option value='sber'>Сбербанк</option>
                        <option value='otp'>ОТП</option>
                        <option value='uralsib'>Уралсиб</option>
                        <option value='clever'>КА Клевер</option>
                        <option value='vtb'>ВТБ</option>
                    </Field>
                    </Col>
                </Row>
            </div>
            <div>
                <Button type='submit'>Submit</Button>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'ImportFile'
})(ImportFileForm)