import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Aux from '../../hoc/ReactAux/ReactAux';

import LawCourtDetailInfo from '../../components/LawCourt/DetailInfo';
import LawCourtRecipientList from '../../components/LawCourt/RecipientList';

import * as lawCourtActions from '../../actions/law-court';

class LawCourtDetail extends Component {
    render() {
        const { selectedItem,
            selectedItemRecipients } = this.props;

        return (
            <Aux>
                <LawCourtDetailInfo item={selectedItem}>
                    <LawCourtRecipientList list={selectedItemRecipients} />
                </LawCourtDetailInfo>
            </Aux>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedItem: state.lawCourt.selectedItem,
        selectedItemRecipients: state.lawCourt.selectedItemRecipients,
    }
}

function mapDispathToProps(dispatch) {
    return {
        lawCourtActions: bindActionCreators(lawCourtActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(LawCourtDetail);