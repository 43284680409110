import {
    REPORT_TEMPLATE,
    EDIT,
    CANCEL,
    OK,
    REPORT_TEMPLATES,
    REPORT_TEMPLATE_ADD_FILE,
    SAVE
} from '../constants/legal';

const initialState = {
    list: [    ],
    item: null,
    editReportTemplate: {
        formEnable: false,
        item: {}
    }
}

const reportTemplate = (state = initialState, action = {}) => {
    switch (action.type) {
        case REPORT_TEMPLATE + EDIT:
            return {
                ...state,
                editReportTemplate: {
                    formEnable: true,
                    item: action.payload
                }
            }

        case REPORT_TEMPLATE + EDIT + CANCEL:
            return {
                ...state,
                editReportTemplate: {
                    formEnable: false,
                    item: {}
                }
            }

        case REPORT_TEMPLATE + OK:
            return {
                ...state,
                item: action.payload
            }

        case REPORT_TEMPLATES + OK:
            return {
                ...state,
                list: action.payload,
                editReportTemplate: {
                    formEnable: false,
                    item: {}
                }
            }

        case REPORT_TEMPLATE_ADD_FILE + OK:
            return {
                ...state,
                editReportTemplate: {
                    ...state.editReportTemplate,
                    item: {
                        ...state.editReportTemplate.item,
                        fileID: action.payload.fileID,
                        fileName: action.payload.name,
                    }
                }
            }

        case REPORT_TEMPLATE + SAVE + OK:
            return {
                ...state,
                item: action.payload,
                editReportTemplate: {
                    formEnable: false,
                    item: {}
                }
            }

        default:
            return state;
    }
}


export default reportTemplate;