import React, { Component } from 'react';
import { Button } from 'reactstrap';

export default class PaymentAddDropdown extends Component {
    render() {
        const { paymentCreate, 
            buttonDisable } = this.props;

        return (
            <Button color='primary' disabled={buttonDisable} onClick={paymentCreate}>
                Добавить платёж
            </Button>
        )
    }
}