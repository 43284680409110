import React from 'react'
import * as accountApi from '../../services/webapi/account-api'
import { Formik, Field, Form } from 'formik'
import Select from 'react-select'
import logo from '../../assets/owl.png'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { GetUserInfo } from '../../utils/helper'
import { LOGIN, OK, FAIL } from '../../constants/legal'
import { info, error } from 'react-notification-system-redux'
import * as Yup from 'yup'

const AuthPageSchema = Yup.object().shape({
  username: Yup.string().required('Необходимо ввести логин'),
  password: Yup.string().required('Необходимо ввести пароль'),
})

const AuthPage = props => {
  const organizations = [
    { value: 'a3f5b477-f3f0-4582-af95-2740622df05b', label: 'Легал Плюс' }
  ]

  let userInfo = { username: '', password: '', organization: organizations[0] }

  const onSubmit = (values, actions) => {
    if (!values.organization && !values.organizationId) {
      return;
    }

    props.dispatch(
      info({
        title: 'Вход в систему',
        message: 'Запрос отправлен',
      })
    )

    if (values.organization) {
      values.organizationId = values.organization.value;
      delete values.organization;
    }

    accountApi
      .login(values)
      .then(result => {
        props.dispatch({
          type: LOGIN + OK,
          userInfo: GetUserInfo(result.access_token),
        })
        props.dispatch(
          info({
            title: 'Вход в систему',
            message: 'Успешно',
          })
        )
        props.history.push('/')
      })
      .catch(err => {
        let errorMessage = err.message

        if (err.response && err.response.data) {
          err.message = err.response.data
        }

        props.dispatch(
          error({
            title: 'Вход в систему',
            message: err.message,
          })
        )

        props.dispatch({
          type: LOGIN + FAIL,
          errorMessage: errorMessage,
        })
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  };

  return (
    <div className='d-flex justify-content-center'>
      <div style={{ maxWidth: '420px', width: '100%' }}>
        <section>
          <Formik
            initialValues={userInfo}
            onSubmit={onSubmit}
            validationSchema={AuthPageSchema}
            render={({ values, setFieldValue, errors, touched }) => (
              <Form className='text-center'>
                <img
                  src={logo}
                  width='200'
                  height='120'
                  style={{ margin: '1em 0' }}
                  alt='Legal Plus'
                />
                <div className='form-group'>
                  <Field
                    name='username'
                    type='text'
                    className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')}
                    placeholder='Логин'

                  ></Field>
                  {/* {errors.username && touched.username ? <div className='text-danger'><small>{errors.username}</small></div> : null} */}
                </div>
                <div className='form-group'>
                  <Field
                    name='password'
                    type='password'
                    className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                    placeholder='Пароль'
                  ></Field>
                  {/* {errors.password && touched.password ? <div className='text-danger'><small>{errors.password}</small></div> : null} */}
                </div>
                <div className='form-group'>
                  <Select
                    onChange={newValue => {
                      setFieldValue('organization', newValue)
                    }}
                    defaultOptions
                    options={organizations}
                    name='organization'
                    value={values.organization}
                  />
                </div>
                <button
                  type='submit'
                  className='btn btn-lg btn-primary btn-block'
                >
                  Войти
                </button>
              </Form>
            )}
          />
        </section>
      </div>
    </div>
  )
}

export default connect()(withRouter(AuthPage))
