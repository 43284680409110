import React from 'react'
import { Link } from 'react-router-dom'
import * as fileActions from '../../actions/file'

import { dateToString } from '../../utils/helper'
import { LEGAL_DOWNLOAD_FILE } from '../../constants/legal'

const DocumentListIncomingItem = (props) => {
  const {
    item,
    editItemFunc } = props;

  return (
    <tr onClick={() => { editItemFunc(item.id) }}>
      <td>{item.regNumber}</td>
      <td>{dateToString(item.regDate)}</td>
      <td>{item.name}</td>
      <td>{item.destination}</td>{/* Кому */}
      <td>{item.commentary}</td>
      <td>
        <a href='#' className='link' onClick={e => {
          e.stopPropagation();
          fileActions.download(LEGAL_DOWNLOAD_FILE + item.fileId);
        }}>{item.fileName}</a></td>
        <td className='list-outgoing__deal'><Link to={'/recipient/' + item.dealId}>{item.lastName} {item.firstName} {item.middleName}</Link></td>
    </tr>
  )
}

export default DocumentListIncomingItem;