import instance from '../services/axios'
import {
  OK,
  FAIL,
  REQUEST,
  RECIPIENT_CONTRACT,
  RECIPIENT_STAGES,
  RECIPIENT_STAGE,
  SAVE,
  RECIPIENT_CESSION_AGREEMENT_NUMBERS,
  RECIPIENT_LOAN_BALANCE_INFO,
  CANCEL,
  EDIT,
} from '../constants/legal'
import { error } from 'react-notification-system-redux'
import * as contractApi from '../services/webapi/contract-api'
import * as stageApi from '../services/webapi/stage-api'

export function get(recipientId) {
  return dispatch => {
    dispatch({
      type: RECIPIENT_CONTRACT + REQUEST,
    })

    instance
      .get('/recipient/' + recipientId + '/contract')
      .then(result => {
        dispatch({
          type: RECIPIENT_CONTRACT + OK,
          payload: result.data,
        })
      })
      .catch(result => {
        dispatch({
          type: RECIPIENT_CONTRACT + FAIL,
          errors: result.message,
        })
        dispatch(
          error({
            title: 'Ошибка получения данных контракта',
            message: result.response.data.error || result.message
          })
        )
      })
  }
}

export const updateStage = (recipientId, stageId) => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_STAGE + SAVE + REQUEST,
    })

    instance
      .put('/recipient/' + recipientId + '/contract/stage/' + stageId)
      .then(result => {
        dispatch({
          type: RECIPIENT_STAGE + SAVE + OK,
          payload: result.data,
        })
      })
      .catch(result => {
        dispatch({
          type: RECIPIENT_STAGE + SAVE + FAIL,
          errors: result.message,
        })
        dispatch(
          error({
            title: 'Ошибка обновления стадии контракта',
            message: result.response.data.error || result.message
          })
        )
      })
  }
}

export const getStages = () => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_STAGES + REQUEST,
    })

    stageApi
      .getAll()
      .then(result => {
        dispatch({
          type: RECIPIENT_STAGES + OK,
          payload: result.data,
        })
      })
      .catch(result => {
        dispatch({
          type: RECIPIENT_STAGES + FAIL,
          errors: result.message,
        })
        dispatch(
          error({
            title: 'Ошибка получения списка стадий',
            message: result.response.data.error || result.message
          })
        )
      })
  }
}

export const getCessionAgreementNumbers = () => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_CESSION_AGREEMENT_NUMBERS + REQUEST,
    })

    contractApi
      .getCessionNumbers()
      .then(result => {
        dispatch({
          type: RECIPIENT_CESSION_AGREEMENT_NUMBERS + OK,
          payload: result.data,
        })
      })
      .catch(result => {
        dispatch({
          type: RECIPIENT_CESSION_AGREEMENT_NUMBERS + FAIL,
          errors: result.message,
        })
        dispatch(
          error({
            title: 'Ошибка получения информации о договорах цессии',
            message: result.response.data.error || result.message
          })
        )
      })
  }
}

export const updateLoanBalanceInfo = values => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_LOAN_BALANCE_INFO + EDIT + REQUEST,
    })

    instance
      .post('/loan-balance-info', values)
      .then(result => {
        dispatch({
          type: RECIPIENT_LOAN_BALANCE_INFO + EDIT + OK,
          payload: result.data,
        })
      })
      .catch(result => {
        dispatch({
          type: RECIPIENT_LOAN_BALANCE_INFO + EDIT + FAIL,
          errors: result.message,
        })
        dispatch(
          error({
            title: 'Ошибка обновления данных о задолженности заёмщика',
            message: result.response.data.error || result.message
          })
        )
      })
  }
}

export const cancelEditLoanBalanceInfo = () => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_LOAN_BALANCE_INFO + EDIT + CANCEL,
    })
  }
}

export const editLoanBalanceInfo = () => {
  return dispatch => {
    dispatch({
      type: RECIPIENT_LOAN_BALANCE_INFO + EDIT,
    })
  }
}
