import React from 'react'
import { Field, reduxForm } from 'redux-form'
import FormDatePicker from '../common/form-datepicker'
import { Collapse, Button } from 'reactstrap'
import { connect } from 'react-redux'
import FormSelect from '../common/form-select'
import FormAutocomplete from '../common/form-autocomplete'
import './filter.css'

let ResipientsFilter = props => {
  const {
    handleSubmit,
    toggle,
    collapse,
    reset,
    recipientStages,
    cessionAgreementNumbers,
    lawCourts,
    lawCourtSearchAutoFunc,
  } = props

  return (
    <form onSubmit={handleSubmit} className='col-12'>
      <div id='accordion' role='tablist' aria-multiselectable='true'>
        <div className='card mb-2'>
          <div
            className='card-header bg-primary text-white'
            role='tab'
            id='headingOne'
            onClick={toggle}
          >
            <h5 className='mb-0'>
              <span
                data-toggle='collapse'
                data-parent='#accordion'
                href='#collapseOne'
                aria-expanded='true'
                aria-controls='collapseOne'
                id='heading-one-link'
              >
                Поиск
              </span>
            </h5>
          </div>

          <Collapse
            id='accordion'
            role='tablist'
            aria-multiselectable='true'
            isOpen={collapse}
          >
            <div className='card-block p-2'>
              <div className='form-group row mb-0'>
                <label
                  htmlFor='name'
                  className='col-lg-1 col-sm-4 col-form-label text-lg-right'
                >
                  ФИО
                </label>
                <div className='col-lg-3 col-sm-8'>
                  <Field
                    name='name'
                    component='input'
                    type='text'
                    className='form-control form-control-sm'
                    placeholder='Фамилия Имя Отчество'
                    autoComplete='off'
                  />
                </div>

                <label
                  htmlFor='passport'
                  className='col-lg-1 col-sm-4 col-form-label text-lg-right text-nowrap'
                >
                  Паспорт
                </label>
                <div className='col-lg-3 col-sm-8'>
                  <Field
                    name='passport'
                    component='input'
                    type='text'
                    className='form-control form-control-sm'
                    placeholder='Паспортные данные'
                    autoComplete='off'
                  />
                </div>

                <label
                  htmlFor='search-date-start'
                  className='col-12 col-sm-4 col-lg-2 col-form-label text-lg-right'
                >
                  Дата рождения
                </label>
                <div className='col-12 col-sm-8 col-lg-2'>
                  <Field
                    component={FormDatePicker}
                    name='birthDate'
                    placeholder='Дата рождения'
                    autoComplete='off'
                    className='form-control-sm'
                  />
                </div>
              </div>

              <div className='form-group row mb-0'>
                <label
                  htmlFor='contract'
                  className='col-lg-1 col-12 col-sm-4 mt-sm-3 mt-lg-0 col-form-label text-lg-right text-nowrap'
                >
                  Договор
                </label>
                <div className='col-12 col-sm-8 col-lg-3 mt-sm-3 mt-lg-0'>
                  <Field
                    name='contract'
                    component='input'
                    type='text'
                    className='form-control form-control-sm'
                    placeholder='Номер договора'
                    autoComplete='off'
                  />
                </div>

                <label
                  htmlFor='search-contract'
                  className='col-12 col-sm-4 col-lg-1 mt-sm-3 mt-lg-0 col-form-label text-lg-right'
                >
                  Номер
                </label>
                <div className='col-12 col-sm-8 col-lg-3 mt-sm-3 mt-lg-0'>
                  <Field
                    component={FormSelect}
                    name='cessionAgreementNumber'
                    type='text'
                    className='form-control form-control-sm'
                    placeholder='Номер цессии'
                    autoComplete='on'
                    //multi={true}
                    options={cessionAgreementNumbers}
                  />
                </div>

                <label
                  htmlFor='search-date-start'
                  className='col-12 col-sm-4 col-lg-2 col-form-label  text-lg-right'
                >
                  Дата цессии
                </label>
                <div className='col-12 col-sm-8 col-lg-2 date'>
                  <Field
                    component={FormDatePicker}
                    name='cessionDate'
                    placeholder='Дата цессии'
                    autoComplete='off'
                    className='form-control-sm'
                  />
                </div>
              </div>

              <div className='form-group row mb-0'>
                <label
                  htmlFor='phoneNumber'
                  className='col-12 col-sm-4 col-lg-1 col-form-label text-lg-right text-nowrap'
                >
                  Телефон
                </label>
                <div className='col-12 col-sm-8 col-lg-3 date'>
                  <Field
                    name='phoneNumber'
                    component='input'
                    type='text'
                    className='form-control form-control-sm'
                    placeholder='Номер телефона'
                    autoComplete='off'
                  />
                </div>

                <label
                  htmlFor='search-contract'
                  className='col-12 col-sm-4 col-lg-1 mt-sm-3 mt-lg-0 col-form-label text-lg-right'
                >
                  Cуд. акт
                </label>
                <div className='col-12 col-sm-8 col-lg-3 mt-sm-3 mt-lg-0'>
                  <Field
                    name='judicalActNumber'
                    component='input'
                    type='text'
                    className='form-control form-control-sm'
                    placeholder='Номер судебного акта'
                    autoComplete='off'
                  />
                </div>

                <label
                  htmlFor='search-date-start'
                  className='col-12 col-sm-4 col-lg-2 col-form-label text-lg-right'
                >
                  Дата суд. акта
                </label>
                <div className='col-12 col-sm-8 col-lg-2 date'>
                  <Field
                    component={FormDatePicker}
                    name='judicalActDate'
                    placeholder='Дата судебного акта'
                    autoComplete='off'
                    className='form-control-sm'
                  />
                </div>
              </div>

              <div className='form-group row mb-0'>
                <label
                  htmlFor=''
                  className='col-12 col-sm-4 col-lg-1 col-form-label text-lg-right'
                >
                  Суд
                </label>
                <div className='col-12 col-sm-8 col-lg-3 form-autocomplete'>
                  <Field
                    name='lawCourt'
                    component={FormAutocomplete}
                    className='form-control form-control-sm'
                    placeholder='Суд'
                    lawCourts={lawCourts}
                    lawCourtSearchAutoFunc={lawCourtSearchAutoFunc}
                  />
                </div>

                <label
                  htmlFor='recipientStage'
                  className='col-12 col-sm-4 col-lg-1 mt-sm-3 mt-lg-0 col-form-label text-lg-right'
                >
                  Стадия
                </label>
                <div className='col-12 col-sm-8 col-lg-3 mt-sm-3 mt-lg-0'>
                  <Field
                    name='recipientStage'
                    component='select'
                    className='form-control form-control-sm'
                  >
                    <option />
                    {recipientStages &&
                      recipientStages.map(value => {
                        return (
                          <option value={value.id} key={value.id}>
                            {value.name}
                          </option>
                        )
                      })}
                  </Field>
                </div>

                <label
                  htmlFor='search-date-start'
                  className='col-12 col-sm-4 col-lg-2 col-form-label text-lg-right'
                >
                  Правоприемство
                </label>
                <div className='col-12 col-sm-8 col-lg-2'>
                  <Field
                    name='HasSuccession'
                    component='select'
                    className='form-control form-control-sm'
                  >
                    <option />
                    <option value='True'>Получено</option>
                    <option value='False'>Отсутствует</option>
                  </Field>
                </div>
              </div>

              <div className='form-group row mb-0'>
                <label
                  htmlFor=''
                  className='col-12 col-sm-4 col-lg-1 col-form-label text-lg-right'
                >
                  ОСП
                </label>
                <div className='col-12 col-sm-8 col-lg-3 form-autocomplete'>
                  <Field
                    name='ExeProdDepartment'
                    component='input'
                    type='text'
                    className='form-control form-control-sm'
                    placeholder='ОСП'
                    autoComplete='off'
                  />
                </div>

                <label
                  htmlFor='tableView'
                  className='col-12 col-sm-4 col-lg-1 mt-sm-3 mt-lg-0 col-form-label text-lg-right'
                >
                  оконч. ИП
                </label>
                <div className='col-12 col-sm-8 col-lg-3 mt-sm-3 mt-lg-0 custom-control-inline mr-0'>
                  <Field
                    component={FormDatePicker}
                    name='endIpStart'
                    placeholder='C'
                    autoComplete='off'
                    className='form-control-sm'
                  />
                  <Field
                    component={FormDatePicker}
                    name='endIpEnd'
                    placeholder='ПО'
                    autoComplete='off'
                    className='form-control-sm'
                  />
                </div>

                <div className='custom-control custom-checkbox col-12 col-sm-4 col-lg-1 mt-sm-3 mt-lg-0 mt-2'>
                  <Field
                    id='tableView1'
                    name='tableView'
                    component='input'
                    type='checkbox'
                    className='mt-2 mr-2 custom-control-input'
                  />
                  <label
                    className='custom-control-label'
                    htmlFor='tableView1'
                  >
                    Таблица
                    </label>
                </div>
                <div className='d-flex justify-content-end col-lg-3'>
                  <div>
                    <Button outline color='secondary mr-2' onClick={reset}>
                      Очистить
                    </Button>
                    <input
                      type='submit'
                      value='Найти'
                      className='btn btn-primary'
                      id='filter-submit'
                    />
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </form>
  )
}

ResipientsFilter = reduxForm({
  form: 'resipientsFilter',
})(ResipientsFilter)

ResipientsFilter = connect(
  state => ({
    initialValues: state.recipient.filter, // pull initial values
  })
  //{ load: loadAccount } // bind account loading action creator
)(ResipientsFilter)

export default ResipientsFilter
