import React, { Component } from 'react'
import { LEGAL_DOWNLOAD_FILE } from '../../constants/legal'
import { Button } from 'reactstrap'

class FileList extends Component {
  render() {
    const { files, downloadFileFunc } = this.props

    var renderFiles =
      files &&
      files.map((value, index) => {
        return (
          <span key={index}>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(LEGAL_DOWNLOAD_FILE + value.fileID)
              }}
            >
              {value.fileName}&nbsp;
              {(value.regNumber || value.regDate) && (
                <span>
                  (№ {value.regNumber} от{' '}
                  {value.regDate}
                  )
                </span>
              )}
            </Button>
          </span>
        )
      })

    return (
      <span>
        <br />
        {renderFiles}
      </span>
    )
  }
}

export default FileList
