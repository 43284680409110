import React from 'react'
import PhoneCall from '../../../common/phone-call'
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip} from 'reactstrap'

import {dateToString} from '../../../../utils/helper'

import {LEGAL_APP_V2} from '../../../../constants/legal';

const RecipientDetailProfileView = props => {
    const {
        personalInfo,
        getCallBack,
        editFunc,
        stages,
        updateStageFunc,
        stagePhases,
        updateStagePhaseFunc,
        contract,
    } = props;

    if (!personalInfo) {
        return <div></div>
    }

    const stageElement =
        stages &&
        stages.map(elem => {
            if (elem.name === contract.recipientStage) {
                return null
            }

            return (
                <DropdownItem
                    key={elem.id}
                    onClick={() => {
                        updateStageFunc(elem.id)
                    }}
                >
                    {elem.name}
                </DropdownItem>
            )
        })

    const renderStagePhases =
        stagePhases &&
        stagePhases.map(elem => {
            if (
                elem.name === contract.stagePhase ||
                elem.stageId !== contract.recipientStageId
            ) {
                return null
            }

            return (
                <DropdownItem
                    key={elem.id}
                    onClick={() => {
                        updateStagePhaseFunc(elem.id)
                    }}
                >
                    {elem.name}
                </DropdownItem>
            )
        })

    return (
        <div>
            <Button
                color='link'
                className='float-right'
                onClick={editFunc}
                id='ProfileEdit'
            >
                <i className='fa fa-pencil-square-o' aria-hidden='true'></i>
            </Button>
            <UncontrolledTooltip placement='top' target='ProfileEdit'>
                Редактировать профиль
            </UncontrolledTooltip>
            <dl className='row'>
                <dt className='col-sm-3'>{contract.recipientStageDesc}</dt>
                <dd className='col-sm-9'>
                    <UncontrolledDropdown>
                        <DropdownToggle caret size='sm' color='primary'>
                            {contract.recipientStage}
                        </DropdownToggle>
                        <DropdownMenu>{stageElement}</DropdownMenu>
                    </UncontrolledDropdown>
                </dd>

                <dt className='col-sm-3'>{contract.stagePhaseDesc}</dt>
                <dd className='col-sm-9'>
                    <UncontrolledDropdown>
                        <DropdownToggle caret size='sm' color='secondary'>
                            {contract.stagePhase || 'Отсутствует'}
                        </DropdownToggle>
                        <DropdownMenu>{renderStagePhases}</DropdownMenu>
                    </UncontrolledDropdown>
                </dd>

                <dt className='col-sm-3'>Дата рождения</dt>
                <dd className='col-sm-9'>{dateToString(personalInfo.birthDate)}</dd>

                <dt className='col-sm-3'>Место рождения</dt>
                <dd className='col-sm-9'>{personalInfo.placeOfBirth}</dd>

                <dt className='col-sm-3'>Паспортные данные</dt>
                <dd className='col-sm-9'>{personalInfo.passportInfo}</dd>

                <dt className='col-sm-3'>Адрес регистрации</dt>
                <dd className='col-sm-9'>{personalInfo.registrationAddressClean || personalInfo.fullRegistrationAddress}</dd>

                <dt className='col-sm-3'>Фактический адрес</dt>
                <dd className='col-sm-9'>{personalInfo.factAddressClean || personalInfo.fullFactAddress}</dd>

                <dt className='col-sm-3'>Место работы</dt>
                <dd className='col-sm-9'>{personalInfo.workPlace}</dd>

                <dt className='col-sm-3'>Телефон регистрации</dt>
                <dd className='col-sm-9'>
                    <PhoneCall
                        phones={personalInfo.regPhone}
                        getCallBack={getCallBack}
                    ></PhoneCall>
                </dd>

                <dt className='col-sm-3'>Фактический телефон</dt>
                <dd className='col-sm-9'>
                    <PhoneCall
                        phones={personalInfo.factPhone}
                        getCallBack={getCallBack}
                    ></PhoneCall>
                </dd>

                <dt className='col-sm-3'>Рабочий телефон</dt>
                <dd className='col-sm-9'>
                    <PhoneCall
                        phones={personalInfo.workPhone}
                        getCallBack={getCallBack}
                    ></PhoneCall>
                </dd>

                <dt className='col-sm-3'>Прочие телефоны</dt>
                <dd className='col-sm-9'>
                    <PhoneCall
                        phones={personalInfo.otherPhones}
                        getCallBack={getCallBack}
                    ></PhoneCall>
                </dd>

                <dt className='col-sm-3'>ИНН</dt>
                <dd className='col-sm-9'>
                    {personalInfo.inn}
                </dd>

                <dt className='col-sm-3'>Дата проверки банкротств</dt>
                <dd className='col-sm-9'>
                    {personalInfo && personalInfo.lastCheckBankrotsDate ? (
                        <a href={LEGAL_APP_V2 + '/integrations/bankrot/histories/?pid=' + personalInfo.personId}>{personalInfo.lastCheckBankrotsDate} (записей {personalInfo.bankrotPhysicalsCount})</a>
                    ) : 'отсутствует'}
                </dd>

                <dt className='col-sm-3'>Дата проверки ФССП</dt>
                <dd className='col-sm-9'>
                    {personalInfo && personalInfo.lastCheckExeProdsDate ? (
                        <a href={LEGAL_APP_V2 + '/integrations/fssp/histories/?pid=' + personalInfo.personId}>{personalInfo.lastCheckExeProdsDate}</a>
                    ) : 'отсутствует'}
                </dd>
            </dl>
        </div>
    )
}

export default RecipientDetailProfileView
