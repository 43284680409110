import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';

export default class FormAutocomplete extends Component {
    changeFunc = (event, value) => {
        this.props.lawCourtSearchAutoFunc(value);
        this.props.input.onChange(event, value);
    }

    selectFunc = (value, item) => {
        this.props.lawCourtSearchAutoFunc(value);
        this.props.input.onChange(null, value);
    }

    render() {
        let { input: { value } } = this.props;
        const { placeholder,
            className,
            lawCourts } = this.props;

        return (
            <Autocomplete
                getItemValue={(item) => item.label}
                items={lawCourts}
                renderItem={(item, isHighlighted) =>
                    <div style={{ background: isHighlighted ? 'lightgray' : 'white' }} key={item.id}>
                        {item.label}
                    </div>
                }
                value={value}
                onChange={this.changeFunc}
                onSelect={this.changeFunc}
                inputProps={{ className: className, placeholder: placeholder }}
                wrapperStyle={{ className: 'input-group' }}
            />
        )
    }
}