import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CallNotifyList from '../components/call-notify/list';
import * as callNotifyActions from '../actions/call-notify';
import * as fileActions from '../actions/file';

class CallNotify extends Component {
    selectImportPaymentFunc = value => {
        this.props.callNotifyActions.selectCallNotify(value);
        this.props.history.push('/recipient')
    }

    render() {
        const { list,
            callNotifyActions,
            fileActions,
            isSupervisor } = this.props;

        if (!isSupervisor) {
            return (<p>Доступ запрещен</p>)
        }

        return (
            <Switch>
                <Route path='/call-notify/list' render={(props) =>
                    <CallNotifyList {...props}
                        list={list}
                        searchFunc={callNotifyActions.search}
                        downloadFileFunc={fileActions.downloadFile}
                        selectCallNotifyFunc={this.selectImportPaymentFunc}></CallNotifyList>
                }></Route>
            </Switch>
        )
    }
}

function mapStateToProps(state) {
    return {
        list: state.callNotify.list,
        isSupervisor: state.user.isSupervisor,
    }
}

function mapDispathToProps(dispatch) {
    return {
        callNotifyActions: bindActionCreators(callNotifyActions, dispatch),
        fileActions: bindActionCreators(fileActions, dispatch),
    }
}

CallNotify.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default connect(mapStateToProps, mapDispathToProps)(withRouter(CallNotify));