import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListGroup } from 'reactstrap';

import LawCourtListItem from '../../components/LawCourt/ListItem';

import * as lawCourtActions from '../../actions/law-court';

class LawCourtList extends Component {
    componentDidMount() {
        this.props.lawCourtActions.search();
    }

    selectFunc = (id) => {
        this.props.lawCourtActions.selectDetail(id);
        this.props.lawCourtActions.getRecipients(id);
    }

    render() {
        const { list,
            selectedItem } = this.props;

        var renderList = list && list.map((value) => {
            return (
                <LawCourtListItem key={value.id}
                    item={value}
                    selectFunc={this.selectFunc}
                    active={selectedItem && value.id === selectedItem.id} />
            )
        })

        return (
            <ListGroup>
                {renderList}
            </ListGroup>
        )
    }
}

function mapStateToProps(state) {
    return {
        list: state.lawCourt.list,
        selectedItem: state.lawCourt.selectedItem
    }
}

function mapDispathToProps(dispatch) {
    return {
        lawCourtActions: bindActionCreators(lawCourtActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(LawCourtList);