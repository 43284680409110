import { LEGAL_API } from './legal'

export const PROFILE = 'PROFILE'
export const CONTRACT = 'CONTRACT'
export const COURT_DECISION = 'COURT_DECISION'
export const EXE_PRODUCTION = 'EXE_PRODUCTION'
export const DOCS = 'DOCS'
export const PLEDGE_ITEM = 'PLEDGE_ITEM'

export const RECIPIENT_DEBT_INFO_URL = LEGAL_API + '/recipient/{recipientId}/debt-info'
