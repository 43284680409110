import axios from '../axios'

import { HOME_ACTIVE_BANKRUPT_COUNT, HOME_STATS, HOME_LIMIT_EXEPRODREQUEST_COUNT, HOME_TASKS_COUNT } from '../../constants/home'

export const getActiveBankruptCount = () => axios.get(HOME_ACTIVE_BANKRUPT_COUNT);

export const getStats = () => axios.get(HOME_STATS);

export const getLimitExeProdRequestCount = () => axios.get(HOME_LIMIT_EXEPRODREQUEST_COUNT);

export const getTaskManagerTasksCurrentCount = () => axios.get(HOME_TASKS_COUNT);