import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Aux from '../../../hoc/ReactAux/ReactAux';
import { Button, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';

import ReportTemplateDetailEdit from './edit';

import * as reportTemplateEditActions from '../../../actions/report-template/report-template';
import * as fileActions from '../../../actions/file';

import { LEGAL_DOWNLOAD_FILE } from '../../../constants/legal';

class ReportTemplateDetail extends Component {
    componentDidMount() {
        this.props.reportTemplateEditActions.get(this.props.reportTemplateId);
    }

    render() {
        const { item,
            editReportTemplate,
            reportTemplateEditActions,
            fileActions } = this.props;

        if (!item) {
            return <p>Пусто</p>
        }

        return (
            <Row>
                <Col md='7'>
                    <Card>
                        <CardHeader>Шаблон документа</CardHeader>
                        <CardBody>
                            {editReportTemplate.formEnable ?
                                <ReportTemplateDetailEdit cancelFunc={reportTemplateEditActions.editCancel}
                                    onSubmit={reportTemplateEditActions}
                                    addFileFunc={reportTemplateEditActions.addFile}
                                    item={editReportTemplate.item}
                                    saveFunc={() => {
                                        reportTemplateEditActions.save(editReportTemplate.item);
                                    }} /> :

                                <Aux>
                                    <h1>{item.reportType}</h1>
                                    <p>{item.fileName}</p>
                                    <Row className='justify-content-end'>
                                        <Button color='link' onClick={() => {
                                            reportTemplateEditActions.edit(item);
                                        }} >Редактировать</Button>
                                        <Button color='primary' onClick={() => {
                                            fileActions.downloadFile(LEGAL_DOWNLOAD_FILE + item.fileID);
                                        }} >Скачать</Button>
                                    </Row>
                                </Aux>}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}

function mapStateToProps(state) {
    return {
        item: state.reportTemplate.item,
        editReportTemplate: state.reportTemplate.editReportTemplate,
    }
}

function mapDispathToProps(dispatch) {
    return {
        reportTemplateEditActions: bindActionCreators(reportTemplateEditActions, dispatch),
        fileActions: bindActionCreators(fileActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(ReportTemplateDetail);