import { NOTIFICATION_EXE_PRODS,
    NOTIFICATION_EXE_PRODS_UNREAD_COUNT } from '../../constants/notification';
import { OK } from '../../constants/legal';

const initialState = {
    list: null,
    unreadMessageCount: null
}

export const notificationExeProd = (state = initialState, action = {}) => {
    switch (action.type) {
        case NOTIFICATION_EXE_PRODS + OK:
            return {
                ...state,
                list: action.payload
            }

        case NOTIFICATION_EXE_PRODS_UNREAD_COUNT + OK:
            return {
                ...state,
                unreadMessageCount: action.payload
            }

        default:
            return state;
    }
}