import instance from '../services/axios';
import {
    RECIPIENTS_LOAD,
    RECIPIENT_LOAD,
    OK,
    FAIL,
    REQUEST,
    RECIPIENTS_FILTER} from '../constants/legal';

export function filterRecipient(values) {
    return dispatch => {
        dispatch({
            type: RECIPIENTS_FILTER + REQUEST
        })

        instance.post('/recipients/search', values)
            .then(result => {
                dispatch({
                    type: RECIPIENTS_LOAD + OK,
                    payload: result.data,
                    filter: values
                })
            })
            .catch(result => {
                dispatch({
                    type: RECIPIENTS_FILTER + FAIL,
                    errors: result.message
                })
            });
    }
}

export function getRecipient(id) {
    return dispatch => {
        dispatch({
            type: RECIPIENT_LOAD + REQUEST,
            recipientId: id
        });

        instance.get('/recipient/' + id)
            .then(result => {
                dispatch({
                    type: RECIPIENT_LOAD + OK,
                    recipient: result.data
                })
            })
            .catch(result => {
                dispatch({
                    type: RECIPIENT_LOAD + FAIL,
                    errors: result.message
                })
            });
    }
}