import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Aux from '../../../hoc/ReactAux/ReactAux'

import CourtDecisionList from '../../court-decision/list'
import ExeProductionDocRequestForm from '../../executing-productions/doc-request'

import * as courtDecisionActions from '../../../actions/recipient-court-decision'
import * as fileActions from '../../../actions/file'
import { dateToString } from '../../../utils/helper'

import { DOWNLOAD_EXE_PRODUCTION_REQUEST } from '../../../constants/legal'

class RecipientDetailCourtDecision extends Component {
  componentDidMount() {
    this.props.courtDecisionActions.get(this.props.recipientId)
  }

  downloadExeProductionRequest = values => {
    values.proceduralSuccessionDate = dateToString(
      values.proceduralSuccessionDate
    )
    values.judicalActDate = dateToString(values.judicalActDate)

    this.props.fileActions.downloadFile(DOWNLOAD_EXE_PRODUCTION_REQUEST, values)
    this.props.courtDecisionActions.exeProductionRequestCancel()
  }

  render() {
    const {
      courtDecisions,
      courtDecisionActions,
      exeProductionRequestModel,
      lawCourtName,
      fileActions } = this.props;

    return (
      <Aux>
        {exeProductionRequestModel ? (
          <ExeProductionDocRequestForm
            onSubmit={this.downloadExeProductionRequest}
            cancelFunc={courtDecisionActions.exeProductionRequestCancel}
          />
        ) : (
            <CourtDecisionList
              list={courtDecisions}
              editFunc={courtDecisionActions.edit}
              downloadFileFunc={fileActions.downloadFile}
              getExeProductionRequestModel={
                courtDecisionActions.getExeProductionRequestModel
              }
              removeCourtDecisionFunc={courtDecisionActions.remove}
            >
              {lawCourtName}
            </CourtDecisionList>
          )}
      </Aux>
    )
  }
}

function mapStateToProps(state) {
  return {
    courtDecisions: state.recipient.courtDecisions,
    exeProductionRequestModel: state.recipient.exeProductionRequestModel,
  }
}

function mapDispathToProps(dispatch) {
  return {
    courtDecisionActions: bindActionCreators(courtDecisionActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispathToProps
)(RecipientDetailCourtDecision)
