import React from 'react'
import { Route, Switch } from 'react-router-dom'

import CreditBureauList from '../../components/credit-bureau/list'
import CreditBureauOKB from '../../components/credit-bureau/okb'
import CreditBureauOKBExport from '../../components/credit-bureau/okb-export'

export default () => {
  return (
    <Switch>
      <Route exact path='/credit-bureau' component={CreditBureauList} />
      <Route exact path='/credit-bureau/okb' component={CreditBureauOKB} />
      <Route
        path='/credit-bureau/okb/export'
        component={CreditBureauOKBExport}
      />
    </Switch>
  )
}
