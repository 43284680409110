import React from 'react';
import { Link } from 'react-router-dom';

const ScreensSettingsMain = () => {
    return (
        <div>
            <h2>Настройки</h2>
            <Link to='/settings/report-template'>Шаблоны документов</Link>
        </div>
    )
}

export default ScreensSettingsMain;