import React, { useState, useEffect, } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Aux from '../../hoc/ReactAux/ReactAux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as homeApi from '../../services/webapi/home-api'
import * as personalInfoActions from '../../actions/personal-info'

const LimitExeProdRequestTotal = props => {
  const openRecipientsClick = () => {
    props.personalInfoActions.clearFilter()
      .then(() => {
        props.personalInfoActions.setFilter({ ...props.filter, hasLimitExeProdRequest: true })
      })
      .then(() => {
        props.history.push('/personal-info')
      })
  }

  const [count, setCount] = useState(0)

  useEffect(() => {
    homeApi
      .getLimitExeProdRequestCount()
      .then(result => {
        setCount(result.data.count)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  let elClassName = 'p-3 text-white m-3 btn';

  if (count > 0 && 10 >= count) {
      elClassName += ' btn-warning';
  } else if (count > 10) {
    elClassName += ' btn-danger';
  } else {
    elClassName += ' btn-success';
  }

  return (
    <Aux>
      <Col
        sm='5'
        xs='11'
        xl='3'
        onClick={openRecipientsClick}
        className={elClassName}
      >
        <h3>Срок ВИП</h3>
        <Row>
          <Col xs='5'>
            <i className='fa fa-address-card-o fa-3x pt-2' aria-hidden='true' />
          </Col>
          <Col xs='7' className='display-4 font-weight-bold'>
            {count}
          </Col>
        </Row>
      </Col>
    </Aux>
  )
}

function mapStateToProps(state) {
  return {
    filter: state.personalInfo.listFilter,
  }
}

function mapDispathToProps(dispatch) {
  return {
    personalInfoActions: bindActionCreators(personalInfoActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispathToProps)(withRouter(LimitExeProdRequestTotal))
