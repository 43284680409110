import React, { Component } from 'react'
import moment from 'moment'
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Card,
  CardBody,
  CardHeader,
  Row,
  Button,
  UncontrolledTooltip,
} from 'reactstrap'
import FileList from '../file/list'

class ActivityList extends Component {
  render() {
    const { activities, downloadFileFunc, editFunc, deleteFunc } = this.props

    var renderActivity =
      activities &&
      activities
        .sort((a, b) => {
          return new Date(b.creationDate) - new Date(a.creationDate)
        })
        .map((value, index) => {
          return (
            <ListGroupItem
              key={value.id}
              color={index % 2 ? 'secondary' : ''}
              action
            >
              <ListGroupItemHeading>
                <div className='d-flex justify-content-between'>
                  <span>{value.recipientActivityType.name}</span>
                  <small className='text-right text-muted'>
                    {moment
                      .utc(value.creationDate)
                      .local()
                      .format('DD.MM.YYYY ')}
                    {moment
                      .utc(value.creationDate)
                      .local()
                      .format('HH:mm')}
                    <br />
                    {value.lastUpdatedAgentEmail}
                  </small>
                </div>
              </ListGroupItemHeading>
              <ListGroupItemText>
                <Button
                  color='danger'
                  outline
                  className='float-right ml-2'
                  id={'UncontrolledTooltipDelete' + value.id}
                  onClick={() => {
                    deleteFunc(value)
                  }}
                >
                  <i className='fa fa-trash-o' aria-hidden='true'></i>
                </Button>

                <UncontrolledTooltip
                  placement='right'
                  target={'UncontrolledTooltipDelete' + value.id}
                >
                  Удалить
                </UncontrolledTooltip>

                <Button
                  color='secondary'
                  outline
                  className='float-right'
                  id={'UncontrolledTooltipEdit' + value.id}
                  onClick={() => {
                    editFunc(value)
                  }}
                >
                  <i className='fa fa-pencil-square-o' aria-hidden='true'></i>
                </Button>

                <UncontrolledTooltip
                  placement='left'
                  target={'UncontrolledTooltipEdit' + value.id}
                >
                  Редактировать
                </UncontrolledTooltip>
                <span>
                  <span className='col-xs-6'>
                    {value.comment}
                  </span>
                  <span className='col-xs-6'>
                    <FileList
                      files={value.files}
                      downloadFileFunc={downloadFileFunc}
                    ></FileList>
                  </span>
                </span>
              </ListGroupItemText>
            </ListGroupItem>
          )
        })

    return (
      <Card color='light'>
        <CardHeader tag='h5'>Активности</CardHeader>
        <CardBody>
          {activities && activities.length > 0 ? (
            <ListGroup>{renderActivity}</ListGroup>
          ) : (
            <Row className='pl-2'>Данные отсутствуют</Row>
          )}
        </CardBody>
      </Card>
    )
  }
}

export default ActivityList
