import {
    OK,
    LAW_COURTS,
    LAW_COURTS_LIST,
    LAW_COURT_SELECT,
    LAW_COURT_UNION,
    LAW_COURT_RECIPIENTS,
    CANCEL
} from '../constants/legal';

const initialState = {
    recipientSearchList: [], // Список судов на фильтре поиска должников
    list: [], // Список судов
    selectedItem: null, // Выбранное значение
    selectedItemRecipients: [], // Список ресипиентов в выбранном суде
    unionItems: [], // Список судов для объединения с выбранным
};

export const lawCourt = (state = initialState, action = {}) => {
    switch (action.type) {
        case LAW_COURTS + OK:
            return {
                ...state,
                recipientSearchList: action.payload
            }

        case LAW_COURTS_LIST + OK:
            return {
                ...state,
                list: action.payload
            }

        case LAW_COURT_SELECT:
            {
                const isCurrentSelected = state.selectedItem && state.selectedItem.id === action.payload;

                return {
                    ...state,
                    selectedItem: isCurrentSelected ? { ...state.selectedItem } : null,
                    unionItems: isCurrentSelected ? [...state.unionItems] : [],
                    selectedItemRecipients: isCurrentSelected ? [...state.selectedItemRecipients] : []
                }
            }

        case LAW_COURT_SELECT + OK:
            {
                const isCurrentSelected = state.selectedItem &&
                    action.payload &&
                    state.selectedItem.id === action.payload.id;

                return {
                    ...state,
                    selectedItem: action.payload,
                    unionItems: isCurrentSelected ? [...state.unionItems] : []
                }
            }

        case LAW_COURT_UNION:
            {
                let listItems = [...state.list];
                const unionItemIndex = listItems.findIndex(value => value.id === action.payload);
                const unionItem = listItems.splice(unionItemIndex, 1);
                const unionItems = [...state.unionItems, ...unionItem];

                return {
                    ...state,
                    list: listItems,
                    unionItems: unionItems
                }
            }

        case LAW_COURT_UNION + CANCEL:
            return {
                ...state,
                list: [...state.list, ...state.unionItems],
                unionItems: []
            }

        case LAW_COURT_RECIPIENTS + OK:
            return {
                ...state,
                selectedItemRecipients: action.payload
            }

        default:
            return state;
    }
}