import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormGroup, Button } from 'reactstrap';
import FileInput from './file-input';

const ImportPaymentForm = props => {
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup>
                <Field name='importFile'
                    component={FileInput}
                    type='file' >
                </Field>
            </FormGroup>
            <Button type='submit' color='primary'>Загрузить</Button>
        </form>
    )
}

export default reduxForm({
    form: 'ImportPayment'
})(ImportPaymentForm)