import axios from '../axios';
import { TOKEN_KEY, LOGOUT } from '../../constants/legal';

export const login = userInfo =>
  new Promise((resolve, reject) => {
    axios
      .post('token', userInfo)
      .then(result => {
        localStorage.setItem(TOKEN_KEY, result.data.access_token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(TOKEN_KEY);

        resolve(result.data);
      })
      .catch(err => {
        localStorage.removeItem(TOKEN_KEY);
        axios.defaults.headers.common['Authorization'] = '';

        reject(err);
      });
  });

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
}