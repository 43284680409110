import React from 'react';
import Aux from '../../hoc/ReactAux/ReactAux';

import RecipientDetail from '../../components/recipient/detail';

const ScreensRecipientDetail = (props) => {
    return (
        <Aux>
            <RecipientDetail recipientId={props.match.params.id} />
        </Aux>
    )
}

export default ScreensRecipientDetail;