import { LEGAL_API } from './legal'

export const PLEDGE_ITEM_API = LEGAL_API + '/PledgeItem/'

export const GET_URL = PLEDGE_ITEM_API
export const GET_LIST_BY_RECIPIENT_ID_URL =
  PLEDGE_ITEM_API + 'GetListByRecipientId?recipientId='

export const SAVE_OR_UPDATE_URL = PLEDGE_ITEM_API + 'saveOrUpdate';
export const REMOVE_URL = PLEDGE_ITEM_API + 'Remove/';

export const VIN_SEARCH_AUTOCOMPLETE_URL = PLEDGE_ITEM_API + 'search-autocomplete?vin=';
