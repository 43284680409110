import axios from '../../services/axios';
import { error, info } from 'react-notification-system-redux';

import {
    REQUEST,
    OK,
    FAIL,
    CREATE_BANNING_DEPARTURE_LIST_URL
} from '../../constants/legal';
import {
    EXE_PRODUCTION_LIST,
    EXE_PRODUCTION_LIST_URL,
    PRINT,
    ELEMENT_TOGGLE,
    SET_ALL
} from '../../constants/exe-prod';

export function getList() {
    return dispatch => {
        dispatch({
            type: EXE_PRODUCTION_LIST + PRINT + REQUEST
        });

        axios.post(EXE_PRODUCTION_LIST_URL)
            .then(result => {
                dispatch({
                    type: EXE_PRODUCTION_LIST + PRINT + OK,
                    payload: result.data,
                })
            })
            .catch(result => {
                dispatch({
                    type: EXE_PRODUCTION_LIST + PRINT + FAIL,
                    errors: result.message
                })

                dispatch(
                    error({
                        title: 'ExeProds. Get list',
                        message: result.response.data.error || result.message
                    }));
            });
    }
}

export function printItemToggle(itemId) {
    return dispatch => {
        dispatch({
            type: EXE_PRODUCTION_LIST + PRINT + ELEMENT_TOGGLE,
            payload: itemId
        })
    }
}

export function printItemSetAll(value) {
    return dispatch => {
        dispatch({
            type: EXE_PRODUCTION_LIST + PRINT + SET_ALL,
            payload: value
        })
    }
}

export function printHandle(exeProdIds) {
    return dispatch => {
        axios.post(CREATE_BANNING_DEPARTURE_LIST_URL, { exeProdIds: exeProdIds })
            .then(result => {
                dispatch({
                    type: EXE_PRODUCTION_LIST + PRINT
                })

                dispatch(
                    info({
                        title: 'Заявление о запрете на выезд',
                        message: 'Запрос сформирован. Количество документов: ' + result.data.count
                    }));

            })
            .catch(result => {
                dispatch({
                    type: EXE_PRODUCTION_LIST + PRINT + FAIL,
                    errors: result.message
                })

                dispatch(
                    error({
                        title: 'ExeProds. Get list',
                        message: result.response.data.error || result.message
                    }));
            })
    }
}