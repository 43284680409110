import React from 'react';
import moment from 'moment';
import axios from '../services/axios';
import {
    OK,
    FAIL,
    REQUEST,
    CALL_NOTIFY,
    SELECT,
    CANCEL,
    SAVE,
} from '../constants/legal';
import { info, error, success } from 'react-notification-system-redux';

export function search() {
    return dispatch => {
        dispatch({
            type: CALL_NOTIFY + REQUEST
        });

        axios.get('/CallNotify/search')
            .then(result => {
                dispatch({
                    type: CALL_NOTIFY + OK,
                    payload: result.data,
                })
            })
            .catch(result => {
                dispatch({
                    type: CALL_NOTIFY + FAIL,
                    errors: result.message
                })

                dispatch(
                    error({
                        title: 'Call notify',
                        message: result.response.data.error || result.message
                    }));
            });
    }
}

export const selectCallNotify = value => {
    return dispatch => {
        dispatch({
            type: CALL_NOTIFY + SELECT,
            payload: value
        })
    }
}

export const addSelectedCallNotify = (callNotifyId, recipientId) => {
    return dispatch => {
        axios.post('/recipient/' + recipientId + '/activity/add-call-notify/' + callNotifyId)
            .then(result => {
                dispatch({
                    type: CALL_NOTIFY + SELECT + SAVE,
                    payload: result.data
                });

                dispatch(
                    success({
                        title: 'Активность успешно создана',
                        message: result.data.comment
                    }));
            })
            .catch(result => {
                dispatch(
                    error({
                        title: 'Ошибка создания активности',
                        message: result.response.data.error || result.message
                    }));
            });
    }
}

export const cancelSelectedCallNotify = () => {
    return dispatch => {
        dispatch({
            type: CALL_NOTIFY + SELECT + CANCEL
        })
    }
}

export const eventReceivedNotify = (message) => {
    return dispatch => {
        console.log(message);
        let title = moment.utc(message.call_Start).local().format('DD.MM.YYYY HH:mm.ss')
        let header = (message.callerId || message.internal) + ' > ' + (message.destination || message.calledDid);
        let text = message.eventDescription;

        dispatch(
            info({
                title: 'Zadarma ' + title,
                children: (
                    <div>
                        <strong>{header}</strong>
                        <div>{text}</div>
                    </div>
                )
            })
        );
    };
}
