import axios from 'axios';
import { LEGAL_API, TOKEN_KEY } from '../constants/legal';

const UNAUTHORIZED_HTTP_CODE = 401
const FORBIDDEN_HTTP_CODE = 403

var instance = axios.create({
    baseURL: LEGAL_API
});

if (localStorage.getItem(TOKEN_KEY)) {
    // Alter defaults after instance has been created
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(TOKEN_KEY);
}

// Add a 401 response interceptor
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === UNAUTHORIZED_HTTP_CODE) {
        instance.defaults.headers.common['Authorization'] = '';
        localStorage.removeItem(TOKEN_KEY);

        if (window.location.pathname !== '/login') {
            window.location = '/login';
        }
    } else if (error.response.status === FORBIDDEN_HTTP_CODE) {
        window.location = '/not-found'
    } else {
        return Promise.reject(error);
    }
});

export default instance;