import { LOGIN, REQUEST, OK, FAIL, LOGOUT, TOKEN_KEY } from '../constants/legal';
import { GetUserInfo } from '../utils/helper';

const initialState = (token => {
    var userInfo = token ? GetUserInfo(token) : null;

    return {
        currentUser: userInfo && userInfo.username,
        isAuth: !!userInfo,
        errorMessage: null,
        isAdmin: userInfo && userInfo.isAdmin,
        isSupervisor: userInfo && userInfo.isSupervisor,
        organizationName: userInfo && userInfo.organizationName,
    }
})(localStorage.getItem(TOKEN_KEY));

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case LOGIN + REQUEST:
            return {
                ...state,
                isAuth: true
            }

        case LOGIN + OK:
            return {
                ...state,
                isAuth: true,
                currentUser: action.userInfo.username,
                isAdmin: action.userInfo.isAdmin,
                isSupervisor: action.userInfo.isSupervisor,
                errorMessage: null,
                organizationName: action.userInfo.organizationName,
            }

        case LOGIN + FAIL:
            return {
                ...state,
                isAuth: false,
                currentUser: null,
                isAdmin: false,
                isSupervisor: false,
                errorMessage: action.errorMessage,
                organizationName: null,
            }

        case LOGOUT:
            return {
                ...state,
                isAuth: false,
                currentUser: null,
                isAdmin: false,
                isSupervisor: false,
                errorMessage: null,
                organizationName: null,
            }

        default:
            return state;
    }
}

export default reducer;