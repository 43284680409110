import React, { Component } from 'react';
import moment from 'moment';
import { ListGroupItem, ListGroupItemHeading, ListGroupItemText, Row, Button, Badge, UncontrolledTooltip } from 'reactstrap';

import Aux from '../../hoc/ReactAux/ReactAux'

export default class RecipientPaymentListItem extends Component {
  render() {
    const { item,
      colorSecondary,
      editFunc,
      editButtonText,
      color,
      recipientId,
      addSelectedImportPaymentFunc,
      cancelSelectedImportPaymentFunc,
      deleteFunc } = this.props;

    return (
      <ListGroupItem color={colorSecondary ? 'secondary' : ''} action color={color}>
        <ListGroupItemHeading>
          <Row className='d-flex justify-content-between pl-2'>
            <span className='text-right'>
              <span className='text-muted mr-2'>
                {moment.utc(item.date).local().format('DD.MM.YYYY')}
              </span>
              {(item.amount).toLocaleString('ru-RU')} руб
                        </span>
            <small>{item.source}</small>
            {item.isDebtСancellation ?
              <Badge color='light'>Не учитывается как доход</Badge> :
              null}
          </Row>
        </ListGroupItemHeading>
        <ListGroupItemText>
          {deleteFunc ?
            <Aux>
              <Button
                outline
                color='danger'
                className='float-right ml-2'
                onClick={() => {
                  deleteFunc({
                    id: item.id,
                    recipientId: item.recipientId
                  })
                }}
                id={'PaymentItemRemove' + item.id}
              >
                <i className='fa fa-trash-o' aria-hidden='true' />
              </Button>
              <UncontrolledTooltip
                placement='top'
                target={'PaymentItemRemove' + item.id}
              >
                Удалить
          </UncontrolledTooltip>
            </Aux> : null}
          {editFunc ?
            <Aux>
              <Button
                outline
                color='secondary'
                className='float-right'
                onClick={() => {
                  editFunc(item);
                }}
                id={'PaymentTooltipEdit' + item.id}>
                {editButtonText ?
                  editButtonText :
                  <i className='fa fa-pencil-square-o' aria-hidden='true'></i>}
              </Button>
              <UncontrolledTooltip
                placement='left'
                target={'PaymentTooltipEdit' + item.id}
              >
                {editButtonText || 'Редактировать'}
              </UncontrolledTooltip>
            </Aux> : null}
          <span>
            {recipientId ?
              <Button color='primary' className='float-right'
                onClick={() => {
                  addSelectedImportPaymentFunc(item);
                }}>Привязать платёж</Button>
              : null}
            {cancelSelectedImportPaymentFunc ?
              <Button outline color='secondary' className='float-right mr-2'
                onClick={cancelSelectedImportPaymentFunc}>Отмена</Button>
              : null}
          </span>
          {item.comment}
        </ListGroupItemText>
      </ListGroupItem>
    )
  }
}