import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormGroup, Button } from 'reactstrap';
import FileInput from '../../components/file/file-input';

const DebtAmnesty2019Form = props => {
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup>
                <Field name='importFile'
                    component={FileInput}
                    type='file' >
                </Field>
            </FormGroup>
            <Button type='submit' color='primary'>Загрузить</Button>
        </form>
    )
}

export default reduxForm({
    form: 'DebtAmnesty2019Form'
})(DebtAmnesty2019Form)