import React, { useState, useEffect } from 'react';
import Aux from '../../../hoc/ReactAux/ReactAux';

import RecipientDocs from '../documents';

import * as fileActions from '../../../actions/file';
import * as personalInfoApi from '../../../services/webapi/personal-info-api'

const RecipientDetailDocs = (props) => {
  const { recipientId } = props;

  const [documents, setDocuments] = useState([])

  useEffect(() => {
    personalInfoApi
      .getDocumentsByRecipient(recipientId)
      .then(result => {
        setDocuments(result.data);
      })
      .catch(err => {
        console.log(err)
      });
  }, [recipientId])

  if (documents.length === 0) {
    return <div>Документы отсутствуют</div>
  }

  const documentsRender = documents.map((val, i) => {
    return (
      <Aux key={i}>
        <div>{val.fio}</div>
        <RecipientDocs list={val.documents}
          downloadFileFunc={fileActions.download}
          recipientId={recipientId}>
        </RecipientDocs>
      </Aux>
    )
  })

  return (
    <Aux>{documentsRender}</Aux>
  )
}

export default RecipientDetailDocs;