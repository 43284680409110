import {
    OK,
    RECIPIENT_PAYMENT,
    CREATE,
    CANCEL,
    EDIT,
    IMPORT,
    SELECT,
    SAVE,
} from '../constants/legal';

const initialState = {
    list: [],
    editPayment: {
        formEnable: false,
        item: {}
    },
    import: {
        selected: null,
        process: false,
        otherTypeRecords: [],
        newRecords: [],
        existingRecords: [],
        errorRecords: [],
        notFoundRecipient: []
    }
};

export const payment = (state = initialState, action = {}) => {
    switch (action.type) {
        case RECIPIENT_PAYMENT + OK:
            return {
                ...state,
                list: action.payload
            }

        case RECIPIENT_PAYMENT + CREATE:
            return {
                ...state,
                editPayment: {
                    formEnable: true,
                    item: {},
                    create: true,
                },
            }

        case RECIPIENT_PAYMENT + CREATE + CANCEL:
            return {
                ...state,
                editPayment: {
                    formEnable: false,
                    item: {}
                },
            }

        case RECIPIENT_PAYMENT + CREATE + OK:
            return {
                ...state,
                editPayment: {
                    formEnable: false,
                    item: {}
                },
                list: state.list.concat(action.payload)
            }

        case RECIPIENT_PAYMENT + EDIT:
            return {
                ...state,
                editPayment: {
                    formEnable: true,
                    item: action.payload
                }
            }

        case RECIPIENT_PAYMENT + EDIT + OK:
            {
                let list = state.list && state.list.map(value => {
                    if (value && value.id === action.payload.id) {
                        return action.payload;
                    }

                    return value;
                })

                return {
                    ...state,
                    editPayment: {
                        formEnable: false,
                        item: {}
                    },
                    list: list
                }
            }

        case RECIPIENT_PAYMENT + IMPORT + OK:
            return {
                ...state,
                import: {
                    ...action.payload,
                    process: true
                }
            }

        case RECIPIENT_PAYMENT + IMPORT + SELECT:
            {
                let item = action.payload;
                delete item.id;

                return {
                    ...state,
                    import: {
                        ...state.import,
                        selected: item,
                    }
                }
            }

        case RECIPIENT_PAYMENT + IMPORT + CANCEL:
            return {
                ...state,
                import: {
                    selected: null,
                    process: false,
                    otherTypeRecords: [],
                    newRecords: [],
                    existingRecords: [],
                    errorRecords: [],
                    notFoundRecipient: []
                }
            }

        case RECIPIENT_PAYMENT + IMPORT + SELECT + SAVE:
            {
                let selectedItem = state.import.selected;
                let notFoundRecipient = state.import.notFoundRecipient.filter(x => {
                    return x.importFileLineId !== selectedItem.importFileLineId
                });

                let newRecords = [...state.import.newRecords, action.payload];

                return {
                    ...state,
                    import: {
                        ...state.import,
                        selected: null,
                        notFoundRecipient: [...notFoundRecipient],
                        newRecords: newRecords
                    }
                }
            }

        case RECIPIENT_PAYMENT + IMPORT + SELECT + CANCEL:
            return {
                ...state,
                import: {
                    ...state.import,
                    selected: null
                }
            }

        default:
            return state;
    }
}