import axios from '../services/axios';
import {
    FILES_LOST,
    FILES_ATTACH,
    FILES_REMOVE,
    FILES_LOST_SAVE,
    REQUEST,
    OK,
    FAIL,
    FILE_UPLOAD,
    TOKEN_KEY,
} from '../constants/legal';

export const getLosts = () => {
    return dispatch => {
        dispatch({ type: FILES_LOST + REQUEST });

        axios.get('/file/losts')
            .then(result => {
                dispatch({
                    type: FILES_LOST + OK,
                    list: result.data
                });
            })
            .catch(result => {
                dispatch({
                    type: FILES_LOST + FAIL,
                    errors: result.message
                });
            })
    }
}

export const attachFile = (fileID, fileData) => {
    return dispatch => {
        dispatch({ type: FILES_ATTACH, fileID, fileData });
    }
}

export const removeFile = (fileID) => {
    return dispatch => {
        dispatch({ type: FILES_REMOVE, fileID });
    }
}

export const saveLostFiles = (files) => {
    return dispatch => {
        const data = new FormData();
        files.forEach((elem) => {
            if (!elem.fileData) {
                return;
            }

            data.append(elem.id, elem.fileData);
        });

        axios.post('/file/losts/attach', data)
            .then(result => {
                console.log(result);

                dispatch({ type: FILES_LOST_SAVE });
            });
    }
}

export const uploadFile = (fileData) => {
    const data = new FormData();
    if (!fileData) {
        return;
    }

    data.append('formFile', fileData);

    return axios.post('/file/upload', data);
}

export const importFileFromBank = (fileData, bankCode) => {
    return dispatch => {
        const data = new FormData();
        if (!fileData) {
            return;
        }

        data.append('formFile', fileData);
        data.append('bankCode', bankCode);

        axios.post('/file/import', data)
            .then(result => {
                dispatch({ type: FILE_UPLOAD + OK });

                return result.data;
            });
    }
}

export const printDebtAmnesty2019 = (fileData) => {
    return dispatch => {
        const data = new FormData();
        if (!fileData) {
            return;
        }

        data.append('formFile', fileData);

        axios.post('/Document/CreateDebtAmnesty2019', data)
            .then(result => {
                dispatch({ type: FILE_UPLOAD + OK });

                return result.data;
            });
    }
}

export const download = (fileUrl, data) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
        var filename = '';
        var disposition = xhr.getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            //var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var filenameRegex = /filename\*=UTF-8''((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
            }
        }

        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
        a.download = filename; // Set the file name.
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        //delete a;
    };

    xhr.open(data ? 'POST' : 'GET', fileUrl);
    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(TOKEN_KEY));
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(data));
}

export const downloadFile = (fileUrl, data) => {
    return dispatch => {
        download(fileUrl, data);
    }
}