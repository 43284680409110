import React from 'react'
import Aux from '../../../hoc/ReactAux/ReactAux'
import { Link } from 'react-router-dom'
import { moneyToRub } from '../../../utils/helper'

const RecipientListTableItem = props => {
  const { recipient } = props

  return (
    <Aux>
      <tr>
        <td>
          {recipient.name}
          <br />
          <Link to={'/recipient/' + props.recipient.id}>Подробнее</Link>
        </td>
        <td>{recipient.birthDate}</td>
        <td>{recipient.creditAgreementNumber}</td>
        <td>{recipient.stage}</td>
        <td>{recipient.stagePhase}</td>
        <td className='text-right'>
          {moneyToRub(recipient.loanBalanceTotalRURCurrent)}
        </td>
        <td className='text-right'>
          {moneyToRub(recipient.loanBalanceStampDutyCurrent)}
        </td>
        <td className='text-right'>
          {moneyToRub(recipient.loanPercentSumCurrent)}
        </td>
        <td className='text-right'>{moneyToRub(recipient.loanSumCurrent)}</td>
        <td className='text-right'>
          {moneyToRub(recipient.loanBalancePenaltyApprovedCurrent)}
        </td>
        {/* <td>№ Исполнительного листа</td> */}
        <td>{recipient.judicalActNumber}</td>
        <td>{recipient.lawCourtNameTo}</td>
        {/* <td>Определение о правопреемстве</td> */}
        <td>{recipient.exeProductionNumber}</td>
        <td>{recipient.exeProductionDate}</td>
        <td>{recipient.exeProductionEndDate}</td>
        {/* <td>Дата отправки заявления о запрете на выезд</td> */}
        <td>{recipient.exeProductionDepartment}</td>
        {/* <td>Оплата за период, в руб.</td>
        <td>% оплаты</td>
        <td>Первоначальный кредитор (правопреемство)</td> */}
      </tr>
    </Aux>
  )
}

export default RecipientListTableItem
