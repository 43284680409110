import React, { Component } from 'react';
import { Button, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';

class CheckLosts extends Component {
    constructor(props){
        super(props);
        this.state = { saveBtnEnable: false };
    }

    componentDidMount() {
        this.props.getLosts();
    }

    checkLostFiles = () => {
        this.props.getLosts();
        this.setState({ saveBtnEnable: false });
    }

    attachFile = (fileID, fileData) => {
        this.props.attachFile(fileID, fileData);
        this.setState({ saveBtnEnable: true });
    }

    removeFile = (fileID) => {
        this.props.removeFile(fileID);
    }

    saveFiles = (files) => {
        this.props.saveLostFiles(files);
        this.setState({ saveBtnEnable: false });
    }

    render() {
        const { files } = this.props;

        const listFile = files && files.map((file, index) => {
            return (
                <ListGroupItem key={file.id}>
                    <ListGroupItemHeading>{index+1} {file.personName}</ListGroupItemHeading>
                    <ListGroupItemText>
                        <span className='row'>
                            <span className='col-8'>
                                тип активности: {file.activityType}<br />
                                комментарий: {file.activityComment}<br />
                                название файла: {file.name}
                            </span>
                            <span className='col-4'>
                                <span>
                                    {file.fileData ? (
                                        <span>
                                            <label>{file.fileData.name}</label>
                                            <Button color='link' onClick={() => this.removeFile(file.id)}>
                                                <i className='fa fa-trash' aria-hidden='true'></i>
                                            </Button>
                                        </span>
                                    ) : (
                                            <label htmlFor={'file' + file.id}>
                                                <input type='file' name={'file' + file.id}
                                                    onChange={(event) => {
                                                        return this.attachFile(file.id, event.target.files[0])
                                                    }} />
                                            </label>
                                        )}
                                </span>
                            </span>
                        </span>
                    </ListGroupItemText>
                </ListGroupItem>
            )
        });

        return (
            <div>
                <h3>Проверка наличия файлов</h3>
                <div>
                    <Button color='info' onClick={this.checkLostFiles}>Проверить</Button>
                    <Button color='primary' onClick={() => this.saveFiles(files)} className='ml-2' disabled={!this.state.saveBtnEnable}>Сохранить</Button>
                </div>
                <hr />
                <ListGroup>
                    {listFile}
                </ListGroup>
            </div>
        )
    }
}

export default CheckLosts;