import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as accountApi from '../../services/webapi/account-api'
import { LEGAL_APP_V2 } from '../../constants/legal'

class MainMenu extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  exit() {
    accountApi.logout();
    window.location.href = '/';
  }

  render() {
    const { currentUser, isSupervisor, organizationName } = this.props;

    return (
      <div>
        <Navbar color='light' expand='sm' light className='navbar-light' fixed='top'>
          <Link to='/' className='navbar-brand'>{organizationName}</Link>
          <div className='float-right .d-block d-sm-none'>{currentUser}
            <i className='fa fa-sign-out ml-2' aria-hidden='true' onClick={() => { this.exit() }}></i>
          </div>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar className='navbar-collapse'>
            <Nav navbar>
              <NavItem>
                <Link to='/recipient' className='nav-link'>Договоры</Link>
              </NavItem>
            </Nav>
            <Nav navbar>
              <NavItem>
                <Link to='/personal-info' className='nav-link'>Клиенты</Link>
              </NavItem>
            </Nav>
            <Nav navbar hidden={!isSupervisor}>
              <NavItem>
                <Link to='/accounting/tax' className='nav-link'>Бухгалтерия</Link>
              </NavItem>
            </Nav>
            <Nav navbar hidden={!isSupervisor}>
              <NavItem>
                <Link to='/call-notify/list' className='nav-link'>Звонки</Link>
              </NavItem>
            </Nav>
            <Nav navbar>
              <NavItem>
                <Link to='/settings' className='nav-link'>Настройки</Link>
              </NavItem>
            </Nav>
            <Nav navbar>
              <NavItem>
                <Link to='/catalogs' className='nav-link'>Справочники</Link>
              </NavItem>
            </Nav>
            <Nav navbar>
              <NavItem>
                <Link to='/print' className='nav-link'>Печать</Link>
              </NavItem>
            </Nav>
            <Nav navbar>
              <NavItem>
                <Link to='/document-flow/outgoing' className='nav-link'>Документооборот</Link>
              </NavItem>
            </Nav>
            <Nav navbar>
              <NavItem>
                <a href={LEGAL_APP_V2 + '/calendar'} className='nav-link'>Календарь</a>
              </NavItem>
            </Nav>
          </Collapse>
          <div className='float-right d-none d-sm-block' onClick={() => { this.exit() }}>{currentUser}
            <i className='fa fa-sign-out ml-2' aria-hidden='true'></i>
          </div>
        </Navbar>
      </div>
    );
  }
}

MainMenu.propTypes = {
  currentUser: PropTypes.string
}

export default MainMenu;