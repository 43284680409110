import React from 'react';
import { Button } from 'reactstrap';

const ExeProductionImportDropdown = (props) => {
    const { mainAction,
        cancelAction,
        buttonDisable } = props;

    return (
        <div>
            {buttonDisable ?
                <Button outline color='secondary' onClick={cancelAction}>
                    Отмена
                </Button> :
                <Button color='primary' onClick={mainAction}>
                    Получить данные с ФССП
                </Button>
            }
        </div>
    )
}

export default ExeProductionImportDropdown;