import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CustomInput, Button } from 'reactstrap';
import moment from 'moment';

import * as exeProdActions from '../../actions/exe-prod/exe-prod';
import './banning-departure-request.css'

class PrintBanningDepartureRequest extends Component {
    constructor(props) {
        super(props)

        this.printHandle = this.printHandle.bind(this);
    }

    clickItem(itemId) {
        this.props.exeProdActions.printItemToggle(itemId);
    }

    printHandle() {
        const exeProdIds = this.props.list
            .filter(item => item.checked)
            .map(m => m.id);

        this.props.exeProdActions.printHandle(exeProdIds);
    }

    componentDidMount() {
        this.props.exeProdActions.getList();
    }

    render() {
        const { list } = this.props;

        const { exeProdActions } = this.props;

        const exeLists = list && list.map((value, index) => {
            return (
                <div key={index}>
                    <CustomInput
                        type='checkbox'
                        id={index}
                        label={value.loanerName}
                        className='d-inline print-bdr'
                        onChange={() => this.clickItem(value.id)}
                        checked={value.checked} />
                    <div className='d-inline'> {value.exeProductionNumber} от от&nbsp;
                                {moment.utc(value.exeProductionDate).local().format('DD.MM.YYYY')}</div>
                    <div>{value.stage} {value.details}</div>
                </div>
            )
        })

        return (
            <div>
                <Button onClick={this.printHandle}
                    color='primary'
                    className='float-right'>Отправить на печать</Button>

                <Button onClick={() => exeProdActions.printItemSetAll(false)}
                    color='link'
                    className='float-right'>Снять выделения</Button>
                <h5>Печать запрет на выезд</h5>
            <div>{exeLists}</div>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        list: state.exeProd.printList
    }
}

function mapDispathToProps(dispatch) {
    return {
        exeProdActions: bindActionCreators(exeProdActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispathToProps)(PrintBanningDepartureRequest);