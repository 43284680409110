import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import { connect } from 'react-redux'
import FormDatePicker from '../common/form-datepicker'

let ActivityEditForm = props => {
  const {
    handleSubmit,
    activityType,
    editActivity,
    removeFileFunc,
    addFileFunc,
    cancelFunc,
  } = props

  const renderEditFiles =
    editActivity.files &&
    editActivity.files.map((value, index) => {
      return (
        <div key={index}>
          <label>{value.name || value.fileName}</label>
          <Button color='link' onClick={() => removeFileFunc(index)}>
            <i className='fa fa-trash' aria-hidden='true'></i>
          </Button>
        </div>
      )
    })

  return (
    <Card className='mb-2'>
      <CardHeader tag='h5'>
        {editActivity.item ? 'Редактировать' : 'Создать'}&nbsp;
        {activityType && activityType[0] && activityType[0].name}
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <div className='form-group row'>
            <label htmlFor='activity-comment' className='col-12 col-form-label'>
              Комментарий
            </label>
            <div className='col-12'>
              <Field
                component='input'
                type='textarea'
                className='form-control'
                id='activity-comment'
                name='comment'
                autoComplete='off'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label htmlFor='activity-regingo' className='col-12 col-form-label'>
              Регистрационные данные
            </label>
            <div className='col-6'>
              <Field
                component='input'
                type='text'
                className='form-control'
                id='activity-reg-number'
                name='regNumber'
                autoComplete='off'
              />
            </div>
            <div className='col-6'>
              <Field
                component={FormDatePicker}
                name='regDate'
                placeholder='C'
                autoComplete='off'
                className='form-control-'
              />
            </div>
          </div>

          <div className='form-group row'>
            <div className='col-12'>{renderEditFiles}</div>
            <div className='col-12'>
              <input
                type='file'
                name={'file'}
                onChange={event => {
                  addFileFunc(event.target.files[0])
                  event.target.value = ''
                  return
                }}
              />
            </div>
          </div>
          <div className='float-right'>
            <Button onClick={cancelFunc} outline color='secondary'>
              Отмена
            </Button>
            <Button type='submit' color='primary' className='ml-2'>
              Сохранить
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  )
}

ActivityEditForm = reduxForm({
  form: 'activityEdit',
})(ActivityEditForm)

ActivityEditForm = connect(state => ({
  initialValues: state.activity.editActivity.item,
}))(ActivityEditForm)

export default ActivityEditForm
