import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { Col, Label, Button, Card, CardBody, CardHeader, FormGroup } from 'reactstrap'
import FormDatePicker from '../common/form-datepicker'

let ExeProductionDocRequestForm = props => {
    const { handleSubmit,
        cancelFunc } = props;
    return (
        <Card className='mb-2'>
            <CardHeader tag='h5'>Сформировать заявление о возбуждении ИП</CardHeader>
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <FormGroup row>
                        <Col>
                            <Label>ФИО</Label>
                            <Field component='input' type='text' className='form-control'
                                id='fio' name='fio' autoComplete='off'></Field>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>Название суда</Label>
                            <Field component='textarea' className='form-control'
                                id='courtName' name='courtName' autoComplete='off'></Field>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>Адрес должника</Label>
                            <Field component='textarea' className='form-control'
                                id='address' name='address' autoComplete='off'></Field>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>Отдел судебных приставов</Label>
                            <Field component='textarea' className='form-control'
                                id='exeProductionAgency' name='exeProductionAgency' autoComplete='off'></Field>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label className='ml-3'>Дело №</Label>
                        <Col>
                            <Field component='input' type='text' className='form-control'
                                id='judical-act-number' name='judicalActNumber' autoComplete='off'></Field>
                        </Col>
                        <Label>от</Label>
                        <Col>
                            <Field component={FormDatePicker} type='text' className='form-control'
                                id='judical-act-date' name='judicalActDate' autoComplete='off'></Field>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label className='ml-4'>&nbsp;Акт №</Label>
                        <Col>
                            <Field component='input' type='text' className='form-control'
                                id='judical-deal-number' name='judicalDealNumber' autoComplete='off'></Field>
                        </Col>
                        {/* <Label>от</Label>
                        <Col>
                            <Field component={FormDatePicker} type='text' className='form-control'
                                id='judical-deal-date' name='judicalDealDate' autoComplete='off'></Field>
                        </Col> */}
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>Сумма долга</Label>
                            <Field component='textarea' className='form-control'
                                id='debt-sum' name='debtSum' autoComplete='off'></Field>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>Место работы</Label>
                            <Field component='textarea' className='form-control'
                                id='workPlace' name='workPlace' autoComplete='off'></Field>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>Дата определения о процессуальном правопреемстве</Label>
                            <Field component={FormDatePicker} type='text' className='form-control'
                                id='procedural-succession-date' name='proceduralSuccessionDate' autoComplete='off'></Field>
                        </Col>
                    </FormGroup>

                    <Field component='input' type='hidden' name='recipientId' />
                    <Field component='input' type='hidden' name='judicalActId' />

                    <div className='float-right'>
                        <Button outline color='secondary' onClick={cancelFunc}>Отмена</Button>
                        <Button type='submit' color='primary' className='ml-2'>Сформировать</Button>
                    </div>
                </form>
            </CardBody>
        </Card>
    )
}

ExeProductionDocRequestForm = reduxForm({
    form: 'courtDecisionEdit'
})(ExeProductionDocRequestForm)

ExeProductionDocRequestForm = connect(
    state => ({
        initialValues: state.recipient.exeProductionRequestModel
    })
)(ExeProductionDocRequestForm);

export default ExeProductionDocRequestForm;