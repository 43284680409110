import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';

import * as fileActions from '../../actions/file';
import DebtAmnesty2019Form from './debt-amnesty2019-form';

class PrintDebtAmnesty2019 extends Component {
    printDebtAmnesty2019 = values => {
        if (!values.importFile) {
            throw new SubmissionError({
                importFile: !values.importFile && 'Необходимо выбрать файл',
                _error: 'Ошибка импорта'
            })
        }

        this.props.fileActions.printDebtAmnesty2019(values.importFile);
    }

    render() {
        return (
            <DebtAmnesty2019Form
                onSubmit={this.printDebtAmnesty2019} />
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispathToProps(dispatch) {
    return {
        fileActions: bindActionCreators(fileActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(PrintDebtAmnesty2019);