import React from 'react'
import moment from 'moment'
import { moneyToRub, moneyToRubWithCurrency } from '../../../../utils/helper'
import {
  Button,
  UncontrolledTooltip,
} from 'reactstrap'

const RecipientDetailContractView = props => {
  const {
    item,
    editFunc,
  } = props

  return (
    <div>
      <Button
        color='link'
        className='float-right'
        onClick={editFunc}
        id='ContractEdit'
      >
        <i className='fa fa-pencil-square-o' aria-hidden='true'></i>
      </Button>
      <UncontrolledTooltip placement='top' target='ContractEdit'>
        Редактировать данные договора
      </UncontrolledTooltip>
      <dl className='row'>
        <dt className='col-sm-5'>{item.creditAgreementNumberDesc}</dt>
        <dd className='col-sm-7'>{item.creditAgreementNumber}</dd>

        <dt className='col-sm-5'>{item.creditTypeDesc}</dt>
        <dd className='col-sm-7'>{item.creditType}</dd>

        <dt className='col-sm-5'>{item.сessionAgreementNumberDesc}</dt>
        <dd className='col-sm-7'>{item.сessionAgreementNumber}</dd>

        <dt className='col-sm-5'>{item.loanBalanceStampDutyCurrentDesc}</dt>
        <dd className='col-sm-7'>
          {moneyToRubWithCurrency(item.loanBalanceStampDutyCurrent)}{' '}
          (изначальная - {moneyToRub(item.loanBalanceStampDutyInitial)})
        </dd>

        <dt className='col-sm-5'>{item.loanPercentSumCurrentDesc}</dt>
        <dd className='col-sm-7'>
          {moneyToRubWithCurrency(item.loanPercentSumCurrent)} (изначальная -{' '}
          {moneyToRub(item.loanPercentSumInitial)})
        </dd>

        <dt className='col-sm-5'>{item.loanSumCurrentDesc}</dt>
        <dd className='col-sm-7'>
          {moneyToRubWithCurrency(item.loanSumCurrent)} (изначальная -{' '}
          {moneyToRub(item.loanSumInitial)})
        </dd>

        <dt className='col-sm-5'>
          {item.loanBalancePenaltyApprovedCurrentDesc}
        </dt>
        <dd className='col-sm-7'>
          {moneyToRubWithCurrency(item.loanBalancePenaltyApprovedCurrent)}{' '}
          (изначальная - {moneyToRub(item.loanBalancePenaltyApprovedInitial)})
        </dd>

        <dt className='col-sm-5'>{item.loanBalanceTotalRURCurrentDesc}</dt>
        <dd className='col-sm-7'>
          {moneyToRubWithCurrency(item.loanBalanceTotalRURCurrent)} (изначальная
          - {moneyToRub(item.loanBalanceTotalRURInitial)})
        </dd>

        <dt className='col-sm-5'>{item.paidSumDesc}</dt>
        <dd className='col-sm-7'>
          {moneyToRubWithCurrency(item.paidSum)} 
        </dd>

        <dt className='col-sm-5'>{item.creditStartDateDesc}</dt>
        <dd className='col-sm-7'>
          {moment
            .utc(item.creditStartDate)
            .local()
            .format('DD.MM.YYYY')}
        </dd>

        <dt className='col-sm-5'>{item.creditEndDateDesc}</dt>
        <dd className='col-sm-7'>
          {moment
            .utc(item.creditEndDate)
            .local()
            .format('DD.MM.YYYY')}
        </dd>

        <dt className='col-sm-5'>{item.arrearCountDayDesc}</dt>
        <dd className='col-sm-7'>{item.arrearCountDay}</dd>
      </dl>
    </div>
  )
}

export default RecipientDetailContractView
