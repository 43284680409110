import React from 'react';

import ReportTemplateDetail from '../../../containers/report-template/detail/detail';

const ScreensReportTemplateDetail = (props) => {
    return (
        <ReportTemplateDetail reportTemplateId={props.match.params.id} />
    )
}

export default ScreensReportTemplateDetail;