import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Accounting from '../../containers/accounting'

const ScreensAccounting = () => {
  return (
    <Switch>
      <Route path='/accounting/tax' component={Accounting} />
    </Switch>
  )
}

export default ScreensAccounting
