import React from 'react'
import classnames from 'classnames'
import { Nav, NavItem, NavLink, Badge } from 'reactstrap'
import Aux from '../../../hoc/ReactAux/ReactAux'

import {
  PROFILE,
  CONTRACT,
  COURT_DECISION,
  EXE_PRODUCTION,
  DOCS,
  PLEDGE_ITEM,
} from '../../../constants/recipient'

const RecipientDetailNav = props => {
  const { creditorName, activeTab, toggle } = props

  return (
    <Aux>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === PROFILE })}
            onClick={() => {
              toggle(PROFILE)
            }}
          >
            Профиль
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === CONTRACT })}
            onClick={() => {
              toggle(CONTRACT)
            }}
          >
            Договор <Badge color='light'>{creditorName}</Badge>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === COURT_DECISION })}
            onClick={() => {
              toggle(COURT_DECISION)
            }}
          >
            Судебное дело
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === EXE_PRODUCTION })}
            onClick={() => {
              toggle(EXE_PRODUCTION)
            }}
          >
            Исполнительное производство
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === DOCS })}
            onClick={() => {
              toggle(DOCS)
            }}
          >
            Документы
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === PLEDGE_ITEM })}
            onClick={() => {
              toggle(PLEDGE_ITEM)
            }}
          >
            Объект залога
          </NavLink>
        </NavItem>
      </Nav>
    </Aux>
  )
}

export default RecipientDetailNav
