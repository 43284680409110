import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Aux from '../../../hoc/ReactAux/ReactAux';
import { Row } from 'reactstrap';

import ResipientsFilter from '../../../components/recipient/filter';
import RecipientPagination from '../../../components/common/pagination';

import * as lawCourtActions from '../../../actions/law-court';
import * as recipientActions from '../../../actions/recipient';
import * as contractActions from '../../../actions/recipient-contract';

class RecipientListSearch extends Component {
    constructor(props) {
        super(props);
        this.state = { collapse: true };
    }

    toggle = () => this.setState({ collapse: !this.state.collapse });

    componentDidMount() {
        this.props.contractActions.getStages();
        this.props.contractActions.getCessionAgreementNumbers();
    }

    submit = (values) => this.props.recipientActions.filterRecipient(values);

    changePage = (page) => this.submit({ ...this.props.filter, page });

    render() {
        const {
            recipientPaging,
            recipientStages,
            cessionAgreementNumbers,
            lawCourts,
            lawCourtActions } = this.props;

        return (
            <Aux>
                <Row>
                    <ResipientsFilter
                        onSubmit={this.submit}
                        toggle={this.toggle}
                        collapse={this.state.collapse}
                        recipientStages={recipientStages}
                        cessionAgreementNumbers={cessionAgreementNumbers}
                        lawCourts={lawCourts}
                        lawCourtSearchAutoFunc={lawCourtActions.searchAutoComplete}>
                    </ResipientsFilter>
                </Row>
                <RecipientPagination paging={recipientPaging}
                    changePage={this.changePage} />

                {this.props.children}

                <RecipientPagination paging={recipientPaging}
                    changePage={this.changePage} 
                    className='mt-2'/>
            </Aux>
        )
    }
}

function mapStateToProps(state) {
    return {
        recipients: state.recipient.list,
        filter: state.recipient.filter,
        recipientPaging: state.recipient.paging,
        recipientStages: state.recipient.stages,
        selectedCallNotify: state.callNotify.selected,
        cessionAgreementNumbers: state.recipient.cessionAgreementNumbers,
        lawCourts: state.lawCourt.recipientSearchList,
        selectedImportPayment: state.payment.import.selected,
    }
}

function mapDispathToProps(dispatch) {
    return {
        recipientActions: bindActionCreators(recipientActions, dispatch),
        lawCourtActions: bindActionCreators(lawCourtActions, dispatch),
        contractActions: bindActionCreators(contractActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(RecipientListSearch);