import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import Select from 'react-select'
import FormDatePicker from '../common/form-datepicker'
import { Row, FormGroup, Label, Col, Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'

import * as contractApi from '../../services/webapi/contract-api'
import * as okbFileApi from '../../services/webapi/okb-file-service'

const financeTypes = [
  { value: 0, label: 'PersonalLoan' },
  { value: 1, label: 'CreditCard' },
  { value: 2, label: 'Autocredit' },
  { value: 3, label: 'RealPropertyLoan' },
  { value: 4, label: 'None' },
]

const CreditBureauOkbExport = props => {
  const [cessionNumbers, setCessionNumbers] = useState([])

  useEffect(() => {
    contractApi
      .getCessionNumbers()
      .then(result => {
        setCessionNumbers(result.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  return (
    <div>
      <h3>ОКБ формирование файла</h3>
      <Formik
        onSubmit={values => {
          const searchQuery = {
            ...values,
            financeType: values.financeType && values.financeType.value,
            cessionAgreementNumber: values.cession && values.cession.value,
          }
          okbFileApi.exportFile(searchQuery).then(() => {
            props.history.push('/credit-bureau/okb')
          })
        }}
      >
        {props => {
          return (
            <Form>
              <Row>
                <Col xs='2'>
                  <FormGroup>
                    <Label>Дата отчетности</Label>
                    <FormDatePicker
                      input={{
                        onChange: date =>
                          props.setFieldValue('accountingDate', date),
                        value: props.values.accountingDate,
                      }}
                      name='accountingDate'
                      placeholder='Дата'
                    />
                  </FormGroup>
                </Col>

                <Col xs='2'>
                  <FormGroup>
                    <Label>Тип услуги</Label>
                    <Select
                      onChange={newValue => {
                        props.setFieldValue('financeType', newValue)
                      }}
                      options={financeTypes}
                      name='financeType'
                    />
                  </FormGroup>
                </Col>

                <Col xs='3'>
                  <FormGroup>
                    <Label>Цессия</Label>
                    <Select
                      onChange={newValue => {
                        props.setFieldValue('cession', newValue)
                      }}
                      defaultOptions
                      options={cessionNumbers}
                      name='cession'
                      placeholder='Цессия'
                      className='cession'
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs='7'>
                  <FormGroup className='pl-4'>
                    <Field
                      name='newVersion'
                      type='checkbox'
                      className='form-check-input'
                    />
                    <Label>
                      Формировать ли новый файл или заменить содержимое
                      существующего
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs='7' className='d-flex justify-content-end'>
                  <div className='mr-2'>
                    <Button
                      outline
                      color='secondary mr-2'
                      onClick={() => {
                        this.filterClear(() => {
                          props.handleReset()
                        })
                      }}
                    >
                      Очистить
                    </Button>
                    <Button type='submit' color='primary'>
                      Сформировать
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default withRouter(CreditBureauOkbExport)
