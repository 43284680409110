import axios from '../axios'

import {
  OKB_FILE_LIST_URL,
  OKB_EXPORT_FILE_URL,
  OKB_SET_EXPORT_SUCCESS_URL,
} from '../../constants/credit-buerau'

export const get = filter => axios.get(OKB_FILE_LIST_URL, filter)

export const exportFile = queryParams => axios.get(OKB_EXPORT_FILE_URL, { params: queryParams })

export const setExportSuccess = id => axios.post(OKB_SET_EXPORT_SUCCESS_URL + id);
