import React from 'react';
import { Button, Row } from 'reactstrap';

let ReportTemplateDetailEdit = props => {
    const { cancelFunc,
        item,
        addFileFunc,
        saveFunc } = props;

    return (
        <div>
            <dl className='row'>
                <dt className='col-sm-3 text-right align-middle'>Тип шаблона</dt>
                <dd className='col-sm-9'>
                    {item.reportType}
                </dd>
            </dl>
            <dl className='row'>
                <dt className='col-sm-3 text-right'>Файл</dt>
                <dd className='col-sm-9'>
                    {item.fileName}
                    <div>
                        <input type='file' name={'file'}
                            onChange={(event) => {
                                addFileFunc(event.target.files[0]);
                                event.target.value = '';
                                return;
                            }} />
                    </div>
                </dd>
            </dl>
            <Row className='justify-content-end'>
                <Button outline color='secondary' onClick={cancelFunc}>Отмена</Button>
                <Button color='primary' onClick={saveFunc} className='ml-2'>Сохранить</Button>
            </Row>
        </div>
    )
}

export default ReportTemplateDetailEdit;