import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import ScreensHome from './home/home'
import ScreensRecipient from './recipient/recipient'
import MainLayout from '../containers/main-layout'
import Accounting from './accounting'
import CallNotify from '../containers/call-notify'
import ScreensSettings from './settings/settings'
import Files from '../containers/files'
import ScreensCatalogs from './catalogs/catalogs'
import ScreensNotification from './Notification/Notification'
import ScreensPrint from './print/print'
import ScreensList from './document-flow'
import ScreensCreditBureau from './credit-bureau/index'
import ScreensPersonalInfoList from './personal-info/list';
import AuthPage from '../components/auth/page';

const ScreenRoot = () => {
  return (
    <MainLayout>
      <Switch>
        <Route path='/' exact component={ScreensHome} />
        <Route path='/login' component={AuthPage} />
        <Route path='/recipient' component={ScreensRecipient} />
        <Route path='/accounting' component={Accounting} />
        <Route path='/call-notify' component={CallNotify} />
        <Route path='/settings' component={ScreensSettings} />
        <Route path='/file' component={Files} />
        <Route path='/catalogs' component={ScreensCatalogs} />
        <Route path='/notification' component={ScreensNotification} />
        <Route path='/print' component={ScreensPrint} />
        <Route path='/document-flow' component={ScreensList} />
        <Route path='/credit-bureau' component={ScreensCreditBureau} />
        <Route path='/personal-info' component={ScreensPersonalInfoList} />
      </Switch>
    </MainLayout>
  )
}

export default withRouter(ScreenRoot)
