import axios from '../services/axios';
import {
    OK,
    FAIL,
    REQUEST,
    RECIPIENT_ACTIVITY,
    CREATE,
    CANCEL,
    RECIPIENT_ACTIVITY_TYPES,
    RECIPIENT_ACTIVITY_ADD_FILE,
    RECIPIENT_ACTIVITY_REMOVE_FILE,
    SAVE,
    EDIT,
    DELETE,
} from '../constants/legal';
import { uploadFile } from '../actions/file';

export function getAll(recipientId) {
    return dispatch => {
        dispatch({
            type: RECIPIENT_ACTIVITY + REQUEST
        });

        axios.get('/recipient/' + recipientId + '/activities')
            .then(result => {
                dispatch({
                    type: RECIPIENT_ACTIVITY + OK,
                    list: result.data
                })
            })
            .catch(result => {
                dispatch({
                    type: RECIPIENT_ACTIVITY + FAIL,
                    errors: result.message
                })
            });
    }
}

export function create(activityTypeId) {
    return dispatch => {
        dispatch({
            type: RECIPIENT_ACTIVITY + CREATE,
            activityTypeId
        })
    }
}

export function cancel() {
    return dispatch => {
        dispatch({
            type: RECIPIENT_ACTIVITY + CREATE + CANCEL
        })
    }
}

export function getAllActivityTypes() {
    return dispatch => {
        dispatch({
            type: RECIPIENT_ACTIVITY_TYPES + REQUEST
        });

        axios.get('/recipient-activity-types')
            .then(result => {
                dispatch({
                    type: RECIPIENT_ACTIVITY_TYPES + OK,
                    list: result.data
                })
            })
            .catch(result => {
                dispatch({
                    type: RECIPIENT_ACTIVITY_TYPES + FAIL,
                    errors: result.message
                })
            });
    }
}

export const editActivityAddFile = (fileData) => {
    return dispatch => {
        uploadFile(fileData)
            .then(result => {
                dispatch({
                    type: RECIPIENT_ACTIVITY_ADD_FILE,
                    payload: { name: fileData.name, fileID: result.data }
                });
            });
    }
}

export const editActivityRemoveFile = (fileIndex) => {
    return dispatch => {
        dispatch({ type: RECIPIENT_ACTIVITY_REMOVE_FILE, payload: fileIndex });
    }
}

export const save = value => {
    return dispatch => {
        dispatch({ type: RECIPIENT_ACTIVITY + SAVE + REQUEST })

        if (value.id) {
            axios.put('recipient/' + value.recipientId + '/activity/' + value.id, value)
                .then(result => {
                    dispatch({ type: RECIPIENT_ACTIVITY + EDIT + SAVE, payload: result.data })
                    dispatch(getAll(value.recipientId));
                })
        } else {
            axios.post('recipient/' + value.recipientId + '/activity', value)
                .then(result => {
                    dispatch({ type: RECIPIENT_ACTIVITY + SAVE, payload: result.data })
                })
        }
    }
}

export const edit = value => {
    return dispatch => {
        dispatch({ type: RECIPIENT_ACTIVITY + EDIT, payload: value })
    }
}

export const activityDelete = (recipientId, activityId) => {
    return dispatch => {
        dispatch({ type: RECIPIENT_ACTIVITY + DELETE + REQUEST });

        axios.delete('recipient/' + recipientId + '/activity/' + activityId)
            .then(() => {
                dispatch({
                    type: RECIPIENT_ACTIVITY + DELETE + OK,
                    payload: activityId
                });

                dispatch(getAll(recipientId));
            })
    }
}