import React, { Component } from 'react';
import { Collapse, ListGroup, Badge, Card, CardBody, CardHeader } from 'reactstrap';
import RecipientPaymentListItem from './list-item';

export default class ImportProcessPayments extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            collapse: {
                0: false,
                1: false,
                2: true,
                3: false,
                4: false
            }
        };
    }

    toggle(item) {
        let keys = Object.keys(this.state.collapse);
        let values = [];
        for (let i = 0; i < keys.length; i++) {
            values[i] = i === item && !this.state.collapse[i];
        }

        this.setState({
            collapse: values
        });
    }

    render() {
        const { importPayment: {
            otherTypeRecords,
            newRecords,
            existingRecords,
            errorRecords,
            notFoundRecipient
        },
            selectFunc } = this.props;

        const renderPayment = (payments, func) => {
            return payments.map((value, index) => {
                return (
                    <RecipientPaymentListItem item={value}
                        key={index}
                        editFunc={(func && typeof func === 'function') ? () => {
                            func(value);
                        } : null}
                        editButtonText='Обработать'>
                    </RecipientPaymentListItem>
                )
            });
        }

        return (
            <div>
                <Card color='light'>
                    <CardHeader tag='h5' onClick={() => {
                        this.toggle(0)
                    }}>Платёж обработан <Badge color="success" pill>{newRecords.length}</Badge></CardHeader>
                    <Collapse isOpen={this.state.collapse[0]}>
                        <CardBody>
                            <ListGroup>
                                {renderPayment(newRecords)}
                            </ListGroup>
                        </CardBody>
                    </Collapse>
                </Card>
                <Card color='light' className='mt-2'>
                    <CardHeader tag='h5' onClick={() => {
                        this.toggle(1)
                    }}>Платёж уже существует <Badge color="info" pill>{existingRecords.length}</Badge></CardHeader>
                    <Collapse isOpen={this.state.collapse[1]}>
                        <CardBody>
                            <ListGroup>
                                {renderPayment(existingRecords)}
                            </ListGroup>
                        </CardBody>
                    </Collapse>
                </Card>
                <Card color='light' className='mt-2'>
                    <CardHeader tag='h5' onClick={() => {
                        this.toggle(2)
                    }}>Не удалось найти договор, к которому необходимо привязать платёж <Badge color="warning" pill>{notFoundRecipient.length}</Badge></CardHeader>
                    <Collapse isOpen={this.state.collapse[2]}>
                        <CardBody>
                            <ListGroup>
                                {renderPayment(notFoundRecipient, selectFunc)}
                            </ListGroup>
                        </CardBody>
                    </Collapse>
                </Card>
                <Card color='light' className='mt-2'>
                    <CardHeader tag='h5' onClick={() => {
                        this.toggle(3)
                    }}>Ошибка обработки платежа <Badge color="danger" pill>{errorRecords.length}</Badge></CardHeader>
                    <Collapse isOpen={this.state.collapse[3]}>
                        <CardBody>
                            <ListGroup>
                                {renderPayment(errorRecords)}
                            </ListGroup>
                        </CardBody>
                    </Collapse>
                </Card>
                <Card color='light' className='mt-2'>
                    <CardHeader tag='h5' onClick={() => {
                        this.toggle(4)
                    }}>Платеж не является входящим <Badge pill>{otherTypeRecords.length}</Badge></CardHeader>
                    <Collapse isOpen={this.state.collapse[4]}>
                        <CardBody>
                            <ListGroup>
                                {renderPayment(otherTypeRecords)}
                            </ListGroup>
                        </CardBody>
                    </Collapse>
                </Card>
            </div>
        )
    }
}