import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik, Field, Form } from 'formik'
import { Table, Row, FormGroup, Label, Col, Button } from 'reactstrap'
import FormDatePicker from '../common/form-datepicker'
import AsyncCreatableSelect from 'react-select/async-creatable'
import Select from 'react-select'

import RecipientPagination from '../common/pagination'
import PersonInfoListItem from './list-item'

import * as personalInfoApi from '../../services/webapi/personal-info-api'
import * as contractApi from '../../services/webapi/contract-api'
import * as stageApi from '../../services/webapi/stage-api'
import * as stagePhaseApi from '../../services/webapi/stage-phase-api'
import * as personalInfoActions from '../../actions/personal-info'
import * as judicalInfoApi from '../../services/webapi/judical-info-api'
import * as lawCourtApi from '../../services/webapi/law-court-api'
import * as pledgeItemApi from '../../services/webapi/pledge-item-api'

import './list.css'

const Checkbox = props => <input type='checkbox' {...props} />;

class PersonInfoList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      totalCount: -1,
      datePickerfocused: false,
      cessionNumbers: [],
      stages: [],
      contractNumbers: [],
      stagePhases: [],
      judicalInfos: [],
      lawCourts: [],
      hasCourtOrderOpts: [{
        value: true, label: 'Да'
      }, {
        value: false, label: 'Нет'
      }],
      vins: [],
    }
  }

  componentDidMount() {
    this.search()
    this.loadCessionNumbers()
    this.loadStages()
    this.loadStagePhases()
  }

  search = () => {
    const filter = this.props.filter
    const searchQuery = {
      ...filter,
      contractNumber: filter.contractNumber && filter.contractNumber.value,
      stages: (filter.stages && filter.stages.map(item => item.value)) || [],
      cession: filter.cession && filter.cession.value,
      stagePhases:
        (filter.stagePhases && filter.stagePhases.map(item => item.value)) ||
        [],
      judicalInfo: filter.judicalInfo && filter.judicalInfo.value,
      lawCourt: filter.lawCourt && filter.lawCourt.value,
      hasCourtOrder: filter.hasCourtOrder && filter.hasCourtOrder.value,
      vin: filter.vin && filter.vin.value,
      hasActiveBankruptInfo: filter.hasActiveBankruptInfo,
      hasLimitExeProdRequest: filter.hasLimitExeProdRequest,
    }

    personalInfoApi.search(searchQuery).then(result => {
      this.setState({
        items: result.data.pageData,
        totalCount: result.data.totalCount,
      })
    })
  }

  loadContractNumbers = (inputValue, callback) => {
    contractApi
      .searchNumbers({
        query: inputValue,
      })
      .then(result => {
        const contractNumbers = result.data.map(value => {
          return { value: value, label: value }
        })

        this.setState({
          contractNumbers: contractNumbers,
        })
        callback(contractNumbers)
      })
      .catch(err => {
        console.log(err)
      })
  }

  loadJudicalInfos = (inputValue, callback) => {
    judicalInfoApi
      .search({
        query: inputValue,
      })
      .then(result => {
        const judicalInfos = result.data.map(value => {
          return { value: value.id, label: value.info }
        })

        this.setState({
          judicalInfos: judicalInfos,
        })

        callback(judicalInfos)
      })
      .catch(err => {
        console.log(err)
      })
  }

  loadlawCourts = (inputValue, callback) => {
    lawCourtApi
      .searchAutocomplete(inputValue)
      .then(result => {
        const lawCourts = result.data.map(value => {
          return { value: value.id, label: value.label }
        })

        this.setState({
          lawCourts: lawCourts,
        })

        callback(lawCourts)
      })
      .catch(err => {
        console.log(err)
      })
  }

  loadVins = (inputValue, callback) => {
    pledgeItemApi
      .searchAutocomplete(inputValue)
      .then(result => {
        const vins = result.data.map(value => {
          return { value: value.id, label: value.label }
        })

        this.setState({
          vins: vins,
        })

        callback(vins)
      })
      .catch(err => {
        console.log(err)
      })
  }

  loadStages = () => {
    stageApi
      .getAll()
      .then(result => {
        this.setState({
          stages: result.data.map(item => ({
            value: item.id,
            label: item.name,
          })),
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  loadCessionNumbers = () => {
    contractApi
      .getCessionNumbers()
      .then(result => {
        this.setState({ cessionNumbers: result.data })
      })
      .catch(err => {
        console.log(err)
      })
  }

  loadStagePhases = () => {
    stagePhaseApi
      .getAll()
      .then(result => {
        this.setState({
          stagePhases: result.data.map(item => ({
            value: item.id,
            label: item.name,
            recipientStage: item.stageId,
          })),
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  changePage = pageNumber => {
    this.props.personalInfoActions
      .setFilter({
        ...this.props.filter,
        pageNumber: pageNumber,
      })
      .then(this.search)
  }

  filterClear = callback => {
    this.props.personalInfoActions
      .clearFilter()
      .then(this.search)
      .then(() => {
        callback(this.props.filter)
      })
  }

  render() {
    const { filter, personalInfoActions } = this.props

    const propInfoItems = this.state.items.map(value => (
      <PersonInfoListItem key={value.id} item={value} />
    ))

    const paging = {
      count: this.state.totalCount,
      pageIndex: filter.pageNumber,
      pageSize: 25,
      pages: Math.ceil(this.state.totalCount / 25),
    }

    return (
      <div>
        <div>
          <Formik
            initialValues={filter}
            onSubmit={values => {
              personalInfoActions.setFilter({
                ...values,
                pageNumber: 1,
              })
              this.search()
            }}
          >
            {props => {
              return (
                <Form className='person-info__list_filter_form'>
                  <Row>
                    <Col xs='3'>
                      <FormGroup>
                        <Label>ФИО</Label>
                        <Field
                          name='fio'
                          type='text'
                          className='form-control'
                          placeholder='ФИО'
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='3'>
                      <FormGroup>
                        <Label>Паспорт</Label>
                        <Field
                          name='passport'
                          type='text'
                          className='form-control'
                          placeholder='Паспорт'
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='3'>
                      <FormGroup>
                        <Label>Телефон</Label>
                        <Field
                          name='phone'
                          type='text'
                          className='form-control'
                          placeholder='Номер телефона'
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='2'>
                      <FormGroup>
                        <Label>Дата рождения</Label>
                        <FormDatePicker
                          input={{
                            onChange: date =>
                              props.setFieldValue('birthday', date),
                            value: props.values.birthday,
                          }}
                          name='birthday'
                          placeholder='Дата'
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs='3'>
                      <FormGroup>
                        <Label>Цессия</Label>
                        <Select
                          onChange={newValue => {
                            personalInfoActions.setFilter({
                              ...filter,
                              cession: newValue,
                            })
                            props.setFieldValue('cession', newValue)
                          }}
                          defaultOptions
                          options={this.state.cessionNumbers}
                          name='cession'
                          placeholder='Цессия'
                          className='cession'
                          isClearable={true}
                          value={filter.cession}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='3'>
                      <FormGroup>
                        <Label>Договор</Label>
                        <AsyncCreatableSelect
                          cacheOptions
                          onChange={newValue => {
                            personalInfoActions.setFilter({
                              ...filter,
                              contractNumber: newValue,
                            })
                            return props.setFieldValue(
                              'contractNumber',
                              newValue
                            )
                          }}
                          defaultOptions
                          loadOptions={this.loadContractNumbers}
                          name='contractNumber'
                          placeholder='Номер договора'
                          className='selected-deal'
                          isClearable={true}
                          value={filter.contractNumber}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='3'>
                      <FormGroup>
                        <Label>Судебное дело</Label>
                        <AsyncCreatableSelect
                          cacheOptions
                          onChange={newValue => {
                            personalInfoActions.setFilter({
                              ...filter,
                              judicalInfo: newValue,
                            })
                            return props.setFieldValue('judicalInfo', newValue)
                          }}
                          defaultOptions
                          loadOptions={this.loadJudicalInfos}
                          name='judicalInfo'
                          placeholder='Судебное дело'
                          isClearable={true}
                          value={filter.judicalInfo}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='3'>
                      <FormGroup>
                        <Label>Суд</Label>
                        <AsyncCreatableSelect
                          cacheOptions
                          onChange={newValue => {
                            personalInfoActions.setFilter({
                              ...filter,
                              lawCourt: newValue,
                            })
                            return props.setFieldValue('lawCourt', newValue)
                          }}
                          defaultOptions
                          loadOptions={this.loadlawCourts}
                          name='lawCourt'
                          placeholder='Суд'
                          isClearable={true}
                          value={filter.lawCourt}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs='3'>
                      <FormGroup>
                        <Label>Стадия</Label>
                        <Select
                          onChange={newValue => {
                            personalInfoActions.setFilter({
                              ...filter,
                              stages: newValue || [],
                            })
                            props.setFieldValue('stages', newValue)
                          }}
                          defaultOptions
                          options={this.state.stages}
                          name='stages'
                          placeholder='Стадия'
                          className='stages'
                          isClearable
                          isMulti
                          value={filter.stages}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='3'>
                      <FormGroup>
                        <Label>Этап</Label>
                        <Select
                          onChange={newValue => {
                            personalInfoActions.setFilter({
                              ...filter,
                              stagePhases: newValue || [],
                            })
                            props.setFieldValue('stagePhases', newValue)
                          }}
                          defaultOptions
                          options={this.state.stagePhases.filter(value => {
                            if (!filter.stages || filter.stages.length === 0) {
                              return true
                            }

                            for (let i = 0; i < filter.stages.length; i++) {
                              if (
                                filter.stages[i].value === value.recipientStage
                              ) {
                                return true
                              }
                            }

                            return false
                          })}
                          name='stagePhases'
                          placeholder='Этап'
                          className='stagePhases'
                          isClearable
                          isMulti
                          value={filter.stagePhases}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='3'>
                      <FormGroup>
                        <Label>ИНН</Label>
                        <Field
                          name='inn'
                          type='text'
                          className='form-control'
                          placeholder='ИНН'
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='3'>
                      <FormGroup>
                        <Label>Наличие судебного приказа</Label>
                        <Select
                          onChange={newValue => {
                            personalInfoActions.setFilter({
                              ...filter,
                              hasCourtOrder: newValue || [],
                            })
                            props.setFieldValue('hasCourtOrder', newValue)
                          }}
                          defaultOptions
                          options={this.state.hasCourtOrderOpts}
                          name='hasCourtOrder'
                          placeholder='Наличие СП'
                          className='hasCourtOrder'
                          isClearable
                          value={filter.hasCourtOrder}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs='3'>
                      <FormGroup>
                        <Label>VIN</Label>
                        <AsyncCreatableSelect
                          cacheOptions
                          onChange={newValue => {
                            personalInfoActions.setFilter({
                              ...filter,
                              vin: newValue,
                            })
                            return props.setFieldValue('vin', newValue)
                          }}
                          defaultOptions
                          loadOptions={this.loadVins}
                          name='vin'
                          placeholder='VIN'
                          isClearable={true}
                          value={filter.vin}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs='3'>
                      <FormGroup className=''>
                        <Label check>
                          <Checkbox
                            className='mt-4 ml-2'
                            onChange={event => {
                              const newValue = event.currentTarget.checked;
                              personalInfoActions.setFilter({
                                ...filter,
                                hasActiveBankruptInfo: newValue,
                                hasLimitExeProdRequest: false,
                              })

                              return props.setFieldValue('hasActiveBankruptInfo', newValue)
                            }}
                            checked={filter.hasActiveBankruptInfo}
                            name='hasActiveBankruptInfo' />
                          Банкротство
                        </Label>

                        <Label check className='ml-2'>
                          <Checkbox
                            className='mt-4 ml-2'
                            onChange={event => {
                              const newValue = event.currentTarget.checked;
                              personalInfoActions.setFilter({
                                ...filter,
                                hasActiveBankruptInfo: false,
                                hasLimitExeProdRequest: newValue,
                              })

                              return props.setFieldValue('hasLimitExeProdRequest', newValue)
                            }}
                            checked={filter.hasLimitExeProdRequest}
                            name='hasLimitExeProdRequest' />
                          Срок ВИП
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col className='d-flex justify-content-end'>
                      <div className='mr-2 mt-4'>
                        <Button
                          outline
                          color='secondary mr-2'
                          onClick={() => {
                            this.filterClear(() => {
                              props.handleReset()
                            })
                          }}
                        >
                          Очистить
                        </Button>
                        <Button type='submit' color='primary'>
                          Найти
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </div>

        <br />

        <RecipientPagination paging={paging} changePage={this.changePage} />

        <Table>
          <thead>
            <tr>
              <th>Цедент</th>
              <th>№ договора Цессии</th>
              <th>ФИО должника</th>
              <th>Кредитный договор</th>
              <th>ОСЗ</th>
              <th>Взыскано</th>
              <th>Остаток задолженности</th>
              <th>Стадия</th>
              <th>Подстадия</th>
              <th>Наименование суда</th>
              <th>Наименование ОСП</th>
              <th>№ ИП</th>
              <th>Дата возбуждения ИП</th>
              <th>Дата окончания ИП</th>
            </tr>
          </thead>
          <tbody>{propInfoItems}</tbody>
        </Table>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    filter: state.personalInfo.listFilter,
  }
}

function mapDispathToProps(dispatch) {
  return {
    personalInfoActions: bindActionCreators(personalInfoActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispathToProps)(PersonInfoList)
