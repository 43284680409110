import React from 'react'

import RecipientListSearch from '../../containers/recipient/list/search'
import RecipientList from '../../components/recipient/list'
import RecipientListTop from '../../containers/recipient/list/top'

const ScreensRecipientList = () => {
  return (
    <div className='justify-content-between'>
      <RecipientListTop />

      <h2>Список клиентов</h2>

      <RecipientListSearch>
        <RecipientList />
      </RecipientListSearch>
    </div>
  )
}

export default ScreensRecipientList
