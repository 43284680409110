import axios from '../services/axios';
import {
    OK,
    FAIL,
    REQUEST,
    STAGE_PHASE,
    STAGE_PHASES,
    SAVE,
} from '../constants/legal';
import { error } from 'react-notification-system-redux';
import * as stagePhaseApi from '../services/webapi/stage-phase-api'

export function getStagePhases() {
    return dispatch => {
        dispatch({
            type: STAGE_PHASES + REQUEST
        });

        stagePhaseApi.getAll()
            .then(result => {
                dispatch({
                    type: STAGE_PHASES + OK,
                    payload: result.data,
                })
            })
            .catch(result => {
                dispatch({
                    type: STAGE_PHASES + FAIL,
                    errors: result.message
                })

                dispatch(
                    error({
                        title: 'Stage phase',
                        message: result.response.data.error || result.message
                    }));
            });
    }
}

export function update(recipientId, stagePhaseId) {
    return dispatch => {
        dispatch({
            type: STAGE_PHASE + SAVE + REQUEST
        });

        axios.put('/recipient/' + recipientId + '/stage-phase/' + stagePhaseId)
            .then(result => {
                dispatch({
                    type: STAGE_PHASE + SAVE + OK,
                    payload: result.data
                })
            })
            .catch(result => {
                dispatch({
                    type: STAGE_PHASE + SAVE + FAIL,
                    errors: result.message
                })
                dispatch(
                    error({
                        title: 'Ошибка обновления этапа стадии',
                        message: result.response.data.error || result.message
                    }));
            });
    }
}