import React from 'react';
import { ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';

const LawCourtListItem = (props) => {
    const { item,
        selectFunc,
        active } = props;

    return (
        <ListGroupItem onClick={() => selectFunc(item.id)} active={active}>
            <ListGroupItemHeading>
                {item.nameTo}
            </ListGroupItemHeading>
            <ListGroupItemText>
                {item.addressIndex ? ', ' + item.addressIndex : null};
                {item.address}
                <br />
                <span>Телефон: {item.phones}</span>
            </ListGroupItemText>
        </ListGroupItem>
    )
}

export default LawCourtListItem;