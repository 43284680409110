import React from 'react';
import { ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const ReportTemplateListItem = (props) => {
    return (
        <Link to={'/settings/report-template/' + props.item.id}>
            <ListGroupItem>
                {props.item.reportType}
            </ListGroupItem>
        </Link>
    )
}

export default ReportTemplateListItem;