import axios from 'axios';
import { TOKEN_KEY } from '../constants/legal';
import { NOTIFICATION_HOST } from '../constants/notification';

var instance = axios.create({
    baseURL: NOTIFICATION_HOST
});

if (localStorage.getItem(TOKEN_KEY)) {
    // Alter defaults after instance has been created
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(TOKEN_KEY);
}

export default instance;