import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  FormGroup,
  Label,
} from 'reactstrap'
import { Formik, Form, Field } from 'formik'

import * as pledgeItemStatusApi from '../../services/webapi/pledge-item-status-api'

const PledgeItemEditForm = props => {
  let { item } = props

  const [pledgeItemStatuses, setPledgeItemStatuses] = useState([]);

  useEffect(() => {
    pledgeItemStatusApi
      .getAll()
      .then(result => {
        setPledgeItemStatuses(result.data);
      })
      .catch(err => {
        console.log(err)
      })
  }, []);

  if (!item) {
    item = {
      brand: '',
      model: '',
      yearOfIssue: '',
      vin: '',
      color: '',
      regNumber: '',
    }
  }

  return (
    <Card className='mb-2'>
      <CardHeader tag='h5'>
        {item.id ? 'Сохранить' : 'Создать'} объект залога
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={item}
          onSubmit={values => {
            props.saveOrUpdateFunc(values)
          }}
          render={() => (
            <Form>
              <Field name='id' type='hidden' />
              <Field name='recipientId' type='hidden' />

              <FormGroup>
                <Label>Марка</Label>
                <Field name='brand' type='text' className='form-control' />
              </FormGroup>

              <FormGroup>
                <Label>Модель</Label>
                <Field name='model' type='text' className='form-control' />
              </FormGroup>

              <FormGroup>
                <Label>Год выпуска</Label>
                <Field
                  name='yearOfIssue'
                  type='number'
                  className='form-control'
                />
              </FormGroup>

              <FormGroup>
                <Label>VIN</Label>
                <Field name='vin' type='text' className='form-control' />
              </FormGroup>

              <FormGroup>
                <Label>Цвет</Label>
                <Field name='color' type='text' className='form-control' />
              </FormGroup>

              <FormGroup>
                <Label>Регистрационный номер</Label>
                <Field name='regNumber' type='text' className='form-control' />
              </FormGroup>

              <FormGroup>
                <Label>Статус</Label>
                <Field
                    name='statusId'
                    component='select'
                    className='form-control form-control-sm'
                  >
                    {pledgeItemStatuses &&
                      pledgeItemStatuses.map(value => {
                        return (
                          <option value={value.id} key={value.id}>
                            {value.name}
                          </option>
                        )
                      })}
                  </Field>
              </FormGroup>

              <Row>
                <div className='float-right'>
                  <Button outline color='secondary' onClick={props.cancelFunc}>
                    Отмена
                  </Button>
                  <Button type='submit' color='primary' className='ml-2'>
                    Сохранить
                  </Button>
                </div>
              </Row>
            </Form>
          )}
        />
      </CardBody>
    </Card>
  )
}

export default PledgeItemEditForm
