import React, { useState, useEffect } from 'react'
import { Badge, Button } from 'reactstrap'
import Aux from '../../hoc/ReactAux/ReactAux'

import { LEGAL_DOWNLOAD_FILE } from '../../constants/legal'

import * as okbFileService from '../../services/webapi/okb-file-service'
import * as fileActions from '../../actions/file'
import { dateToString } from '../../utils/helper'

const CreditBureauOKB = () => {

  const [okbFiles, setOkbFiles] = useState([]);

  useEffect(() => {
    okbFileService
      .get()
      .then(result => {
        setOkbFiles(result.data)
      })
      .catch(err => {
        console.log('okbFileService.get', err)
      });
  }, [])

  const fileItems = okbFiles.map(value => (
    <li
      key={value.id}
      className='mb-2'
    >
      {value.fileName} ({value.financeType}) от{' '}
      {dateToString(value.accountingDate)}
      <span className='ml-2'>
        {value.isExportSuccess ?
          <Badge color='success'>Обработано</Badge> :
          <Aux>
            <Button outline color='success' size='sm'
              onClick={() => {
                const item = value;
                okbFileService
                  .setExportSuccess(item.id)
                  .then(() => {
                    const files = okbFiles.map(file => {
                      if (file.id === item.id){
                        file.isExportSuccess = true;
                      }

                      return file;
                    })
                    
                    setOkbFiles(files)
                  })
                  .catch(err => {
                    console.log('okbFileService.setExportSuccess', err)
                  });
              }}>Обработать</Button>
            {/* <Button outline color='danger' size='sm' className='ml-2'>Удалить</Button> */}
          </Aux>}
      </span>
      <Button color='link' size='sm'
        onClick={() => {
          fileActions.download(LEGAL_DOWNLOAD_FILE + value.fileID)
        }}>Скачать CDS</Button>
      <Button color='link' size='sm'
        onClick={() => {
          fileActions.download(LEGAL_DOWNLOAD_FILE + value.reportFileID)
        }}>Скачать Json</Button>
    </li>
  ))

  return (
    <div>
      <h6>OKB</h6>
      <ul>{fileItems}</ul>
    </div>
  )
}

export default CreditBureauOKB
