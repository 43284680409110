import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Aux from '../../hoc/ReactAux/ReactAux'
import { LEGAL_APP_V2 } from '../../constants/legal'

import * as homeApi from '../../services/webapi/home-api'

const HomeTasksTotal = () => {

  const openTasksClick = () => {
    window.location.href = LEGAL_APP_V2 + '/task/list'
  }

  const [tasksTotal, setTasksTotal] = useState(0);

  useEffect(() => {
    homeApi.getTaskManagerTasksCurrentCount()
      .then(result => {
        setTasksTotal(result.data.total)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  return (
    <Aux>
      <Col
        sm='5'
        xs='11'
        xl='3'
        onClick={openTasksClick}
        className='p-3 text-white m-3 btn btn-primary'
      >
        <h3>Задачи</h3>
        <Row>
          <Col xs='5'>
            <i className='fa fa-tasks fa-3x pt-2' aria-hidden='true' />
          </Col>
          <Col xs='7' className='display-4 font-weight-bold'>
            {tasksTotal}
          </Col>
        </Row>
      </Col>
    </Aux>
  )
}

export default HomeTasksTotal
