import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListGroup, ListGroupItem, ListGroupItemHeading, Row, Col, FormGroup } from 'reactstrap';

import { moneyToRubWithCurrency, dateToString } from '../../utils/helper';

import * as notificationExeProdActions from '../../actions/notification/exe-production';

const FillColorItem = (value, index) => {
    if (value && !value.Users) {
        return 'primary';
    }

    if (index % 2) {
        return 'secondary'
    }

    return '';
}

class NotificationList extends Component {
    componentDidMount() {
        this.props.notificationExeProdActions.getAll();
    }

    setReadMessage = (messageId) => {
        this.props.notificationExeProdActions.setReadMessage(messageId, this.props.currentUser);
    }

    render() {
        const { list } = this.props;

        const renderList = list && list.map((item, index) => {
            return (
                <ListGroupItem key={item.NotificationID} color={FillColorItem(item, index)}>
                    <ListGroupItemHeading>
                        <Row>
                            <Col md='8' sm='12'>
                                {item.ExeProductionNumber} от&nbsp;{dateToString(item.ExeProductionDate)}
                            </Col>
                            <Col md='4' sm='12'>
                                {moneyToRubWithCurrency(item.Sum)}
                            </Col>
                        </Row>

                        <Link to={'/recipient/' + item.RecipientID} onClick={() => this.setReadMessage(item.NotificationID)}>{item.RecipientName}</Link>
                    </ListGroupItemHeading>
                </ListGroupItem>
            )
        })

        return (
            <div className='mb-2'>
                <FormGroup>
                    {list && list.length > 0 ?
                        <ListGroup>
                            {renderList}
                        </ListGroup> :
                        <Row className='pl-3'>Данные отсутствуют</Row>
                    }
                </FormGroup>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        list: state.notificationExeProd.list,
        currentUser: state.user.currentUser,
    }
}

function mapDispathToProps(dispatch) {
    return {
        notificationExeProdActions: bindActionCreators(notificationExeProdActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(NotificationList);