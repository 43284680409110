import axios from '../services/axios';
import {
    OK,
    FAIL,
    REQUEST,
    ACCOUNTING_TAX_FILTER,
} from '../constants/legal';

// export function getAll() {
//     return dispatch => {
//         dispatch({
//             type: ACCOUNTING_TAX + REQUEST
//         });

//         axios.get('/tax')
//             .then(result => {
//                 dispatch({
//                     type: ACCOUNTING_TAX + OK,
//                     payload: result.data.payments,
//                     total: result.data.total
//                 })
//             })
//             .catch(result => {
//                 dispatch({
//                     type: ACCOUNTING_TAX + FAIL,
//                     errors: result.message
//                 })

//                 dispatch(
//                     error({
//                         title: 'Accounting tax',
//                         message: result.message
//                     }));
//             });
//     }
// }

export function filterTax(values) {
    return dispatch => {
        dispatch({
            type: ACCOUNTING_TAX_FILTER + REQUEST
        })

        axios.post('/tax/search', values)
            .then(result => {
                dispatch({
                    type: ACCOUNTING_TAX_FILTER + OK,
                    payload: result.data.payments,
                    total: result.data.total,
                    filter: values
                })
            })
            .catch(result => {
                dispatch({
                    type: ACCOUNTING_TAX_FILTER + FAIL,
                    errors: result.message
                })
            });
    }
}