import instance from '../services/axios';
import {
    OK,
    FAIL,
    REQUEST,
    RECIPIENT_EXECUTING_PRODUCTION,
    IMPORT,
    SAVE,
    CANCEL,
    RECIPIENT_EXECUTING_PRODUCTION_SHOW_OFFLINE_LIST,
    DELETE
} from '../constants/legal';
import { info, error } from 'react-notification-system-redux';

export function getAll(recipientId) {
    return dispatch => {
        dispatch({
            type: RECIPIENT_EXECUTING_PRODUCTION + REQUEST
        });

        instance.get('/recipient/' + recipientId + '/exeproductions')
            .then(result => {
                dispatch({
                    type: RECIPIENT_EXECUTING_PRODUCTION + OK,
                    payload: result.data
                })
            })
            .catch(result => {
                dispatch({
                    type: RECIPIENT_EXECUTING_PRODUCTION + FAIL,
                    errors: result.message
                })
            });
    }
}

export function importFromSite(recipientId, isOffline) {
    return dispatch => {
        dispatch({
            type: RECIPIENT_EXECUTING_PRODUCTION + IMPORT + REQUEST
        });

        if (!isOffline) {
            dispatch(
                info({
                    title: 'ФССП',
                    message: 'Запрос выполняется'
                }));
        }

        let isOnlineParam = '';
        if (isOffline) {
            isOnlineParam = '?online=false'
        }

        instance.get('/recipient/' + recipientId + '/exeproductions/import' + isOnlineParam)
            .then(result => {
                dispatch({
                    type: RECIPIENT_EXECUTING_PRODUCTION + IMPORT + OK,
                    payload: result.data,
                    isOffline: isOffline
                });

                if (!isOffline) {
                    dispatch(
                        info({
                            title: 'ФССП',
                            message: 'Запрос выполнился'
                        }));
                }
            })
            .catch(result => {
                dispatch({
                    type: RECIPIENT_EXECUTING_PRODUCTION + IMPORT + FAIL,
                    payload: result
                });

                dispatch(
                    error({
                        title: 'ФССП',
                        message: result.response.data.error || result.message
                    }));
            });
    }
}

export function importFromSiteCancel() {
    return dispatch => {
        dispatch({
            type: RECIPIENT_EXECUTING_PRODUCTION + IMPORT + CANCEL
        });
    }
}

export function add(values) {
    return dispatch => {
        dispatch({
            type: RECIPIENT_EXECUTING_PRODUCTION + SAVE + REQUEST
        });

        dispatch(
            info({
                title: 'Исполнительное производство',
                message: 'Запрос добавления записи'
            }));

        instance.post('/recipient/' + values.recipientId + '/exeproduction', values)
            .then(result => {
                dispatch({
                    type: RECIPIENT_EXECUTING_PRODUCTION + SAVE + OK,
                    payload: result.data
                });

                dispatch(
                    info({
                        title: 'Исполнительное производство',
                        message: 'Запись добавлена'
                    }));
            })
            .catch(result => {
                dispatch({
                    type: RECIPIENT_EXECUTING_PRODUCTION + SAVE + FAIL,
                    payload: result
                });

                dispatch(
                    error({
                        title: 'Исполнительное производство',
                        message: result.response.data.error || result.message
                    }));
            })
    }
}

export const setShowOffline = value => {
    return dispatch => {
        dispatch({
            type: RECIPIENT_EXECUTING_PRODUCTION_SHOW_OFFLINE_LIST,
            payload: value
        });
    }
}

export const remove = values => {
    return dispatch => {
        dispatch({
            type: RECIPIENT_EXECUTING_PRODUCTION + DELETE
        });

        instance.delete('/recipient/' + values.recipientId + '/exeproduction?exeproductionid=' + values.exeProductionId)
            .then(() => {
                dispatch({
                    type: RECIPIENT_EXECUTING_PRODUCTION + DELETE + OK,
                    payload: values.exeProductionId
                });

                dispatch(
                    info({
                        title: 'Исполнительное производство',
                        message: 'Запись удалена'
                    }));
            })
            .catch((result) => {
                dispatch({
                    type: RECIPIENT_EXECUTING_PRODUCTION + DELETE + FAIL,
                    payload: result
                });

                dispatch(
                    error({
                        title: 'Исполнительное производство',
                        message: result.response.data.error || result.message
                    }));
            })
    }
}