import React from 'react'
import { Link } from 'react-router-dom'
import * as fileActions from '../../actions/file'

import { dateToString } from '../../utils/helper'
import { LEGAL_DOWNLOAD_FILE } from '../../constants/legal'

const DocumentListOutgoingItem = (props) => {
  const {
    item,
    editItemFunc } = props;

  return (
    <tr onClick={() => { editItemFunc(item.id) }}>
      <td className='list-outgoing__number'>{item.regNumber}</td>
      <td className='list-outgoing__date'>{dateToString(item.regDate)}</td>
      <td className='list-outgoing__destination'>{item.destination}</td>{/* Кому */}
      <td className='list-outgoing__commentary'>{item.commentary}</td>
      <td className='list-outgoing__type'></td>{/*  Вид отправки*/}
      <td className='list-outgoing__worker-name'>{item.workerName}</td>
      <td className='list-outgoing__file'>
        <a href='#' className='link' onClick={e => {
          e.stopPropagation();
          fileActions.download(LEGAL_DOWNLOAD_FILE + item.fileId);
        }}>{item.fileName}</a></td>
      <td className='list-outgoing__deal'><Link to={'/recipient/' + item.dealId}>{item.lastName} {item.firstName} {item.middleName}</Link></td>
      {/* <td><Link to={'/docs/' + props.item.id}>{props.item.fileName}</Link></td> */}
    </tr>
  )
}

export default DocumentListOutgoingItem;