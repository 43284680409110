import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListGroup } from 'reactstrap';

import * as reportTemplateActions from '../../../actions/report-template/report-template';

import ReportTemplateListItem from './list-item';

class ReportTemplateList extends Component {
    componentDidMount() {
        this.props.reportTemplateActions.getAll();
    }

    render() {
        const { list } = this.props;

        const renderList = list.map(item => {
            return <ReportTemplateListItem key={item.id}
                item={item} />
        })

        return (
            <ListGroup>
                {renderList}
            </ListGroup>
        )
    }
}

function mapStateToProps(state) {
    return {
        list: state.reportTemplate.list,
    }
}

function mapDispathToProps(dispatch) {
    return {
        reportTemplateActions: bindActionCreators(reportTemplateActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(ReportTemplateList);