import React from 'react';
import { Row } from 'reactstrap';

import HomeRecipientTotal from '../../components/home/recipient-total';
import HomeBankruptTotal from '../../components/home/bankrupt-total';
import LimitExeProdRequestTotal from '../../components/home/limit-exeprodrequest-total';
// import HomeExeProdUnreadTotal from '../../components/home/ExeProdUnreadTotal';
import HomeTasksTotal from '../../components/home/tasks-total'

const ScreensHome = () => {
    return (
        <div>
            <h1>Главная</h1>
            <Row>
                <HomeRecipientTotal />
                {/* <HomeExeProdUnreadTotal /> */}
                <HomeBankruptTotal />
                <LimitExeProdRequestTotal />
            </Row>
            <Row>
                <HomeTasksTotal />
            </Row>
        </div >
    )
}

export default ScreensHome;