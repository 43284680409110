import {
    FILES_LOST,
    OK,
    FILES_ATTACH,
    FILES_REMOVE,
    FILES_LOST_SAVE
} from '../constants/legal';

const initialState = {
    list: []
}

let tempList = null;

export const file = (state = initialState, action = {}) => {
    switch (action.type) {
        case FILES_LOST:
            return { ...state };
        case FILES_LOST + OK:
            return { ...state, list: action.list }

        case FILES_ATTACH:
            tempList = state.list.map(item => {
                if (item.id === action.fileID) {
                    item.fileData = action.fileData;
                }

                return item;
            });

            return { ...state, list: tempList }

        case FILES_REMOVE:
            tempList = state.list.map(item => {
                if (item.id === action.fileID) {
                    item.fileData = null;
                }

                return item;
            });

            return { ...state, list: tempList }

        case FILES_LOST_SAVE:
            tempList = state.list.filter((item) => !item.fileData);

            return { ...state, list: tempList }

        default:
            return state;
    }
}