import React from 'react';
import { Component } from 'react';

export default class Footer extends Component {
    render() {
        return (
            <div>
                <hr />
                <footer>
                    <p>&copy; 2017 - Legal Plus</p>
                </footer>
            </div>
        );
    }
}