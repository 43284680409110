import {
    OK,
    CALL_NOTIFY,
    SELECT,
    CANCEL,
    SAVE,
} from '../constants/legal';

const initialState = {
    list: [],
    selected: null,
};

export const callNotify = (state = initialState, action = {}) => {
    switch (action.type) {
        case CALL_NOTIFY + OK:
            return {
                ...state,
                list: action.payload
            }

        case CALL_NOTIFY + SELECT + CANCEL:
            return {
                ...state,
                selected: null
            }

        case CALL_NOTIFY + SELECT + SAVE:
            return {
                ...state,
                selected: null
            }

        case CALL_NOTIFY + SELECT:
            return {
                ...state,
                selected: action.payload
            }

        default:
            return state;
    }
}