import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, CustomInput } from 'reactstrap'
import { Formik, Field, Form } from 'formik'
import Select from 'react-select'
import * as Yup from 'yup'
import * as helper from '../../utils/helper'
//import FormDatePicker from '../common/form-datepicker'
import * as fileActions from '../../actions/file'
import * as documentFlowApi from '../../services/webapi/document-flow-api';
import * as documentTypeApi from '../../services/webapi/document-type-api'

import './edit-form.css'

const DocumentEditSchema = Yup.object().shape({
  documentType: Yup.object({
    value: Yup.string().required('Тип документа должен быть выбран'),
  }).nullable().required('Тип документа должен быть выбран'),
  file: Yup.object({
    id: Yup.string().required('Файл должен быть загружен')
  }).nullable().required('Файл должен быть загружен'),
})

const DocumentEditForm = props => {
  const defaultDocument = {
    isIncoming: props.isIncoming,
    destination: '',
    commentary: '',
  };

  const [documentTypes, setDocumentTypes] = useState([]);
  const [editItem, setEditItem] = useState(defaultDocument);
  const [editItemId, setEditItemId] = useState();

  if (editItemId !== props.itemId) {
    setEditItemId(props.itemId)
  }

  useEffect(() => {
    documentTypeApi.getAll()
      .then(result => {
        setDocumentTypes(result.data.map(item => {
          return {
            value: item.id,
            label: item.name,
          }
        }));
      })
      .catch(err => {
        console.log(err);
      })
  }, []);

  useEffect(() => {
    if (editItemId) {
      documentFlowApi.get(editItemId)
        .then(result => {
          const doc = result.data;
          let item = {
            id: doc.id,
            isIncoming: doc.isIncoming,
            name: doc.name,
            commentary: doc.commentary || '',
            destination: doc.destination || '',
            documentType: {
              value: doc.documentType,
            },
            regNumber: doc.regNumber,
            regDate: doc.regDate,
            dealId: doc.dealId,
          };

          if (doc.fileId) {
            item.file = {
              id: doc.fileId,
              name: doc.fileName,
            }
          }

          setEditItem(item);
        })
        .catch(err => {
          console.log(err);
        })
    } else {
      setEditItem(defaultDocument)
    }
  }, [editItemId]);

  return (
    <Formik
      initialValues={editItem}
      onSubmit={(values, { resetForm }) => {
        documentFlowApi.saveOrUpdate({
          ...values,
          fileId: values.file && values.file.id,
          file: undefined,
          documentTypeId: values.documentType && values.documentType.value,
          documentType: undefined,
          isIncoming: values.isIncoming,
        })
          .then(result => {
            console.log(result);
            resetForm();
            props.toggle(true);
          }).catch(err => {
            console.log(err);
          });
      }}
      enableReinitialize={true}
      validationSchema={DocumentEditSchema}>
      {(fprops) => {
        const { errors } = fprops;

        return (
          <Form className='document-flow_form' noValidate>
            <Modal isOpen toggle={props.toggle} container='.document-flow_form'>
              <ModalHeader toggle={props.toggle}>{editItem.id ?
                ('№' + (editItem.regNumber || '') + ' от ' + (helper.dateToString(editItem.regDate) || '')) :
                'Добавить документ'
              }{editItem.dealId ?
                <Link className='ml-2' to={'/recipient/' + editItem.dealId}>открыть договор</Link> :
                null}</ModalHeader>
              <ModalBody className='pt-0'>
                <Row className='pt-3'>
                  <Col>
                    <CustomInput
                      type='switch'
                      id='isIncoming'
                      name='isIncoming'
                      label={fprops.values.isIncoming ? 'Входящий документ' : 'Отправленный документ'}
                      onChange={event => {
                        const newValue = event.currentTarget.checked;
                        return fprops.setFieldValue('isIncoming', newValue)
                      }}
                      checked={fprops.values.isIncoming} />
                  </Col>
                </Row>

                <Row className='pt-3'>
                  <Col>
                    <fieldset>
                      <legend>{fprops.values.isIncoming ? 'От кого поступил документ' : 'Кому'}</legend>
                      <Field
                        name='destination'
                        component='textarea'
                        className='form-control' />
                    </fieldset>
                  </Col>
                </Row>

                <Row className='pt-3'>
                  <Col>
                    <fieldset>
                      <Select
                        onChange={newValue => {
                          fprops.setFieldValue('documentType', newValue)
                        }}
                        defaultOptions
                        options={documentTypes}
                        name='documentType'
                        placeholder=''
                        isClearable={true}
                        value={documentTypes.filter(option => option.value === (fprops.values.documentType && fprops.values.documentType.value))}
                        className={errors.documentType ? 'documentType__is-invalid' : null}
                      />
                      <legend>Тип документа</legend>
                    </fieldset>
                    {errors.documentType ? (
                      <div className='text-danger'><small>{errors.documentType.value}</small></div>
                    ) : null}
                  </Col>
                </Row>

                <Row className='pt-3'>
                  <Col>
                    <fieldset>
                      <legend>{fprops.values.isIncoming ? 'Краткое содержание' : 'Цель'}</legend>
                      <Field
                        name='commentary'
                        component='textarea'
                        className='form-control' />
                    </fieldset>
                  </Col>
                </Row>

                <Row className='pt-3'>
                  <Col>
                    <fieldset>
                      <legend>Файл</legend>
                      {fprops.values.file
                        ? <div className='height-auto form-control'>{fprops.values.file.name}
                          {editItem.dealId ?
                            null :
                            <Button color='link' onClick={() =>
                              fprops.setFieldValue('file', undefined)}>
                              <i className='fa fa-trash' aria-hidden='true'></i>
                            </Button>}
                        </div>
                        :
                        <Input type='file' name='file' className='form-control height-auto'
                          onChange={(event) => {
                            let eventFile = event.currentTarget.files[0];

                            fileActions.uploadFile(eventFile)
                              .then(result => {
                                {
                                  fprops.setFieldValue('file', {
                                    id: result.data,
                                    name: eventFile.name,
                                  });

                                  eventFile = '';
                                }
                              })
                              .catch(err => {
                                console.log(err);
                              })
                          }}
                          invalid={errors.file} />
                      }
                    </fieldset>
                    {errors.file ? (
                      <div className='text-danger'><small>{errors.file.id}</small></div>
                    ) : null}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color='secondary' outline onClick={props.toggle}>Отмена</Button>
                <Button color='primary' type='submit' className='ml-2'>Сохранить</Button>
              </ModalFooter>
            </Modal>
          </Form>
        )
      }}
    </Formik >
  )
}

export default DocumentEditForm;