import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Aux from '../../../hoc/ReactAux/ReactAux'

import ActivityAddDropdown from '../../activity/add-dropdown'
import PaymentAddDropdown from '../../payment/add-button'
import CourtDecisionAddDropdown from '../../court-decision/add-button'
import ExeProductionImportDropdown from '../../executing-productions/import-button'

import * as activityActions from '../../../actions/recipient-activity'
import * as paymentActions from '../../../actions/recipient-payment'
import * as courtDecisionActions from '../../../actions/recipient-court-decision'
import * as executingProductionActions from '../../../actions/recipient-executing-production'

import {
  PROFILE,
  CONTRACT,
  COURT_DECISION,
  EXE_PRODUCTION,
  PLEDGE_ITEM,
} from '../../../constants/recipient'

class RecipientDetailDropdown extends Component {
  importExecutingProductions = () =>
    this.props.executingProductionActions.importFromSite(this.props.recipientId)

  render() {
    const {
      activityTypes,
      editPayment,
      editCourtDecision,
      importExeProd,
      activityActions,
      paymentActions,
      courtDecisionActions,
      executingProductionActions,
      activeTab,
      plegeItemAddButton,
    } = this.props

    const dropdownRender = () => {
      switch (activeTab) {
        case PROFILE:
          return (
            <ActivityAddDropdown
              activityCreateFunc={activityActions.create}
              getAllActivityTypes={activityActions.getAllActivityTypes}
              activityTypes={activityTypes}
            />
          )
        case CONTRACT:
          return (
            <PaymentAddDropdown
              paymentCreate={paymentActions.create}
              buttonDisable={editPayment.formEnable}
            />
          )
        case COURT_DECISION:
          return (
            <CourtDecisionAddDropdown
              courtDecisionCreate={courtDecisionActions.create}
              buttonDisable={editCourtDecision.formEnable}
            />
          )
        case EXE_PRODUCTION:
          return (
            <ExeProductionImportDropdown
              mainAction={this.importExecutingProductions}
              cancelAction={executingProductionActions.importFromSiteCancel}
              buttonDisable={importExeProd.formEnable && !importExeProd.offline}
            />
          )
        case PLEDGE_ITEM:
          return (plegeItemAddButton)
        default:
          return null
      }
    }

    return <Aux>{dropdownRender()}</Aux>
  }
}

function mapStateToProps(state) {
  return {
    editActivity: state.activity.editActivity,
    activityTypes: state.activity.activityTypes,
    editPayment: state.payment.editPayment,
    editCourtDecision: state.recipient.editCourtDecision,
    importExeProd: state.recipient.importExeProd,
  }
}

function mapDispathToProps(dispatch) {
  return {
    activityActions: bindActionCreators(activityActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    courtDecisionActions: bindActionCreators(courtDecisionActions, dispatch),
    executingProductionActions: bindActionCreators(
      executingProductionActions,
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispathToProps
)(RecipientDetailDropdown)
