import React, { Component } from 'react'
import Select from 'react-select'

export default class FormSelect extends Component {
  state = {
    selectedOption: '',
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption })
    console.log(`Selected: ${selectedOption.label}`)
  }

  render() {
    const {
      input: { name, value, onBlur, onChange, onFocus },
      options,
      multi,
      placeholder,
    } = this.props
    const transformedValue = transformValue(value, options, multi)

    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: '31px',
        'minHeight': '31px',
        'fontSize': '14px',
      }),
    }

    return (
      <Select
        valueKey='value'
        name={name}
        value={transformedValue}
        multi={multi}
        options={options}
        onChange={
          multi ? multiChangeHandler(onChange) : singleChangeHandler(onChange)
        }
        onBlur={() => onBlur(value)}
        onFocus={onFocus}
        placeholder={placeholder}
        styles={customStyles}
        isClearable
      />
    )
  }
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : '')
  }
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map(value => value.value))
  }
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
  if (multi && typeof value === 'string') return []

  const filteredOptions = options.filter(option => {
    return multi ? value.indexOf(option.value) !== -1 : option.value === value
  })

  return multi ? filteredOptions : filteredOptions[0]
}
