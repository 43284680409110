import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ScreensReportTemplate from './report-template/report-template';
import ScreensSettingsMain from './main';

const ScreensSettings = () => {
    return (
        <Switch>
            <Route path='/settings/' exact component={ScreensSettingsMain} />
            <Route path='/settings/report-template' component={ScreensReportTemplate} />
        </Switch>
    )
}

export default ScreensSettings;