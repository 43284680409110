import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import FormDatePicker from '../common/form-datepicker';
import { connect } from 'react-redux';

let PaymentEditForm = props => {
    const { create,
        handleSubmit,
        cancelFunc } = props;

    return (
        <Card className='mb-2'>
            <CardHeader tag='h5'>
                {create ? 'Добавить' : 'Редактировать'}&nbsp;платёж
            </CardHeader>
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <div className='form-group row'>
                        <label htmlFor='payment-amount' className='col-2 col-form-label'>Сумма</label>
                        <div className='col-4'>
                            <Field component='input' type='text' className='form-control' id='payment-amount' name='amount' autoComplete='off'></Field >
                        </div>

                        <label htmlFor='payment-number' className='col-2 col-form-label'>Номер</label>
                        <div className='col-4'>
                            <Field component='input' type='text' className='form-control' id='payment-number' name='number' autoComplete='off'></Field >
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor='payment-date' className='col-6 col-form-label'>Дата совершения платежа</label>
                        <div className='col-6'>
                            <Field component={FormDatePicker} id='payment-date' name='date' placeholder='Дата совершения' autoComplete='off'></Field>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor='payment-remittance-date' className='col-6 col-form-label'>Дата поступления платежа</label>
                        <div className='col-6'>
                            <Field component={FormDatePicker} id='payment-remittance-date' name='remittanceDate' placeholder='Дата поступления' autoComplete='off'></Field>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor='payment-comment' className='col-12 col-form-label'>Комментарий</label>
                        <div className='col-12'>
                            <Field component='textarea' className='form-control' id='payment-comment' name='comment' autoComplete='off'></Field >
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor='payment-source' className='col-12 col-form-label'>Источник</label>
                        <div className='col-12'>
                            <Field component='input' type='text' className='form-control' id='payment-source' name='source' autoComplete='off'></Field >
                        </div>
                    </div>
                    <div className='form-group row'>
                        <div className='col-1 mt-3'>
                            <Field component='input'
                                type='checkbox'
                                className='form-control'
                                id='payment-is-debt-cancellation'
                                name='isDebtСancellation'
                                autoComplete='off'></Field >
                        </div>
                        <label htmlFor='payment-is-debt-cancellation'
                            className='col-10 col-form-label'>Списание задолженности (не учитывается как доход в бухгалтерии)</label>
                    </div>

                    <div className='float-right'>
                        <Button outline color='secondary' onClick={cancelFunc}>Отмена</Button>
                        <Button type='submit' color='primary' className='ml-2'>Сохранить</Button>
                    </div>
                </form>
            </CardBody>
        </Card>
    );
}

PaymentEditForm = reduxForm({
    form: 'paymentEdit'
})(PaymentEditForm);

PaymentEditForm = connect(
    state => ({
        initialValues: state.payment.editPayment.item
    })
)(PaymentEditForm);

export default PaymentEditForm;