import React, { Component } from 'react';
import { ListGroup, Card, CardBody, CardHeader, Row } from 'reactstrap';
import RecipientPaymentListItem from './list-item';

class RecipientPayments extends Component {
    render() {
        const {
            payments,
            editFunc,
            deleteFunc } = this.props;

        var renderPayment = payments && payments
            .sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            })
            .map((value, index) => {
                return (
                    <RecipientPaymentListItem item={value}
                        key={index}
                        colorSecondary={index % 2}
                        editFunc={editFunc}
                        deleteFunc={deleteFunc}>
                    </RecipientPaymentListItem>
                )
            });

        return (
            <div>
                <Card color='light'>
                    <CardHeader tag='h5'>Платежи</CardHeader>
                    <CardBody>
                        {payments && payments.length > 0 ?
                            <ListGroup>
                                {renderPayment}
                            </ListGroup> :
                            <Row className='pl-2'>Данные отсутствуют</Row>
                        }
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default RecipientPayments;