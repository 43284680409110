export const OK = '_OK'
export const FAIL = '_FAIL'
export const REQUEST = '_REQUEST'
export const IMPORT = 'IMPORT'
export const EDIT = 'EDIT'
export const SELECT = 'SELECT'
export const CREATE = 'CREATE'
export const CANCEL = 'CANCEL'
export const SAVE = 'SAVE'
export const DELETE = 'DELETE'

export const DEFAULT_PAGE_SIZE = 25

export const LEGAL_HOST = window.location.origin
export const LEGAL_API = (process.env.REACT_APP_API_URL || LEGAL_HOST) +  '/api'
export const LEGAL_TM_API = (process.env.REACT_APP_API_URL || LEGAL_HOST) +  '/tm-api'
export const LEGAL_APP_V2 = (process.env.REACT_APP_V2_URL || LEGAL_HOST) +  '/v2'
export const LEGAL_DOWNLOAD_FILE = LEGAL_API + '/file/download/'
export const DOWNLOAD_NOTIFICATION_URL =
  LEGAL_API + '/Document/DownloadNotification?id='
export const REBUILD_NOTIFICATION_URL =
  LEGAL_API + '/Document/RebuildNotification?id='
export const DOWNLOAD_CESSION_CLAIM_URL =
  LEGAL_API + '/Document/DownloadCessionClaim?id='
export const REBUILD_CESSION_CLAIM_URL =
  LEGAL_API + '/Document/RebuildCessionClaim?id='
export const DOWNLOAD_LETTER_FOR_COURT_URL =
  LEGAL_API + '/Document/DownloadLetterForCourt?id='
export const DOWNLOAD_LETTER_FOR_BAILIFF_URL =
  LEGAL_API + '/Document/DownloadLetterForBailiff?id='
export const DOWNLOAD_PERSONAL_NOTIFICATION_URL =
  LEGAL_API + '/Document/DownloadPersonalNotification?id='
export const REBUILD_PERSONAL_NOTIFICATION_URL =
  LEGAL_API + '/Document/RebuildPersonalNotification?id='
export const DOWNLOAD_PERSONAL_CESSION_CLAIM_URL =
  LEGAL_API + '/Document/DownloadPersonalCessionClaim?id='
export const REBUILD_PERSONAL_CESSION_CLAIM_URL =
  LEGAL_API + '/Document/RebuildPersonalCessionClaim?id='
export const DOWNLOAD_LETTER_FOR_RECIPIENT_URL =
  LEGAL_API + '/Document/DownloadLetterForRecipient?id='
export const DOWNLOAD_NO_DEBTS_NOTE_URL =
  LEGAL_API + '/Document/DownloadNoDebtsNote?id='
export const REBUILD_NO_DEBTS_NOTE_URL =
  LEGAL_API + '/Document/RebuildNoDebtsNote?id='
export const DOWNLOAD_PERSONAL_NO_DEBTS_NOTE_URL =
  LEGAL_API + '/Document/DownloadPersonalNoDebtsNote?id='
export const REBUILD_PERSONAL_NO_DEBTS_NOTE_URL =
  LEGAL_API + '/Document/RebuildPersonalNoDebtsNote?id='
export const DOWNLOAD_FORGIVE_AGREEMENT_URL =
  LEGAL_API + '/Document/DownloadForgiveAgreement?id='
export const REBUILD_FORGIVE_AGREEMENT_URL =
  LEGAL_API + '/Document/RebuildForgiveAgreement?id='
export const DOWNLOAD_PERSONAL_FORGIVE_AGREEMENT_URL =
  LEGAL_API + '/Document/DownloadPersonalForgiveAgreement?id='
export const REBUILD_PERSONAL_FORGIVE_AGREEMENT_URL =
  LEGAL_API + '/Document/RebuildPersonalForgiveAgreement?id='
export const DOWNLOAD_NO_FORGIVE_AGREEMENT_URL =
  LEGAL_API + '/Document/DownloadNoForgiveAgreement?id='
export const REBUILD_NO_FORGIVE_AGREEMENT_URL =
  LEGAL_API + '/Document/RebuildNoForgiveAgreement?id='
export const DOWNLOAD_PERSONAL_NO_FORGIVE_AGREEMENT_URL =
  LEGAL_API + '/Document/DownloadPersonalNoForgiveAgreement?id='
export const REBUILD_PERSONAL_NO_FORGIVE_AGREEMENT_URL =
  LEGAL_API + '/Document/RebuildPersonalNoForgiveAgreement?id='
export const DOWNLOAD_EXE_PRODUCTION_FINISH_URL =
  LEGAL_API + '/Document/DownloadExeProductionFinish?id='
export const DOWNLOAD_EXE_PRODUCTION_REPLACE_URL =
  LEGAL_API + '/Document/DownloadExeProductionReplace?id='
export const GET_EXE_PRODUCTION_REQUEST_MODEL =
  LEGAL_API + '/Document/GetExeProductionRequestModel?id='
export const DOWNLOAD_EXE_PRODUCTION_REQUEST =
  LEGAL_API + '/Document/DownloadExeProductionRequest'
export const DOWNLOAD_DEBT_NOTE = LEGAL_API + '/Document/DownloadDebtNote?id='
export const REBUILD_DEBT_NOTE = LEGAL_API + '/Document/RebuildDebtNote?id='
export const DOWNLOAD_PERSONAL_DEBT_NOTE =
  LEGAL_API + '/Document/DownloadPersonalDebtNote?id='
export const REBUILD_PERSONAL_DEBT_NOTE =
  LEGAL_API + '/Document/RebuildPersonalDebtNote?id='
export const DOWNLOAD_EXE_PRODUCTION_DUBLICATE =
  LEGAL_API + '/Document/DownloadExeProductionDublicate?id='
export const DOWNLOAD_EXE_PRODUCTION_DUBLICATE_WITHOUT_ANSWER =
  LEGAL_API + '/Document/DownloadExeProductionDublicateWithoutAnswer?id='
export const CREATE_STATEMENT_OF_SEARCH =
  LEGAL_API + '/Document/CreateStatementOfSearch?id='
export const BANNING_DEPARTURE_STATEMENT =
  LEGAL_API + '/Document/CreateBanningDeparture?id='
export const CREATE_BANNING_DEPARTURE_LIST_URL =
  LEGAL_API + '/Document/CreateBanningDepartureRequestList'
export const CREATE_EXE_PRODUCTION_PROGRESS_INQUIRY =
  LEGAL_API + '/Document/CreateExeProductionProgressInquiry?id='
export const CHAT_URL = LEGAL_HOST + '/chat'
export const CALL_NOTIFY_HUB_URL = LEGAL_HOST + '/call-notify-hub'
export const CREATE_EXE_PRODUCTION_REQUEST_FROM_LAW_COURT =
  LEGAL_API + '/Document/CreateExeProdRequestFromLawCourt?id='
export const CREATE_REQUEST_CESSION_CLAIM_DUBLICATE =
  LEGAL_API + '/Document/CreateRequestCessionClaimDublicate?id='
export const CREATE_JUDICAL_ACT_REQUEST_AFTER_EXE_PROD_END =
  LEGAL_API + '/Document/CreateJudicalActRequestRequestAfterExeProdEndAsync?id='
export const CREATE_EXE_PROD_REJECT_REASON_REQUEST =
  LEGAL_API + '/Document/CreateExeProdRejectReasonRequestAsync?id='
  export const CREATE_COURT_ORDER_REQUEST =
    LEGAL_API + '/Document/CreateCourtOrderRequest?id='

export const OUTGOING_CALL = 'Исходящий звонок'

export const RECIPIENTS_LOAD = 'RECIPIENTS_LOAD'
export const RECIPIENTS_FILTER = 'RECIPIENTS_FILTER'
export const RECIPIENT_LOAD = 'RECIPIENT_LOAD'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const TOKEN_KEY = 'accessToken'

export const FILES_LOST = 'FILES_REQUEST_LOST'
export const FILES_ATTACH = 'FILES_ATTACH'
export const FILES_REMOVE = 'FILES_REMOVE'
export const FILES_LOST_SAVE = 'FILES_LOST_SAVE'

export const RECIPIENT_ACTIVITY = 'RECIPIENT_ACTIVITY'
export const RECIPIENT_ACTIVITY_TYPES = 'RECIPIENT_ACTIVITY_TYPES'
export const RECIPIENT_ACTIVITY_ADD_FILE = 'RECIPIENT_ACTIVITY_ADD_FILE'
export const RECIPIENT_ACTIVITY_REMOVE_FILE = 'RECIPIENT_ACTIVITY_REMOVE_FILE'
export const RECIPIENT_PAYMENT = 'RECIPIENT_PAYMENT'
export const FILE_UPLOAD = 'FILE_UPLOAD'
export const RECIPIENT_CONTRACT = 'RECIPIENT_CONTRACT'
export const RECIPIENT_LOAN_BALANCE_INFO = 'RECIPIENT_LOAN_BALANCE_INFO'
export const RECIPIENT_COURT_DECISION = 'RECIPIENT_COURT_DECISION'
export const RECIPIENT_EXECUTING_PRODUCTION = 'RECIPIENT_EXECUTING_PRODUCTION'
export const RECIPIENT_EXECUTING_PRODUCTION_SHOW_OFFLINE_LIST =
  'RECIPIENT_EXECUTING_PRODUCTION_SHOW_OFFLINE_LIST'
export const RECIPIENT_PERSONAL_INFO = 'RECIPIENT_PERSONAL_INFO'
export const RECIPIENT_STAGES = 'RECIPIENT_STAGES'
export const RECIPIENT_STAGE = 'RECIPIENT_STAGE'

export const RECIPIENT_DOCUMENTS = 'RECIPIENT_DOCUMENTS'

export const CALL_BACK = 'CALL_BACK'

export const ACCOUNTING_TAX = 'ACCOUNTING_TAX'
export const ACCOUNTING_TAX_EXPORT_TO_EXCEL = LEGAL_API + '/tax/export-to-excel'
export const ACCOUNTING_TAX_FILTER = 'ACCOUNTING_TAX_FILTER'

export const CALL_NOTIFY = 'CALL_NOTIFY'

export const HOME_STATS = 'HOME_STATS'

export const RECIPIENT_CESSION_AGREEMENT_NUMBERS =
  'RECIPIENT_CESSION_AGREEMENT_NUMBERS'

export const STAGE_PHASE = 'STAGE_PHASE'
export const STAGE_PHASES = 'STAGE_PHASES'

export const LAW_COURTS = 'LAW_COURTS'
export const LAW_COURTS_LIST = 'LAW_COURTS_LIST'
export const LAW_COURT_SELECT = 'LAW_COURT_SELECT'
export const LAW_COURT_UNION = 'LAW_COURT_UNION'
export const LAW_COURT_RECIPIENTS = 'LAW_COURT_RECIPIENTS'

export const REPORT_TEMPLATE = 'REPORT_TEMPLATE'
export const REPORT_TEMPLATES = 'REPORT_TEMPLATES'
export const REPORT_TEMPLATE_ADD_FILE = 'REPORT_TEMPLATE_ADD_FILE'

export const EXE_PRODUCTION_REQUEST_MODEL = 'EXE_PRODUCTION_REQUEST_MODEL'
