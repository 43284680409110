import React from 'react'
import { Card, Button } from 'reactstrap'
import {
    DOWNLOAD_LETTER_FOR_COURT_URL,
    DOWNLOAD_LETTER_FOR_RECIPIENT_URL,
    DOWNLOAD_NO_DEBTS_NOTE_URL,
    DOWNLOAD_FORGIVE_AGREEMENT_URL,
    DOWNLOAD_NO_FORGIVE_AGREEMENT_URL,
    DOWNLOAD_DEBT_NOTE,
    REBUILD_DEBT_NOTE,
} from '../../../../constants/legal'
import {
    RECIPIENT_DEBT_INFO_URL
} from '../../../../constants/recipient'

import * as fileActions from '../../../../actions/file'

const RecipientDetailDocumentLinks = (props) => {
    const downloadFileFunc = fileActions.download;

    return (
        <Card>
            <div>
                <Button color='link'
                    onClick={() => {
                        downloadFileFunc(DOWNLOAD_LETTER_FOR_COURT_URL + props.recipientId)
                    }}>Конверт для суда</Button>
            </div>
            <div>
                <Button color='link'
                    onClick={() => {
                        downloadFileFunc(DOWNLOAD_LETTER_FOR_RECIPIENT_URL + props.recipientId)
                    }}>Конверт для должника</Button>
            </div>
            <div>
                <Button color='link'
                    onClick={() => {
                        downloadFileFunc(DOWNLOAD_NO_DEBTS_NOTE_URL + props.recipientId)
                    }}>Справка об отсутствии задолженности</Button>
            </div>
            <div>
                <Button color='link'
                    onClick={() => {
                        downloadFileFunc(DOWNLOAD_FORGIVE_AGREEMENT_URL + props.recipientId)
                    }}>Соглашение о погашении задолженности с прощением</Button>
            </div>
            <div>
                <Button color='link'
                    onClick={() => {
                        downloadFileFunc(DOWNLOAD_NO_FORGIVE_AGREEMENT_URL + props.recipientId)
                    }}>Соглашение о погашении задолженности</Button>
            </div>
            <div>
                <Button color='link'
                    onClick={() => {
                        downloadFileFunc(DOWNLOAD_DEBT_NOTE + props.recipientId)
                    }}>Печать справки об остатке задолженности</Button>
            </div>
            <div>
                <Button color='link'
                    onClick={() => {
                        downloadFileFunc(REBUILD_DEBT_NOTE + props.recipientId)
                    }}>Пересоздать справку об остатке задолженности</Button>
            </div>
            <div>
                <Button color='link'
                    onClick={() => {
                        downloadFileFunc(RECIPIENT_DEBT_INFO_URL.replace('{recipientId}', props.recipientId))
                    }}>Расчет задолженности</Button>
            </div>
        </Card>);
}

export default RecipientDetailDocumentLinks;