import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button, Row } from 'reactstrap';
import { connect } from 'react-redux';

let RecipientDetailContractEditForm = props => {
    const { cancelFunc,
        handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <dl className='row'>
                <dt className='col-sm-3'>Госпошлина</dt>
                <dd className='col-sm-9'>
                    <Field component='input' type='text' className='form-control' name='loanBalanceStampDutyInitial' autoComplete='off'></Field>
                </dd>

                <dt className='col-sm-3'>Сумма долга по %%</dt>
                <dd className='col-sm-9'>
                    <Field component='input' type='text' className='form-control' name='loanPercentSumInitial' autoComplete='off'></Field>
                </dd>

                <dt className='col-sm-3'>Сумма основного долга</dt>
                <dd className='col-sm-9'>
                    <Field component='input' type='text' className='form-control' name='loanSumInitial' autoComplete='off'></Field>
                </dd>

                <dt className='col-sm-3'>Сумма долга по пеням/штрафам/неустойкам</dt>
                <dd className='col-sm-9'>
                    <Field component='input' type='text' className='form-control' name='loanBalancePenaltyApprovedInitial' autoComplete='off'></Field>
                </dd>
            </dl>
            <Row>
                <Button outline color='secondary' onClick={cancelFunc}>Отмена</Button>
                <Button type='submit' color='primary' className='ml-2'>Сохранить</Button>
            </Row>
            <br />
        </form>
    )
}

RecipientDetailContractEditForm = reduxForm({
    form: 'contractEditForm',
    enableReinitialize: true
})(RecipientDetailContractEditForm);

RecipientDetailContractEditForm = connect(
    state => ({
        initialValues: state.recipient.editContract.item
    })
)(RecipientDetailContractEditForm);

export default RecipientDetailContractEditForm;