import React from 'react'
import { Table, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import DocumentListIncomingItem from './list-incoming-item'

const DocumentListOutgoing = props => {
  const docList = props.list.map(document => {
    return (
      <DocumentListIncomingItem key={document.id} item={document} editItemFunc={props.editItemFunc} />
    )
  })

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div>
          <h2>Входящие документы / {<Link to='/document-flow/outgoing'>отправленные</Link>}</h2>
        </div>

        <div>
          <Button color='primary' onClick={() => { props.editItemFunc({}); }}>
            <i className='fa fa-plus' aria-hidden='true'></i>&nbsp;Добавить
            </Button>
        </div>
      </div>

      <Table size='sm' hover striped>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Дата</th>
            <th>Наименование документа</th>
            <th>От кого поступил документ</th>
            <th>Краткое содержание</th>
            <th>Прикрепленный файл</th>
            <th className='list-outgoing__deal'>Договор</th>
          </tr>
        </thead>
        <tbody>
          {docList}
        </tbody>
      </Table>

      {props.children}

    </div >
  )
}

export default DocumentListOutgoing;