import axios from '../services/axios';
import {
    OK,
    REQUEST,
    CALL_BACK,
} from '../constants/legal';
import { info, error } from 'react-notification-system-redux';

export function getCallBack(values) {
    return dispatch => {
        dispatch({ type: CALL_BACK + REQUEST });

        axios.get('/call-center/start-callback?numberTo=' + values)
            .then(result => {
                dispatch({
                    type: CALL_BACK + OK
                });

                dispatch(
                    info({
                        title: 'Вызов Callback',
                        message: result.data.result
                    }));
            })
            .catch(result => {
                dispatch(
                    error({
                        title: 'Вызов Callback',
                        message: result.response.data.error || result.message
                    }));
            })
    }
}