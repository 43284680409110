import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Tax from '../components/accounting/tax'
import * as taxActions from '../actions/accounting-tax'
import * as fileActions from '../actions/file'

const Accounting = props => {
  const { taxList, taxActions, taxTotal, taxFilter, fileActions, isSupervisor } = props

  if (!isSupervisor) {
    return (<p>Доступ запрещен</p>)
  }

  return (
    <Tax
      {...props}
      list={taxList}
      total={taxTotal}
      filterTaxFunc={taxActions.filterTax}
      filter={taxFilter}
      downloadFileFunc={fileActions.downloadFile}
    />
  )
}

function mapStateToProps(state) {
  return {
    taxList: state.accountingTax.list,
    taxTotal: state.accountingTax.total,
    taxFilter: state.accountingTax.filter,
    isSupervisor: state.user.isSupervisor,
  }
}

function mapDispathToProps(dispatch) {
  return {
    taxActions: bindActionCreators(taxActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispathToProps
)(Accounting)
