import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ListGroup, Table } from 'reactstrap'
import Aux from '../../../hoc/ReactAux/ReactAux'

import RecipientListItem from '../list-item'
import RecipientListTableItem from './table-item'

import * as recipientActions from '../../../actions/recipient'
import * as fileActions from '../../../actions/file'

class RecipientList extends Component {
  componentDidMount() {
    this.props.recipientActions.filterRecipient(this.props.filter)
  }

  openDetailClick = () => {
    this.props.history.push('/recipient/' + this.props.recipient.id)
  }

  render() {
    const { recipients } = this.props

    let recipientList = <div>Пусто</div>

    if (recipients && recipients.length > 0) {
      if (this.props.filter.tableView) {
        recipientList = (
          <Table size='sm' striped bordered hover>
            <thead>
              <tr>
                <td>ФИО Заёмщика</td>
                <td>Дата рождения</td>
                <td>Номер кредитного договора</td>
                <td>Стадия</td>
                <td>Подстадия</td>
                <td>ИТОГО</td>
                <td>Госпошлина</td>
                <td>%</td>
                <td>ОД</td>
                <td>Штрафы, пени</td>
                {/* <td>№ Исполнительного листа</td> */}
                <td>№ гр дела</td>
                <td>Наим.суда</td>
                {/* <td>Определение о правопреемстве</td> */}
                <td>№ ИП</td>
                <td>Дата возбуждения ИП</td>
                <td>Дата окончания ИП</td>
                {/* <td>Дата отправки заявления о запрете на выезд</td> */}
                <td>Наим ОСП</td>
                {/* <td>Оплата за период, в руб.</td>
                <td>% оплаты</td>
                <td>Первоначальный кредитор (правопреемство)</td> */}
              </tr>
            </thead>
            <tbody>
              {recipients.map(elem => {
                return <RecipientListTableItem recipient={elem} key={elem.id} />
              })}
            </tbody>
          </Table>
        )
      } else {
        recipientList = (
          <ListGroup className='list-group-flush'>
            {recipients.map(elem => {
              return (
                <RecipientListItem
                  key={elem.id}
                  recipient={elem}
                  downloadFileFunc={this.props.fileActions.downloadFileFunc}
                />
              )
            })}
          </ListGroup>
        )
      }
    }

    return <Aux>{recipientList}</Aux>
  }
}

function mapStateToProps(state) {
  return {
    recipients: state.recipient.list,
    filter: state.recipient.filter,
  }
}

function mapDispathToProps(dispatch) {
  return {
    recipientActions: bindActionCreators(recipientActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispathToProps
)(RecipientList)
