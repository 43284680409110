import {
  OK,
  REQUEST,
  CREATE,
  EDIT,
  SAVE,
  CANCEL,
  DELETE,
  RECIPIENT_LOAD,
  RECIPIENT_ACTIVITY,
  RECIPIENT_ACTIVITY_TYPES,
  RECIPIENT_ACTIVITY_ADD_FILE,
  RECIPIENT_ACTIVITY_REMOVE_FILE,
  CALL_BACK,
  OUTGOING_CALL,
} from '../constants/legal'

const initialState = {
  activities: null,
  editActivity: {
    formEnable: false,
    item: {},
    files: [],
    typeId: null,
  },
  activityTypes: []
}


export const activity = (state = initialState, action = {}) => {
  switch (action.type) {

    case RECIPIENT_LOAD + REQUEST:
      return {
        ...state,
        activities: [],
      }


    case RECIPIENT_ACTIVITY + OK:
      return { ...state, activities: action.list }

    case RECIPIENT_ACTIVITY + CREATE:
      return {
        ...state,
        editActivity: {
          formEnable: true,
          typeId: action.activityTypeId,
        },
      }

    case RECIPIENT_ACTIVITY + CREATE + CANCEL:
      return {
        ...state,
        editActivity: {
          formEnable: false,
        },
      }

    case RECIPIENT_ACTIVITY + EDIT:
      return {
        ...state,
        editActivity: {
          formEnable: true,
          typeId: action.payload.recipientActivityType.id,
          item: action.payload,
          files: action.payload.files,
        },
      }

    case RECIPIENT_ACTIVITY + EDIT + SAVE:
      return {
        ...state,
        editActivity: {
          formEnable: false,
        },
        //TODO: применить изменение к массиву данных.
      }

    case RECIPIENT_ACTIVITY_TYPES + OK:
      return { ...state, activityTypes: action.list }

    case RECIPIENT_ACTIVITY_ADD_FILE: {
      let files = state.editActivity.files || []
      let editfiles = files.concat(action.payload)
      return {
        ...state,
        editActivity: {
          formEnable: true,
          typeId: state.editActivity.typeId,
          files: editfiles,
          item: state.editActivity.item,
        },
      }
    }

    case RECIPIENT_ACTIVITY_REMOVE_FILE:
      return {
        ...state,
        editActivity: {
          formEnable: true,
          typeId: state.editActivity.typeId,
          files: [
            ...state.editActivity.files.slice(0, action.payload),
            ...state.editActivity.files.slice(action.payload + 1),
          ],
          item: state.editActivity.item,
        },
      }

    case RECIPIENT_ACTIVITY + SAVE:
      return {
        ...state,
        editActivity: {
          formEnable: false,
        },
        activities: state.activities.concat(action.payload),
      }

    case RECIPIENT_ACTIVITY + DELETE + OK:
      var activities = state.activities.filter(
        value => value.id !== action.payload
      )

      return {
        ...state,
        activities,
      }

    case CALL_BACK + OK:
      var incomeCall = state.activityTypes.find(element => {
        return element.name === OUTGOING_CALL
      })
      return {
        ...state,
        editActivity: {
          formEnable: true,
          typeId: incomeCall.id,
        },
      }

    default:
      return state
  }
}
