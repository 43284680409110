import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import user from './user'
import { recipient } from './recipient'
import { personalInfo } from './personal-info'
import { file } from './file'
import { reducer as notifications } from 'react-notification-system-redux'
import { accountingTax } from './accounting-tax'
import { payment } from './payment'
import { callNotify } from './call-notify'
import { lawCourt } from './law-court'
import reportTemplate from './report-template'
import { notificationExeProd } from './notification/exe-prod'
import { exeProd } from './exe-prod'
import { activity } from './activity'

export default combineReducers({
  user,
  form: formReducer,
  recipient,
  personalInfo,
  notifications,
  file,
  accountingTax,
  payment,
  callNotify,
  lawCourt,
  reportTemplate,
  notificationExeProd,
  exeProd,
  activity
})
