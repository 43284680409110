import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default class ActivityAddDropdown extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    componentDidMount() {
        this.props.getAllActivityTypes();
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        const { activityTypes } = this.props;

        let renderActivityTypes = activityTypes && activityTypes.map((value, index) => {
            return (
                <DropdownItem key={index} onClick={() => { this.props.activityCreateFunc(value.id) }}>{value.name}</DropdownItem>
            )
        })

        return (
            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret color='primary'>
                    Добавить активность
                </DropdownToggle>
                <DropdownMenu>
                    {renderActivityTypes}
                </DropdownMenu>
            </ButtonDropdown>
        )
    }
}