import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Aux from '../../hoc/ReactAux/ReactAux'

import * as homeApi from '../../services/webapi/home-api'

const HomeRecipientTotal = (props) => {

  const openRecipientsClick = () => {
    props.history.push('/recipient')
  }

  const [recipientTotal, setRecipientTotal] = useState(0);

  useEffect(() => {
    homeApi.getStats()
      .then(result => {
        setRecipientTotal(result.data.total)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  return (
    <Aux>
      <Col
        sm='5'
        xs='11'
        xl='3'
        onClick={openRecipientsClick}
        className='p-3 text-white m-3 btn btn-primary'
      >
        <h3>Всего должников</h3>
        <Row>
          <Col xs='5'>
            <i className='fa fa-users fa-3x pt-2' aria-hidden='true' />
          </Col>
          <Col xs='7' className='display-4 font-weight-bold'>
            {recipientTotal}
          </Col>
        </Row>
      </Col>
    </Aux>
  )
}

export default withRouter(HomeRecipientTotal)
