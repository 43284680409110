import axios from '../../services/notification-axios';
import {
    NOTIFICATION_EXE_PRODS,
    NOTIFICATION_EXE_PRODS_UNREAD_COUNT,
    NOTIFICATION_EXE_PRODS_SET_READ
} from '../../constants/notification';
import { OK } from '../../constants/legal';

export const getAll = () => {
    return dispatch => {
        // dispatch({
        //     type: ''
        // })
        axios.get('/getAll')
            .then(result => {
                dispatch({
                    type: NOTIFICATION_EXE_PRODS + OK,
                    payload: result.data
                })
            })
    }
}

export const getUnreadMessageCount = () => {
    return dispatch => {
        axios.get('/unread/count')
            .then(result => {
                dispatch({
                    type: NOTIFICATION_EXE_PRODS_UNREAD_COUNT + OK,
                    payload: result.data
                })
            })
    }
}

export const setReadMessage = (messageId, userName) => {
    return dispatch => {
        axios.post('/read/' + messageId, { name: userName })
            .then(result => {
                dispatch({
                    type: NOTIFICATION_EXE_PRODS_SET_READ + OK,
                    payload: result.data
                })
            })
    }
}