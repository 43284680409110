import React from 'react'
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
  Col,
  Badge,
  Button,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap'

import { moneyToRubWithCurrency, dateToString } from '../../utils/helper'

import {
  DOWNLOAD_EXE_PRODUCTION_FINISH_URL,
  DOWNLOAD_EXE_PRODUCTION_REPLACE_URL,
  DOWNLOAD_LETTER_FOR_BAILIFF_URL,
  DOWNLOAD_EXE_PRODUCTION_DUBLICATE,
  DOWNLOAD_EXE_PRODUCTION_DUBLICATE_WITHOUT_ANSWER,
  CREATE_STATEMENT_OF_SEARCH,
  BANNING_DEPARTURE_STATEMENT,
  CREATE_EXE_PRODUCTION_PROGRESS_INQUIRY,
  CREATE_JUDICAL_ACT_REQUEST_AFTER_EXE_PROD_END,
} from '../../constants/legal'

const FillColorItem = (value, index) => {
  if (value && value.isMissingInFSSP) {
    return 'warning'
  }

  if (index % 2) {
    return 'secondary'
  }

  return ''
}

const RecipientExeProductions = props => {
  const { list, downloadFileFunc, removeExeProdFunc } = props

  const renderList =
    list &&
    list.map((value, index) => {
      return (
        <ListGroupItem key={index} color={FillColorItem(value, index)}>
          <ListGroupItemHeading>
            <Row>
              <Col md='8' sm='12'>
                {value.exeProductionNumber} от&nbsp;
                {dateToString(value.exeProductionDate)}
              </Col>
              <Col md='4' sm='12'>
                {moneyToRubWithCurrency(value.sum)}
              </Col>
            </Row>
            {value.ipEnd ? (
              <Badge color='success'>
                {dateToString(value.ipEndDate)}, {value.ipEnd}
              </Badge>
            ) : null}
            <Button
              outline
              color='danger'
              className='float-right'
              onClick={() => {
                removeExeProdFunc({
                  recipientId: value.recipientID,
                  exeProductionId: value.id,
                })
              }}
              id='ExeProductionRemove'
            >
              <i className='fa fa-trash-o' aria-hidden='true' />
            </Button>
            <UncontrolledTooltip placement='top' target='ExeProductionRemove'>
              Удалить ИП
            </UncontrolledTooltip>
          </ListGroupItemHeading>
          <FormGroup>{value.subject}</FormGroup>
          <FormGroup>{value.details}</FormGroup>
          <FormGroup>
            <small className='font-weight-bold'>Отдел судебных приставов</small>{' '}
            {value.department}
            <br />
            <small className='font-weight-bold'>
              Судебный пристав-исполнитель&nbsp;
            </small>{' '}
            {value.bailiff}
          </FormGroup>
          <Row>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(DOWNLOAD_EXE_PRODUCTION_FINISH_URL + value.id)
              }}
            >
              Зявление об окончании исполнительного производства
            </Button>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(DOWNLOAD_EXE_PRODUCTION_REPLACE_URL + value.id)
              }}
            >
              Заявление о смене стороны в ИП
            </Button>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(DOWNLOAD_LETTER_FOR_BAILIFF_URL + value.id)
              }}
            >
              Письмо
            </Button>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(DOWNLOAD_EXE_PRODUCTION_DUBLICATE + value.id)
              }}
            >
              Заявление о выдаче дубликата ИД (есть ответ ФССП)
            </Button>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(DOWNLOAD_EXE_PRODUCTION_DUBLICATE_WITHOUT_ANSWER + value.id)
              }}
            >
              Заявление о выдаче дубликата ИД (нет ответа ФССП)
            </Button>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(CREATE_STATEMENT_OF_SEARCH + value.id)
              }}
            >
              Заявление о розыске имущества
            </Button>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(BANNING_DEPARTURE_STATEMENT + value.id)
              }}
            >
              Заявление о запрете выезда с РФ
            </Button>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(
                  CREATE_EXE_PRODUCTION_PROGRESS_INQUIRY + value.id
                )
              }}
            >
              Запрос о ходе ИП
            </Button>
            <Button
              color='link'
              onClick={() => {
                downloadFileFunc(
                  CREATE_JUDICAL_ACT_REQUEST_AFTER_EXE_PROD_END + value.id
                )
              }}
              >Запрос ИД после окончания ИП</Button>
          </Row>
        </ListGroupItem>
      )
    })

  return (
    <div className='mb-2'>
      <FormGroup>
        {list && list.length > 0 ? (
          <ListGroup>{renderList}</ListGroup>
        ) : (
          <Row className='pl-3'>Данные отсутствуют</Row>
        )}
      </FormGroup>
    </div>
  )
}

export default RecipientExeProductions
