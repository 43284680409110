import React, { Component } from 'react'
import { Button, Table } from 'reactstrap'
import {
  LEGAL_DOWNLOAD_FILE,
} from '../../constants/legal'
import { dateToString } from '../../utils/helper'

export default class RecipientDocs extends Component {
  render() {
    const { list,
      downloadFileFunc } = this.props;

    let renderDocs = list && list.map((value, index) => {
      return (
        <tr key={index}>
          <td>{value.regNumber}</td>
          <td>{dateToString(value.regDate)}</td>
          <td>
            <Button color='link'
              size='sm'
              onClick={() => {
                downloadFileFunc(LEGAL_DOWNLOAD_FILE + value.fileId)
              }}>{value.fileName}</Button>
              <br />
              <span className='float-left text-muted'>{value.recipientActivityTypeName}</span>
              <span className='float-right text-muted'>{value.fileTypeName}</span>
          </td>
          <td>{dateToString(value.createTime)}</td>
        </tr>
      )
    })

    return (
      <Table size='sm' className='font-size-small' striped>
        <thead>
          <tr>
            <th>Номер</th>
            <th>Дата</th>
            <th></th>
            <th>Создан</th>
          </tr>
        </thead>
        <tbody>{renderDocs}</tbody>
      </Table>
    )
  }
}