import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Aux from '../../../../hoc/ReactAux/ReactAux';

import RecipientDetailContractView from './view';
import RecipientDetailContractEditForm from './edit-form';

import * as contractActions from '../../../../actions/recipient-contract';
import * as helper from '../../../../utils/helper';

class RecipientDetailContract extends Component {
    componentDidMount() {
        this.props.contractActions.get(this.props.recipientId);
    }

    updateLoanBalanceInfo = (values) => {
        const loanBalanceInfo = {
            recipientId: this.props.recipientId,
            sum: helper.replaceCommaToDot(values.loanSumInitial),
            percent: helper.replaceCommaToDot(values.loanPercentSumInitial),
            penalty: helper.replaceCommaToDot(values.loanBalancePenaltyApprovedInitial),
            stampDuty: helper.replaceCommaToDot(values.loanBalanceStampDutyInitial)
        }

        this.props.contractActions.updateLoanBalanceInfo(loanBalanceInfo);
    }

    render() {
        const { contract,
            editContract,
            contractActions } = this.props;

        return (
            <Aux>
                {editContract.formEnable ?
                    <RecipientDetailContractEditForm onSubmit={this.updateLoanBalanceInfo}
                        cancelFunc={contractActions.cancelEditLoanBalanceInfo} /> :
                    <RecipientDetailContractView
                        item={contract}
                        editFunc={contractActions.editLoanBalanceInfo}>
                    </RecipientDetailContractView>}
            </Aux>
        )
    }
}

function mapStateToProps(state) {
    return {
        contract: state.recipient.contract,
        editContract: state.recipient.editContract,
    }
}

function mapDispathToProps(dispatch) {
    return {
        contractActions: bindActionCreators(contractActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(RecipientDetailContract);