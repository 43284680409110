import axios from '../../services/axios'
import { error, info } from 'react-notification-system-redux'

import { COMMENT_SAVE } from '../../constants/comment'
import { REQUEST } from '../../constants/legal'

export const save = values => {
  return dispatch => {
    dispatch({
      type: COMMENT_SAVE + REQUEST,
    })

    axios
      .post('recipient/' + values.recipientId + '/comment', {
        comment: values.comment,
      })
      .then(() => {
        dispatch(
          info({
            title: 'Комментарий обновлен',
            message: 'Успешно',
          })
        )
      })
      .catch(err => {
        dispatch(
          error({
            title: 'Вход в систему',
            message: err.response.data,
          })
        )
      })
  }
}
