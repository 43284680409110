import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'

import ScreenRoot from './screens/root'

import './index.css'
import 'bootstrap/dist/css/bootstrap.css'
import './components/font-awesome/css/font-awesome.min.css'
import 'react-datepicker/dist/react-datepicker.css'

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScreenRoot />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
