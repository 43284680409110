import axios from '../services/axios';
import {
    OK,
    FAIL,
    REQUEST,
    CANCEL,
    RECIPIENT_PERSONAL_INFO,
    EDIT
} from '../constants/legal';

export function getAll(recipientId) {
    return dispatch => {
        dispatch({
            type: RECIPIENT_PERSONAL_INFO + REQUEST
        });

        axios.get('/recipient/' + recipientId + '/personal-infos')
            .then(result => {
                dispatch({
                    type: RECIPIENT_PERSONAL_INFO + OK,
                    payload: result.data
                })
            })
            .catch(result => {
                dispatch({
                    type: RECIPIENT_PERSONAL_INFO + FAIL,
                    errors: result.message
                })
            });
    }
}

export function updatePersonalInfo(recipientId, values) {
    return dispatch => {
        dispatch({
            type: RECIPIENT_PERSONAL_INFO + EDIT + REQUEST
        })

        axios.post('/recipient/' + recipientId + '/personal-infos', values)
            .then(result => {
                dispatch({
                    type: RECIPIENT_PERSONAL_INFO + EDIT + OK,
                    payload: result.data
                })
            })
            .catch(result => {
                dispatch({
                    type: RECIPIENT_PERSONAL_INFO + EDIT + FAIL,
                    errors: result.message
                })
            });
    }
}

export function cancelEdit() {
    return dispatch => {
        dispatch({
            type: RECIPIENT_PERSONAL_INFO + EDIT + CANCEL
        })
    }
}

export function edit() {
    return dispatch => {
        dispatch({
            type: RECIPIENT_PERSONAL_INFO + EDIT
        })
    }
}