import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListGroup, ListGroupItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';

import * as personalInfoActions from '../../../actions/recipient-personal-info';
import * as fileActions from '../../../actions/file';

import {
    DOWNLOAD_PERSONAL_NOTIFICATION_URL,
    REBUILD_PERSONAL_NOTIFICATION_URL,
    DOWNLOAD_PERSONAL_CESSION_CLAIM_URL,
    REBUILD_PERSONAL_CESSION_CLAIM_URL,
    DOWNLOAD_PERSONAL_NO_DEBTS_NOTE_URL,
    REBUILD_PERSONAL_NO_DEBTS_NOTE_URL,
    DOWNLOAD_PERSONAL_FORGIVE_AGREEMENT_URL,
    REBUILD_PERSONAL_FORGIVE_AGREEMENT_URL,
    DOWNLOAD_PERSONAL_NO_FORGIVE_AGREEMENT_URL,
    REBUILD_PERSONAL_NO_FORGIVE_AGREEMENT_URL,
    DOWNLOAD_PERSONAL_DEBT_NOTE,
    REBUILD_PERSONAL_DEBT_NOTE,
    CREATE_COURT_ORDER_REQUEST,
} from '../../../constants/legal';

class ResipientDetailPersonalInfoList extends Component {
    componentDidMount() {
        this.props.personalInfoActions.getAll(this.props.recipientId);
    }

    render() {
        const { personalInfos,
            fileActions: {
                downloadFile
            } } = this.props;

        const renderList = personalInfos && personalInfos.map((value, index) => {
            return (
                <ListGroupItem key={index}>
                    <span className='float-left pr-2'>{value.name}</span>
                    <UncontrolledDropdown size='sm' className='float-right'>
                        <DropdownToggle caret color='primary' outline>
                            {value.recipientType}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                downloadFile(DOWNLOAD_PERSONAL_NOTIFICATION_URL + value.id);
                            }}>Уведомление</DropdownItem>
                            <DropdownItem onClick={() => {
                                downloadFile(REBUILD_PERSONAL_NOTIFICATION_URL + value.id);
                            }}>Обновить уведомление</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => {
                                downloadFile(DOWNLOAD_PERSONAL_CESSION_CLAIM_URL + value.id);
                            }}>Заявление о правопреемстве</DropdownItem>
                            <DropdownItem onClick={() => {
                                downloadFile(REBUILD_PERSONAL_CESSION_CLAIM_URL + value.id);
                            }}>Обновить заявление о правопреемстве</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => {
                                downloadFile(DOWNLOAD_PERSONAL_NO_DEBTS_NOTE_URL + value.id);
                            }}>Справка об отсутствии задолженности</DropdownItem>
                            <DropdownItem onClick={() => {
                                downloadFile(REBUILD_PERSONAL_NO_DEBTS_NOTE_URL + value.id);
                            }}>Обновить справку об отсутствии задолженности</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => {
                                downloadFile(DOWNLOAD_PERSONAL_FORGIVE_AGREEMENT_URL + value.id);
                            }}>Соглашение о погашении задолженности с прощением</DropdownItem>
                            <DropdownItem onClick={() => {
                                downloadFile(REBUILD_PERSONAL_FORGIVE_AGREEMENT_URL + value.id);
                            }}>Обновить соглашение о погашении задолженности с прощением</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => {
                                downloadFile(DOWNLOAD_PERSONAL_NO_FORGIVE_AGREEMENT_URL + value.id);
                            }}>Соглашение о погашении задолженности</DropdownItem>
                            <DropdownItem onClick={() => {
                                downloadFile(REBUILD_PERSONAL_NO_FORGIVE_AGREEMENT_URL + value.id);
                            }}>Обновить соглашение о погашении задолженности</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => {
                                downloadFile(DOWNLOAD_PERSONAL_DEBT_NOTE + value.id);
                            }}>Печать справки об остатке задолженности</DropdownItem>
                            <DropdownItem onClick={() => {
                                downloadFile(REBUILD_PERSONAL_DEBT_NOTE + value.id);
                            }}>Обновить соглашение о погашении задолженности</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <br />
                    <Button color='link'
                        onClick={() => {
                            downloadFile(CREATE_COURT_ORDER_REQUEST + value.id)
                        }}>Создать заявление о выдаче судебного приказа</Button>
                </ListGroupItem>
            )
        })

        return (
            <ListGroup>{renderList}</ListGroup>
        )
    }
}

function mapStateToProps(state) {
    return {
        personalInfos: state.recipient.personalInfos,
    }
}

function mapDispathToProps(dispatch) {
    return {
        personalInfoActions: bindActionCreators(personalInfoActions, dispatch),
        fileActions: bindActionCreators(fileActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispathToProps)(ResipientDetailPersonalInfoList);